import '../App.css';
import NavBar from '../Components/NavBar';
import Banner from '../Components/Banner';
import BrandReview from '../Components/BrandReview';
import NoAdvertisements from '../Components/NoAdvertisements';
import { useApi } from '../Contexts/ApiProvider';
import { React, useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import LoadingSpinner from '../Components/loadingSpinner';
import { useSearchParams } from 'react-router-dom';

function Businesses() {
    const api = useApi();
    const { user, getAccessTokenSilently } = useAuth0();
    let [currentIndexVal, setCurrentIndexVal] = useState(0);
    const [userBrands, setUserBrands] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [searchParams] = useSearchParams();

    useEffect(() => {
        (async () => {
            setIsLoading(true);
            var apiUri = '';
            if(searchParams.get('businessID') != null){
                apiUri = 'advertisement/business/' + searchParams.get('businessID') + '/consumer';
            }
            else{
                apiUri = 'business/consumer/brands';
            }
            const response = await api.get(apiUri, null, {
                headers: {
                  Authorization:  'Bearer ' + await getAccessTokenSilently()
                }});
            if (response.ok) {
                setUserBrands(response.body);
                setIsLoading(false);
            }
            else {
                setIsLoading(false);
            }
        })();
        }, []);

    return(
        <div className='ads-page'>
            <header className="App-header">
                <NavBar currentPage={'ads'}/>
            </header>
            <Banner isHomePage={false}/>
            <div className='ad-page-sub-banner'>More ad categories... { userBrands.length <= 0 || userBrands == null ? '' : isLoading ? '' : ''}</div>
            <div className='ad-wrapper-container'>
                <div className='ad-wrapper'>
                    { userBrands.length <= 0 || userBrands == null ? isLoading ? <LoadingSpinner/> : <NoAdvertisements pageName='brands'/> : isLoading ? <LoadingSpinner/> : 
                                    userBrands.map((brandToReview, index) => (<BrandReview key={brandToReview.id} userBrand={brandToReview} brandIndex={index} currentIndex={currentIndexVal} hasNext={userBrands.length > currentIndexVal + 1 ? true : false} hasPrev={currentIndexVal == 0 ? false : true}
                                        onReviewSubmission={brandId => {
                                        setUserBrands(userBrands.filter(userBrand => userBrand.id !== brandId));
                                        if(currentIndexVal + 1 >= userBrands.length) {setCurrentIndexVal(0)};
                                    }}
                                        navToNextBrand={navToBrand => {setCurrentIndexVal(navToBrand)}}
                                        navToPrevBrand={navToBrand => {setCurrentIndexVal(navToBrand)}}
                                    />))}
                </div>
            </div>
            <div className='ad-page-sub-banner-bottom'>More ads by... { userBrands.length <= 0 || userBrands == null ? '' : isLoading ? '' : userBrands[0].name}</div>
        </div>
    )
}

export default Businesses;