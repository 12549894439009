import '../LandingPage.css';
import LandingNav from '../Components/LandingPage/LandingNav';
import LandingFooter from '../Components/LandingPage/LandingFooter';
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { useApi } from '../Contexts/ApiProvider';
import BehaviorEco from '../BehaviorEco.png';
import BehaviorEco1 from '../BehaviorEco1.png';
import BehaviorEco2 from '../BehaviorEco2.png';
import { useNavigate } from 'react-router-dom';
import AdSense from '../Components/AdSense';

function LandingBlogBehavior() {
    const api = useApi();
    const [isLoading, setIsLoading] = useState(true);
    const navigate = useNavigate();

    return(
        <div className='landing-page-wrapper'>
            <LandingNav />
            <div style={{height: '50px'}}></div>
            <div className='landing-about-blog-content-wrapper'>
                {/*<AdSense dataAdSlot='2013861535' />*/}
                <div className='landing-about-blog-articles-wrapper'>
                    <div className='landing-about-blog-article'>
                        <div className='landing-about-blog-back-btn' onClick={() => (navigate('/about'))}>
                            <FontAwesomeIcon icon={faChevronLeft}/><span className='landing-about-blog-back-text'>Go Back</span>
                        </div>
                        <div className='landing-about-blog-article-title'>                            
                            <p>Behavioral Economics, The Secret Language of Business</p>
                            <p className='landing-about-blog-article-author'>Written by The Parsal Team</p>
                        </div>
                        <div className='landing-about-blog-article-image'>
                            <img src={BehaviorEco} alt='Behavor Economics'/>
                        </div>
                        <div className='landing-about-blog-article-body'>
                            <p>Have you ever felt like your being followed online? That someone is always looking 
                                over your shoulder and nudging your elbow at just the right time to get you to click 
                                on that ad, add that hat to your amazon shopping list, subscribe to that email for 
                                that sweet, sweet 10% discount?</p>
 
                            <div>
                                <p>The truth is, that is happening, and not just online.</p>
                                <p>All day, every day – businesses are using expert targeted tactics that are shown to 
                                    effectively nudge shoppers in the direction they want. From hiding the milk at the 
                                    back of the grocery store so you must walk down every aisle and see all the stuff 
                                    you don’t need, but WANT to buy to buy one get one free offers that drive you to 
                                    buy two copies of Twilight, when you know well that you already have four copies at 
                                    home.</p>
                                <p>Here, we’ve gathered experiences of the course of a decade plus in the advertising 
                                    game to share some of the tactics these companies deploy. Watch for these tactics, 
                                    stay informed, and hold your personal data close when businesses ask you to 
                                    exchange it for momentary return:</p>
                                <div className='landing-about-blog-article-image'>
                                    <img src={BehaviorEco1} alt='Behavor Economics'/>
                                </div>
                                <p><b>The “Foot in the Door” Subscription:</b>It seems inconspicuous at first. A pop up 
                                    interrupting your shopping for socks on coolsocksforcoolpeople.com offering 25% off
                                    your next sock order! All you need to do is provide an email address or mobile 
                                    phone number. Easy. However, hidden in that offer is what is called an “opt in”. 
                                    Some businesses will make the Opt In active – this means you have to check a box 
                                    or agree to receive future marketing from the business. But many will use what’s 
                                    called “passive opt in”. This means that whether you realize it or not, you are 
                                    agreeing to marketing just by signing up for the discount. “Not I” you’re saying in 
                                    your head now, “I will be one of the smart ones and quickly unsubscribe as soon as I
                                    get that delicious discount code”. The thing is, you won’t. According to email 
                                    marketing platform Mailchimp, only about 0.25% of people unsubscribe from email 
                                    lists they belong to. So, once you sign up, the business has you trapped receiving 
                                    their emails day in and day out. “But that doesn’t matter!” you’re surely exclaiming,
                                    again, in your head “I will just ignore them like I do all those emails from my mom.” 
                                    Well, bad news...</p>
                                
                                <div className='landing-about-blog-article-image'>
                                    <img src={BehaviorEco2} alt='Behavor Economics'/>
                                </div>
                                <p><b>A/B Testing & Predictive Analytics:</b>There is a famous anecdote that business 
                                    folks love to share. A story about the magical predictive power of behavioral data, 
                                    the buzzword version of “tracking what users are clicking on your website” and 
                                    dynamic email marketing. The tale unfolds that an angry husband and father called 
                                    the headquarters of retailer Target demanding that they stop emailing him to 
                                    advertise diapers and formula. No one in his household was a baby, nor was 
                                    expecting a baby. The target headquarters apologized and said that they would 
                                    unsubscribe him, only for the man to call back shortly after to apologize. His 
                                    teenage daughter, it turns out, had indeed been shopping for pregnancy tests and 
                                    hadn’t yet told her dad – and the magical marketing math had predicted her future 
                                    needs for baby supplies, then let her know that when the time came, mama Target 
                                    was there. While this story may sound a bit silly, in practice, it’s actually quite 
                                    common. Every action you take on a company’s website, social pages, or even in 
                                    store can be captured, analyzed, and predicted. Once you open a pathway for 
                                    messaging, email, text, mobile app, carrier pigeon, whatever – businesses can use 
                                    all this behavior to create a series of tests, incremental offers, interesting articles, 
                                    and more to slowly shift your behavior toward what they want from you.</p>
                                <p>To be continued...</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <LandingFooter/>
        </div>
    )
}

export default LandingBlogBehavior;