import React, { useEffect, useState, useRef } from "react";
import '../App.css';
import { useApi } from '../Contexts/ApiProvider';
import { useAuth0 } from "@auth0/auth0-react";
import PreferredbrandByCat from "./PreferredBrandByCat";
import Modal from 'react-modal';
import Button from 'react-bootstrap/Button';
import DatePicker from 'react-date-picker';
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';
import Form from 'react-bootstrap/Form';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import {toast} from 'react-toastify';
import LoadingSpinner from "./loadingSpinner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmarkCircle } from "@fortawesome/free-solid-svg-icons";
import moment from 'moment';

export default function ActivelyShopping() {
    const api = useApi();
    const { user, getAccessTokenSilently } = useAuth0();
    const [isLoading, setIsLoading] = useState(false);
    const [isPreferredLoading, setIsPreferredLoading] = useState(false);
    let [businessCategories, setBusinessCategories] = useState([]);
    let [selectedCatId, setSelectedCatId] = useState(0);
    let [selectedCatName, setSelectedCatName] = useState('');
    let [deleteReason, setDeleteReason] = useState('');
    let [userLikes, setUserLikes] = useState([]);
    let [activelyShopping, setActivelyShopping] = useState([]);
    let [purchaseDate, setPurchaseDate] = useState();
    let [purchaseAmount, setPurchaseAmount] = useState('');
    let [purchaseDescription, setPurchaseDescription] = useState('');
    let [selectedPurchasedBrand, setSelectedPurchasedBrand] = useState('');
    let [selectedPurchasedBrandId, setSelectedPurchasedBrandId] = useState(0);
    let [selectedPurchasedLocation, setSelectedPurchasedLocation] = useState('');
    let [selectedAdSources, setSelectedAdSources] = useState([]);
    let [consumerBrandsByCategory, setConsumerBrandsByCategory] = useState([]);
    let [purchaseDetailCategory, setPurchaseDetailCategory] = useState('');
    let [activeShopDate, setActiveShopDate] = useState('');
    const adSourceOptions = [
        { value: 1, label: 'facebook' },
        { value: 2, label: 'instagram' },
        { value: 3, label: 'tiktok' },
        { value: 4, label: 'twitter(x)' },
        { value: 5, label: 'youtube' },
        { value: 6, label: 'email' },
        { value: 7, label: 'print' },
        { value: 8, label: 'television' },
        { value: 9, label: 'referral' },
        { value: 10, label: 'brand website'},
        { value: 11, label: 'other' }
      ];
    const animatedComponents = makeAnimated();
    const [addPurchaseDetailsModalIsOpen, setAddPurchaseDetailsModalIsOpen] = React.useState(false);
    const customStylesAddPurchaseDetails = {
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          transform: 'translate(-50%, -50%)',
          background: '#ffffff',
          width: '630px',
          textAlign: 'left',
          height: '650px'
        },
      };      

    const loadPreferredBrands = (selectedId) => {
        (async () => {
            setIsPreferredLoading(true);
            setSelectedCatId(selectedId);
            var apiUrl = 'consumer/preferredbrands/' + selectedId;
            const response = await api.get(apiUrl, null, {
                headers: {
                  Authorization:  'Bearer ' + await getAccessTokenSilently()
                }});
            if (response.ok) {
                setUserLikes(response.body);
                setIsPreferredLoading(false);
            }
            else {
                setUserLikes([]);
                setIsPreferredLoading(false);
            }
        })();
    };

    const loadBrandsByCategoryId = (deleteCatId) => {
        (async () => {
            var apiUrl = 'consumer/category/' + deleteCatId
            const response = await api.get(apiUrl, null, {
                headers: {
                  Authorization:  'Bearer ' + await getAccessTokenSilently()
                }});
            if (response.ok && response.body != null && response.body.length > 0) {
                setConsumerBrandsByCategory(response.body);
                setPurchaseDetailCategory(response.body[0].baseCategoryName);
            }
            else {
                setConsumerBrandsByCategory([]);
            }
        })();
    };

    const saveActivelyShopping = () => {
        (async () => {
            setIsLoading(true);
            var apiUrl = 'consumer/activelyshopping';
            const response = await api.post(apiUrl, {
                categoryId: selectedCatId,
                isActivelyShopping: true
            }, {
                headers: {
                  Authorization:  'Bearer ' + await getAccessTokenSilently()
                }});
            if (response.ok && response.body != null) {
                setActivelyShopping([...activelyShopping, response.body]);                
                setSelectedCatName('');
                setUserLikes([]);
                setAddPurchaseDetailsModalIsOpen(false);
                setIsLoading(false);
            }
            else {
                setIsLoading(false);
            }
        })();
    };

    const deleteActivelyShopping = (deleteId, deleteCatId, deleteCreateDate) => {
        (async () => {
            setIsLoading(true);
            var apiUrl = 'consumer/activelyshopping/' + deleteId;
            const response = await api.post(apiUrl, null, {
                headers: {
                  Authorization:  'Bearer ' + await getAccessTokenSilently()
                }});
            if (response.ok) {
                setActivelyShopping(activelyShopping.filter(a => a.id !== deleteId));
                await loadBrandsByCategoryId(deleteCatId);
                setIsLoading(false);
                setActiveShopDate(deleteCreateDate);
                openAddPurchaseDetails();
            }
            else {
                setIsLoading(false);
            }
        })();
    };

    function openAddPurchaseDetails(){
        setAddPurchaseDetailsModalIsOpen(true);
    }

    const handleChange = (options) => {
        setSelectedAdSources(options);
      };

    function afterOpenModal() {
        // references are now sync'd and can be accessed.
      }
    
    function closePurchaseDetailsModal() {
        setAddPurchaseDetailsModalIsOpen(false);
        setConsumerBrandsByCategory([]);
        setDeleteReason('');
        setSelectedPurchasedLocation('');
        setActiveShopDate('');
        setPurchaseAmount('');
        setPurchaseDescription('');
        setPurchaseDate();
        setSelectedAdSources([]);
    }

    const onSubmit = async (event) => {
        setIsLoading(true);
        event.preventDefault();
        var sources = selectedAdSources.map(sourceId => sourceId.value);
        const data = await api.post('purchase', {
            purchaseDate: purchaseDate,
            purchaseLocation: selectedPurchasedLocation,
            price: purchaseAmount,
            businessId: selectedPurchasedBrandId,
            productDescription: purchaseDescription,
            adSourceIds: sources
          }, {
            headers: {
              Authorization:  'Bearer ' + await getAccessTokenSilently()
            }});
          if (!data.ok) {
            setIsLoading(false);
            toast.error('Unable to save Purchase Details');
          }
          else {
            closePurchaseDetailsModal();
            setIsLoading(false);
            toast('Purchase Details saved')
          }
        };

    useEffect(() => {
        (async () => {
        setIsLoading(true);
        var apiUri = 'business/categories';
        var activelyShoppingApiUri = 'consumer/activelyshopping';
        
        const response = await api.get(apiUri, null, {
            headers: {
              Authorization:  'Bearer ' + await getAccessTokenSilently()
            }});
        const activelyShoppingResponse = await api.get(activelyShoppingApiUri, null, {
            headers: {
              Authorization:  'Bearer ' + await getAccessTokenSilently()
            }});
        if (response.ok && activelyShoppingResponse.ok) {
            setBusinessCategories(response.body);
            setActivelyShopping(activelyShoppingResponse.body)
            setIsLoading(false);
        }
        else {
            setBusinessCategories([]);
            setIsLoading(false);
        }
        })();
    }, []);

  return (
    <div className="actively-shopping-wrapper">
        <div>
            <Modal
                isOpen={addPurchaseDetailsModalIsOpen}
                onAfterOpen={afterOpenModal}
                onRequestClose={closePurchaseDetailsModal}
                style={customStylesAddPurchaseDetails}
                contentLabel="Example Modal"
                id='add-purchase-details-modal'>
                <div style={deleteReason === '' ? {display: 'block'} : {display: 'none'}}>
                    <div className="actively-shopping-done-prompt-header">
                        <div className="actively-shopping-done-prompt-header-text">
                            <span>Actively Shopping Added: {moment(activeShopDate).format('LL')}</span>
                            <span className="actively-shopping-done-prompt-x" onClick={() => {setDeleteReason('no longer shopping');closePurchaseDetailsModal()}}><FontAwesomeIcon icon={faXmarkCircle}/></span>
                        </div>
                    </div>
                    <div className="actively-shopping-done-prompt-subject">
                        <h2>Done shopping?</h2>
                    </div>
                    <div className="actively-shopping-done-prompt-text">
                        <p>Add details about your purchase to improve your Parsal profile even further. Some brands may even pay you a premium for purchases referred from Parsal.</p>
                    </div>
                    <div className="add-purchase-details-modal-reason-btn-wrapper">
                        <button className="add-purchase-details-modal-reason-btn-purchase" onClick={() => {setDeleteReason('purchased')}}>Add Details</button>
                        <button className="add-purchase-details-modal-reason-btn-no-shopping" onClick={() => {setDeleteReason('no longer shopping');closePurchaseDetailsModal()}}>Just Delete</button>
                    </div>
                </div>
                <div className="add-purchase-details-modal-detail-wrapper" style={deleteReason === 'purchased' ? {display: 'block'} : {display: 'none'}}>
                    <div className="actively-shopping-done-prompt-header">
                        <div className="actively-shopping-done-prompt-header-text">
                            <span>Actively Shopping Added: {moment(activeShopDate).format('LL')}</span>
                            <span className="actively-shopping-done-prompt-x" onClick={() => {setDeleteReason('no longer shopping');closePurchaseDetailsModal()}}><FontAwesomeIcon icon={faXmarkCircle}/></span>
                        </div>
                    </div>
                    <div className="add-purchase-details-modal-detail-header" style={{display: 'none'}}>Add Purchase Details</div>
                    <div className="add-purchase-details-modal-detail-sub-header">{purchaseDetailCategory}</div>
                    <Form onSubmit={onSubmit}>
                        <Form.Group className="mb-3 form-section" controlId="exampleForm.ControlInput1">
                            <Form.Label className="form-section-label">Brand</Form.Label>
                            <Form.Control as="select" value={selectedPurchasedBrand} onChange={(selectedValue) => {setSelectedPurchasedBrand(selectedValue.target.value);setSelectedPurchasedBrandId(selectedValue.target.childNodes[selectedValue.target.selectedIndex].getAttribute('id'))}}>
                                <option value='' selected disabled hidden>select...</option>
                                {consumerBrandsByCategory.map((catBrand, index) => <option key={index} id={catBrand.id} value={catBrand.name}>{catBrand.name}</option>)}
                            </Form.Control>
                        </Form.Group>
                        <Form.Group className="mb-3 form-section" controlId="exampleForm.ControlTextarea1">
                            <Form.Label className="form-section-label">Product Purchased</Form.Label>
                            <Form.Control type="textarea" value={purchaseDescription} onChange={(selectedValue) => {setPurchaseDescription(selectedValue.target.value)}}/>
                        </Form.Group>
                        <Form.Group className="">
                            <div>Purchase Date:</div>
                            <DatePicker value={purchaseDate} onChange={setPurchaseDate}/>
                        </Form.Group>
                        <Form.Group className="mb-3 form-section" controlId="exampleForm.ControlTextarea1">
                            <Form.Label className="form-section-label">Price Paid</Form.Label>
                            <Form.Control type="text" placeholder="$" value={purchaseAmount} onChange={(selectedValue) => {setPurchaseAmount(selectedValue.target.value)}}/>
                        </Form.Group>
                        <Form.Group className="mb-3 form-section" controlId="exampleForm.ControlTextarea1">
                            <Form.Label className="form-section-label">Purchase Location</Form.Label>
                            <Form.Control as="select" value={selectedPurchasedLocation} onChange={(selectedValue) => {setSelectedPurchasedLocation(selectedValue.target.value)}}>
                                <option value='' selected disabled hidden>select...</option>
                                <option value='brandwebsite'>Brand Website</option>
                                <option value='reseller'>Reseller</option>
                                <option value='facebook'>Facebook</option>
                                <option value='instagram'>Instagram</option>
                                <option value='tiktok'>TikTok</option>
                                <option value='instore'>In-store</option>
                                <option value='event'>Event</option>
                                <option value='other'>Other</option>
                            </Form.Control>
                        </Form.Group>    
                        <Form.Group className="mb-3 form-section" controlId="exampleForm.ControlTextarea1">
                            <div>Where did you hear about this product</div>
                            <Select options={adSourceOptions} closeMenuOnSelect={false}
                                    components={animatedComponents}
                                    isMulti
                                    onChange={handleChange} />
                        </Form.Group>
                        <Button className="add-purchase-details-modal-save-detail-btn" variant="primary" type="submit">Save</Button><button className="add-purchase-details-modal-close-detail-btn" onClick={() => {closePurchaseDetailsModal()}}>Close</button>
                    </Form>
                </div>
                
            </Modal>
        </div>
        <div style={isLoading ? {display: 'block'} : {display : 'none'}}>
            <LoadingSpinner />
        </div>
        <div style={isLoading ? {display: 'none'} : {display : 'block'}}>
            <div className="actively-shopping-list">
                {activelyShopping.map((activeShop, index) => <div key={index} className="actively-shopping-item">{activeShop.categoryName}<button className="actively-shopping-item-delete-btn" onClick={() => deleteActivelyShopping(activeShop.id, activeShop.categoryId, activeShop.createDate)}>Delete</button></div>)}
            </div>
            <div className="actively-shopping-selection">
                <div className="actively-shopping-username"><span>{user.given_name} is looking for...</span></div>
                <div className="actively-shopping-selection-wrapper">
                    <select value={selectedCatName} className="activley-shopping-selection" onChange={(selectedVal)=> {
                            setSelectedCatId(selectedVal.target.childNodes[selectedVal.target.selectedIndex].id);
                            setSelectedCatName(selectedVal.target.value);
                            loadPreferredBrands(selectedVal.target.childNodes[selectedVal.target.selectedIndex].id);
                        }}>
                        <option value='' disabled hidden selected>Select..</option>
                        {businessCategories.map((cat, index) => 
                            <option key={index} id={cat.pCategoryID}>{cat.name}</option>)}                    
                    </select>
                </div>
                <div className="actively-shopping-selection-save-btn-wrapper">
                    <button className="actively-shopping-selection-save-btn" onClick={() => saveActivelyShopping()}>Save</button>
                </div>
            </div>
            {/*<div style={{display:'none'}}>
                <div><span>...type of {selectedCatName}...</span></div>
                <div>
                    <select>
                        {businessCategories.filter((p) => p.categoryName === selectedCatName).map((subCat, index) => 
                        subCat.subCategories.map((subCatName, index) => <option value={subCat.categoryId} key={index}>{subCatName}</option>))}                    
                    </select>
                </div>
                        </div>*/}
            <div className="actively-shopping-preferred-brands-wrapper">
                <div className="actively-shopping-preferred-brands-header">Preferred Brands</div>
                <div style={isPreferredLoading ? {display : 'block'} : {display: 'none'}}>
                    <LoadingSpinner/>
                </div>
                <div  style={isPreferredLoading ? {display : 'none'} : {display: 'block'}} className="actively-shopping-preferred-brand">{userLikes.map((userLike, index) => (<PreferredbrandByCat brand={userLike} key={index}/>))}</div>
            </div>
        </div>
    </div>
  );
}