import React from "react";
import '../../LandingPage.css';
import PhoneImgReview from '../../landingPagePhone1.png';
import PhoneImgProfile from '../../landingPagePhone2.png';
import PhoneImgEnhanceProfile from '../../landingPagePhone3.png';
import PhoneImgGiveFeedback from '../../landingPagePhone4.png';
import PhoneImgGetPaid from '../../landingPagePhone5.png';
import { Carousel } from "react-responsive-carousel";

export default function LandingCarousel() {
  return (
    <div>
        <Carousel 
            autoPlay={true}
            infiniteLoop={true}
            interval={10000}
            showIndicators={false}>
        <div>
            <div>
                <img src={PhoneImgProfile} className='landing-page-carousel-img'></img>
            </div>
            <div className='landing-page-carousel-description'>
                Create a profile
            </div>
        </div>
        <div>
            <div>
                <img src={PhoneImgEnhanceProfile} className='landing-page-carousel-img'></img>
            </div>
            <div className='landing-page-carousel-description'>
                Enhance your profile
            </div>
        </div>
        <div>
            <div>
                <img src={PhoneImgReview} className='landing-page-carousel-img'></img>
            </div>
            <div className='landing-page-carousel-description'>
                Review ads targetting you
            </div>
        </div>
        <div>
            <div>
                <img src={PhoneImgGiveFeedback} className='landing-page-carousel-img'></img>
            </div>
            <div className='landing-page-carousel-description'>
                Give Brands Feedback
            </div>
        </div>
        <div>
            <div>
                <img src={PhoneImgGetPaid} className='landing-page-carousel-img'></img>
            </div>
            <div className='landing-page-carousel-description'>
                Secure & Sell Your Profile
            </div>
        </div>
        </Carousel>
    </div>
  );
}