// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import Config from "./Config";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyCRJBATyh76GNGXFF_K_6WweJ9Io8ooANQ",
    authDomain: "parsel-383203.firebaseapp.com",
    projectId: "parsel-383203",
    storageBucket: "parsel-383203.appspot.com",
    messagingSenderId: "1005229990234",
    appId: "1:1005229990234:web:f2257e8db465861da75363",
    measurementId: "G-0CFSZ3NPHE"
};

// Initialize Firebase
initializeApp(firebaseConfig);
const messaging = getMessaging();


export const requestForToken = () => {
    return getToken(messaging, { vapidKey: 'BJgU0Cj4AIu_ZX3oLJ_lGcCNc7Xe3uRN5VxnLK4VU6tll8Se2rNyg3xrbZI3WdFFuOkmYUh4OUUYzUeKDzBU90Y'})
      .then((currentToken) => {
        if (currentToken) {
          console.log('current token for client: ', currentToken);
          // Perform any other neccessary action with the token
        } else {
          // Show permission request UI
          console.log('No registration token available. Request permission to generate one.');
        }
      })
      .catch((err) => {
        console.log('An error occurred while retrieving token. ', err);
      });
  };

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      console.log("payload", payload)
      resolve(payload);
    });
  });