import '../LandingPage.css';
import React, { useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import LandingCarousel from '../Components/LandingPage/LandingCarousel';
import LandingNav from '../Components/LandingPage/LandingNav';
import LandingFooter from '../Components/LandingPage/LandingFooter';
import { useNavigate } from 'react-router-dom';
import AdSense from '../Components/AdSense';

function Landing() {
    const { loginWithRedirect, user, isAuthenticated } = useAuth0();
    const navigate = useNavigate();

        const handleLogin = async () => {
            await loginWithRedirect({
            appState: {
                access_type: 'offline', 
                connection_scope: 'https://www.googleapis.com/auth/gmail.readonly', 
                approval_prompt: 'force',
                returnTo: "/home",
                useRefreshTokens: true
            },
        });
    }

    useEffect(() => {
        if(isAuthenticated || user !== undefined){
            navigate('/home');
        }
    });

    return(
        <div className='landing-page-wrapper' style={{overflow: 'hidden'}}>
            <LandingNav />
            <div className='landing-page-content-wrapper'>
                <div className='landing-page-content-area-one-wrapper'>
                    <div className='landing-page-content-area-one'>
                        <div className='landing-page-content-area-one-welcome-wrapper'>
                            <div>Welcome</div>
                            <div className='landing-page-content-area-one-welcome-sub-text'>Get ready, to get <span className='parsal-yellow'>Parsal-ing</span></div>
                        </div>
                        <div className='landing-page-content-area-one-description-wrapper'>
                            <div>It's your data, you should get paid for it. Parsal puts you in the drivers seat to create unique, valuable personal data, determine who should get access to it, and get paid!</div>
                        </div>
                        <div className='landing-page-content-area-one-sign-up-wrapper'>
                            <div className='landing-page-content-area-one-sign-up-description'>
                                <div>Sign up today to secure your place in the digital gig-economy.</div>
                            </div>
                            <div className='landing-page-content-area-one-sign-up'>
                                {/*<input className='landing-page-sign-up-email' type='email' placeholder='email address'></input>
                                <span className='spacer'></span>*/}
                                <button className='landing-page-sign-up-btn' onClick={handleLogin}>Sign Up</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='landing-page-content-area-two'>
                    <div className='landing-page-content-area-two-carousel-wrapper'>
                        <LandingCarousel/>
                    </div>
                </div>
                {/*<AdSense dataAdSlot='2013861535' />*/}
            </div>
            <LandingFooter/>
        </div>
    )
}

export default Landing;