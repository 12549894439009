import '../App.css';
import { faCommentSlash, faCommentDots, faCommentDollar, faChevronCircleLeft, faChevronCircleRight, faTag, faThumbsUp, faHeart, faShare } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import parse from 'html-react-parser';
import Modal from 'react-modal';
import InputField from '../Components/InputField';
import DefaultAdvertisementContent from './DefaultAdvertisementContent';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { useApi } from '../Contexts/ApiProvider';
import React, { useState, useRef} from 'react';
import {useCollapse} from 'react-collapsed';
import { useAuth0 } from "@auth0/auth0-react";
import moment from 'moment';
import {toast} from 'react-toastify';

function Advertisement({userAd, adIndex, onReviewSubmission, currentIndex, autoShop, autoUnsubscribe, navToNextAd, navToPrevAd, hasNext, hasPrev}) {
    const [formErrors, setFormErrors] = useState({});
    const hasAlreadyPurchasedField = useRef(false);
    const isInterestedField = useRef(false);
    const isActivelyShoppingField = useRef(false);
    const isWithinPriceRangeField = useRef(false);
    const isTooExpensiveField = useRef(false);
    const isShoppingWithinThirtyDaysField = useRef(false);
    const isNotTheRightTargetField = useRef(false);
    const isNotTheRightTimeField = useRef(false);
    const isRightFitField = useRef(false);
    const purchaseLikelyRankField = useRef(0);
    let [feedbackNotes, setFeedbackNotes] = useState('');
    let [shareToEmailAddress, setShareToEmailAddress] = useState('');
    let [socialShoppingNetwork, setSocialShoppingNetowrk] = useState([]);
    const api = useApi();
    const { user, getAccessTokenSilently } = useAuth0();
    const { getCollapseProps, getToggleProps } = useCollapse();
    const [selectedConnectionIds, setSelectedConnectionIds] = useState([]);

    const customStylesInterested = {
        content: {
          top: '55%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          transform: 'translate(50%, -50%)',
          background: '#ffbf46',
          width: '25%',
          opacity: '0.8',
          textAlign: 'left',
          height: '400px'
        },
      };
    const customStylesNotInterested = {
        content: {
          top: '55%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          transform: 'translate(-150%, -50%)',
          background: '#575761',
          width: '25%',
          opacity: '0.8',
          textAlign: 'left',
          height: '400px'
        },
      };
    const customStylesFeedback = {
        content: {
          top: '20%',
          left: '35%',
          right: 'auto',
          bottom: 'auto',
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          width: '30%',
          textAlign: 'center',
          height: '65%'
        },
      };
    const customStylesShareAd = {
      content: {
        top: '10%',
        left: '13%',
        right: 'auto',
        bottom: 'auto',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        width: '74%',
        textAlign: 'center',
        height: '22%'
      },
    };
    const [interestedModalIsOpen, setInterestedModalIsOpen] = React.useState(false);
    const [notInterestedModalIsOpen, setNotInterestedModalIsOpen] = React.useState(false);
    const [feedbackModalIsOpen, setFeedbackModalIsOpen] = React.useState(false);
    const [shareAdModalIsOpen, setShareAdModalIsOpen] = React.useState(false);
  
    function openInterestedModal() {setInterestedModalIsOpen(true);}

    function openNotInterestedModal() {setNotInterestedModalIsOpen(true);}

    function openFeedbackModal() {setFeedbackModalIsOpen(true);}
    
    function openShareAdModal() {setShareAdModalIsOpen(true);}
  
    function afterOpenModal() {
      // references are now sync'd and can be accessed.
      GetSocialShoppingNetwork();
    }
  
    function closeInterestedModal() {setInterestedModalIsOpen(false);}

    function closeNotInterestedModal() {setNotInterestedModalIsOpen(false);}

    function closeFeedbackModal() {setFeedbackModalIsOpen(false);}

    function closeShareAdModal() {
      setShareAdModalIsOpen(false);
      setShareToEmailAddress('');
    }

    function getDate(dateVal)
    {
      var formattedDate = moment(dateVal).format('LL');
      return formattedDate;
    }

    const onSubmit = async (event) => {
        event.preventDefault();
        const data = await api.post('interaction/feedback/ad', {
            advertisementId: userAd.id,
            email: user.email,
            hasAlreadyPurchased: hasAlreadyPurchasedField.current.checked,
            isInterested: isInterestedField.current.checked,
            isActivelyShopping: isActivelyShoppingField.current.checked,
            isBuyingWithinThirtyDays: isShoppingWithinThirtyDaysField.current.checked,
            isTooExpensive: isTooExpensiveField.current.checked,
            isWithinPriceRange: isWithinPriceRangeField.current.checked,
            isNotRightTarget: isNotTheRightTargetField.current.checked,
            isNotRightTime: isNotTheRightTimeField.current.checked,
            isRightFit : isRightFitField.current.checked,
            purchaseLikelyRank: parseInt(purchaseLikelyRankField.current.value),
            feedbackNotes: feedbackNotes
          }, {
            headers: {
              Authorization:  'Bearer ' + await getAccessTokenSilently()
            }});
          if (!data.ok) {
            setFormErrors(data.body.errors.json);
          }
          else {
            setFormErrors({});
            if(isActivelyShoppingField.current.checked && autoShop){
              saveActivelyShopping();
            }
            if(autoUnsubscribe && !isInterestedField.current.checked && userAd.unsubscribeUrl !== undefined && userAd.unsubscribeUrl !== null && userAd.unsubscribeUrl !== "" && isNotTheRightTargetField.current.checked){
              setTimeout(() => {window.open(userAd.unsubscribeUrl, '_top');});
            }
            onReviewSubmission(userAd.id);
          }
        };

        const saveActivelyShopping = () => {
          (async () => {
              var apiUrl = 'consumer/activelyshopping';
              const response = await api.post(apiUrl, {
                  categoryId: userAd.business.categories[0].pCategoryID,
                  isActivelyShopping: true
              }, {
                  headers: {
                    Authorization:  'Bearer ' + await getAccessTokenSilently()
                  }});
              if (response.ok && response.body.isActivelyShopping === true) {
                toast('Actively shopping saved');
              }
              else {
              }
          })();
      };

      const handleCheck = (event) => {
        var selectedConnections_array = [...selectedConnectionIds];
        if (event.target.checked) {
          selectedConnections_array = [...selectedConnectionIds, event.target.id];
        } else {
          selectedConnections_array.splice(selectedConnectionIds.indexOf(event.target.id), 1);
        }
        setSelectedConnectionIds(selectedConnections_array);
      
      };

      const ShareAd = async (event) => {
        event.preventDefault();
        if (selectedConnectionIds.length > 0) {
          var apiUrl = 'advertisement/share/consumerIds';
          const response = await api.post(apiUrl, {
              consumerIds: selectedConnectionIds,
              advertisementId: userAd.id,
              businessId: userAd.business.id
          }, {
              headers: {
                Authorization:  'Bearer ' + await getAccessTokenSilently()
              }});
          if (response.ok) {
            closeShareAdModal();
            toast('Advertisement successfully shared');
          }
          else {
            toast.error('Unable to share advertisement');
          }
        }
        else {
          var apiUrl = 'advertisement/share';
          const response = await api.post(apiUrl, {
              fromEmail: user.email,
              toEmail: shareToEmailAddress,
              advertisementId: userAd.id,
              businessId: userAd.business.id
          }, {
              headers: {
                Authorization:  'Bearer ' + await getAccessTokenSilently()
              }});
          if (response.ok) {
            closeShareAdModal();
            toast('Advertisement successfully shared');
          }
          else {
            toast.error('Unable to share advertisement');
          }
        }
      };

    
      const GetSocialShoppingNetwork = async () => {
        var apiUrl = 'social';
        const response = await api.get(apiUrl, null, {
            headers: {
              Authorization:  'Bearer ' + await getAccessTokenSilently()
            }});
        if (response.ok) {
          setSocialShoppingNetowrk(response.body);
        }
        else {
          toast.error('Unable to get social shopping network');
        }
      };

        const notApplicabeResponse = async () => {
          const data = await api.post('interaction/feedback/ad', {
              advertisementId: userAd.id,
              email: user.email,
              hasAlreadyPurchased: hasAlreadyPurchasedField.current.checked,
              isInterested: isInterestedField.current.checked,
              isActivelyShopping: isActivelyShoppingField.current.checked,
              isBuyingWithinThirtyDays: isShoppingWithinThirtyDaysField.current.checked,
              isTooExpensive: isTooExpensiveField.current.checked,
              isWithinPriceRange: isWithinPriceRangeField.current.checked,
              isNotTheRightTarget: isNotTheRightTargetField.current.checked,
              isNotTheRightTime: isNotTheRightTimeField.current.checked,
              isRightFit : isRightFitField.current.checked,
              purchaseLikelyRank: parseInt(purchaseLikelyRankField.current.value),
              feedbackNotes: "",
              isNotAd: true
            }, {
              headers: {
                Authorization:  'Bearer ' + await getAccessTokenSilently()
              }});
            if (!data.ok) {
              setFormErrors(data.body.errors.json);
            }
            else {
              setFormErrors({});
              onReviewSubmission(userAd.id);
            }
          };

    return (        
    <div className="ad-main-container" id={userAd.id} style={adIndex === currentIndex ? {display: 'block'} : {display: 'none'}}>
      <div className='brand-feedback-super-like-btn-wrapper' style={{zIndex: '99'}}>
        <button className='brand-feedback-super-like-btn'><FontAwesomeIcon icon={faShare} onClick={() => { openShareAdModal() }}/></button>
        <div style={{color: 'black'}}>Share</div>
      </div>
      <div>
        <Modal
          isOpen={shareAdModalIsOpen}
          onAfterOpen={afterOpenModal}
          onRequestClose={closeShareAdModal}
          style={customStylesShareAd}
          contentLabel="Example Modal"
          id='share-ad-modal'>
          <Form onSubmit={ShareAd}>
            <Form.Group className="mb-3 form-section" controlId="exampleForm.ControlTextarea1">
                <Form.Label className="form-section-label">Email</Form.Label>
                <Form.Control type="email" value={shareToEmailAddress} onChange={(selectedValue) => (setShareToEmailAddress(selectedValue.target.value))}/>
            </Form.Group>
            <div style={{color: 'white', fontSize: '22px'}}>
              -- OR --
            </div>
            <div>
              <ul className="social-connection-list" style={{textAlign: 'left'}}>
                    {socialShoppingNetwork.filter(i => i.status === "Accepted").map((connection, index) => (
                        <li className="social-connection">
                          <Form.Group style={{display: 'inline-block'}}>
                            <Form.Control type='checkbox' id={connection.consumerId} onChange={handleCheck}/>
                          </Form.Group> 
                            <div className="social-connection-icon-share">
                                {connection.firstName.substring(0, 1)}{connection.lastName.substring(0, 1)}
                            </div>
                            <div className="social-connection-details-wrapper-share">
                                <div className="social-connection-name">
                                    {connection.firstName} {connection.lastName}
                                </div>
                            </div>           
                        </li>
                    ))}
                </ul>
            </div>
          <Button variant="primary" type="submit" className='interested-modal-interested-btn'>
                <span className='feedback-modal-btn-icon'><FontAwesomeIcon icon={faShare}/></span>
                <span>Share</span>
            </Button>                     
          </Form>
        </Modal>
      </div>
            <div>
                <Modal
                    isOpen={interestedModalIsOpen}
                    onAfterOpen={afterOpenModal}
                    onRequestClose={closeInterestedModal}
                    style={customStylesInterested}
                    contentLabel="Example Modal"
                    id='is-interested-modal'>
                    <Form onSubmit={onSubmit}>
                        <InputField
                        name="isInterested" label="Interested" type="checkbox" shouldShow="false"
                        error={formErrors.isInterested} fieldRef={isInterestedField} isChecked="true"/>
                        <InputField
                        name="isActivelyShopping" label="Actively Shopping" type="checkbox"
                        error={formErrors.isActivelyShopping} fieldRef={isActivelyShoppingField} />
                        <InputField
                        name="isShoppingWithinThirtyDays" label="Save For Later" type="checkbox"
                        error={formErrors.isShoppingWithinThirtyDays} fieldRef={isShoppingWithinThirtyDaysField} />
                        <InputField
                        name="hasAlreadyPurchased" label="Already Purchased" type="checkbox" shouldShow="false"
                        error={formErrors.hasAlreadyPurchased} fieldRef={hasAlreadyPurchasedField} />
                        <InputField
                        name="isWithinPriceRange" label="In Price Range" type="checkbox"
                        error={formErrors.isWithinPriceRange} fieldRef={isWithinPriceRangeField} />
                        <InputField
                        name="isRightFit" label="Fits Me" type="checkbox"
                        error={formErrors.isRightFit} fieldRef={isRightFitField} />
                        <InputField
                        name="isNotTheRightTime" label="Not The Right Time" type="checkbox" shouldShow="false"
                        error={formErrors.isNotTheRightTime} fieldRef={isNotTheRightTimeField} />
                        <InputField
                        name="isTooExpensive" label="Too Expensive" type="checkbox" shouldShow="false"
                        error={formErrors.isTooExpensive} fieldRef={isTooExpensiveField} />
                        <InputField
                        name="isNotTheRightTarget" label="Not The Right Target" type="checkbox" shouldShow="false"
                        error={formErrors.isNotTheRightTarget} fieldRef={isNotTheRightTargetField} />
                        <InputField
                        name="purchaseLikelyRank" label="Likely" type="number" shouldShow="false"
                        error={formErrors.purchaseLikelyRank} fieldRef={purchaseLikelyRankField} placeholder={0}/>
                        <Button variant="primary" type="submit" className='interested-modal-interested-btn'>
                            <span className='feedback-modal-btn-icon'><FontAwesomeIcon icon={faCommentDollar}/></span>
                            <span>Submit</span>
                        </Button>                     
                    </Form>
                </Modal>
              </div>
              <div>
                <Modal
                    isOpen={notInterestedModalIsOpen}
                    onAfterOpen={afterOpenModal}
                    onRequestClose={closeNotInterestedModal}
                    style={customStylesNotInterested}
                    contentLabel="Example Modal"
                    id='not-interested-modal'>
                    <Form onSubmit={onSubmit}>
                    <InputField
                        name="isInterested" label="Interested" type="checkbox" shouldShow="false"
                        error={formErrors.isInterested} fieldRef={isInterestedField} />
                        <InputField
                        name="isActivelyShopping" label="Actively Shopping" type="checkbox" shouldShow="false"
                        error={formErrors.isActivelyShopping} fieldRef={isActivelyShoppingField} />
                        <InputField
                        name="isShoppingWithinThirtyDays" label="Shopping Within 30 Days" type="checkbox" shouldShow="false"
                        error={formErrors.isShoppingWithinThirtyDays} fieldRef={isShoppingWithinThirtyDaysField} />
                        <InputField
                        name="hasAlreadyPurchased" label="Already Purchased" type="checkbox"
                        error={formErrors.hasAlreadyPurchased} fieldRef={hasAlreadyPurchasedField} />
                        <InputField
                        name="isWithinPriceRange" label="In Price Range" type="checkbox" shouldShow="false"
                        error={formErrors.isWithinPriceRange} fieldRef={isWithinPriceRangeField} />
                        <InputField
                        name="isRightFit" label="Fits Me" type="checkbox" shouldShow="false"
                        error={formErrors.isRightFit} fieldRef={isRightFitField} />
                        <InputField
                        name="isNotTheRightTime" label="Not The Right Time" type="checkbox"
                        error={formErrors.isNotTheRightTime} fieldRef={isNotTheRightTimeField} />
                        <InputField
                        name="isTooExpensive" label="Too Expensive" type="checkbox"
                        error={formErrors.isTooExpensive} fieldRef={isTooExpensiveField} />
                        <InputField
                        name="isNotTheRightTarget" label="Not The Right Target" type="checkbox"
                        error={formErrors.isNotTheRightTarget} fieldRef={isNotTheRightTargetField} />
                        <InputField
                        name="purchaseLikelyRank" label="Likely" type="number" shouldShow="false"
                        error={formErrors.purchaseLikelyRank} fieldRef={purchaseLikelyRankField} placeholder={0}/>
                        <Button variant="primary" type="submit" className='not-interested-modal-not-interested-btn'>
                            <span className='feedback-modal-btn-icon'><FontAwesomeIcon icon={faCommentSlash}/></span>
                            <span>Submit</span>
                        </Button>
                    </Form>
                </Modal>
              </div>
              <div>
                <Modal
                    isOpen={feedbackModalIsOpen}
                    onAfterOpen={afterOpenModal}
                    onRequestClose={closeFeedbackModal}
                    style={customStylesFeedback}
                    contentLabel="Example Modal"
                    id='provide-feedback-modal'>
                    <Form>
                        <Form.Group className="mb-3 form-section" controlId="exampleForm.ControlInput1">
                          <Form.Label className="form-section-label"></Form.Label>
                          <textarea className='review-feedback-txt-area' placeholder='Feedback' value={feedbackNotes} onChange={(feedbackNotesValue) => {setFeedbackNotes(feedbackNotesValue.target.value)}}/>
                        </Form.Group>
                        <Button variant="primary" type="submit" className='send-feedback-modal-btn' onClick={() => {closeFeedbackModal()}}>
                            <span>Save Feedback</span>
                        </Button>               
                    </Form>
                    <div>{userAd.metadata}</div>
                </Modal>
            </div>
            <div style={{contain: 'style', height: '100%'}}>
              {userAd.adSource === "Gmail" ? parse(userAd.advertisementHtml) : userAd.isIFrameBlocked ? <DefaultAdvertisementContent adBusiness={userAd.business} reviewLink={userAd.advertisementHtml}/> :
                  <iframe sandbox='' id="iFrameExample"
                      title="iFrame Example"
                      src={userAd.advertisementHtml}
                      style={{height: '100%', width: '600px', border: 'none'}}>
                  </iframe>
                }
            </div>
        <div className="ad-container-inner" id="carouselExampleIndicators">
            <div id="ad-carousel-main">
              <div className='overlay-feedback-btn-arrows-wrapper'>
                <button className='overlay-feedback-btn-arrow overlay-feedback-btn-arrow-left overlay-feedback-btn-arrow-mobile' style={hasPrev ? {} : {display: 'none'}} onClick={()=>{navToPrevAd(currentIndex - 1)}}>
                    <FontAwesomeIcon icon={faChevronCircleLeft} className='overlay-feedback-btn-arrow-icon'/>
                </button>
                <button className='overlay-feedback-btn-arrow overlay-feedback-btn-arrow-right overlay-feedback-btn-arrow-mobile' style={hasNext ? {} : {display: 'none'}} onClick={()=>{navToNextAd(currentIndex + 1)}}>
                    <FontAwesomeIcon icon={faChevronCircleRight} className='overlay-feedback-btn-arrow-icon'/>
                </button>
              </div>
                <div className="ad-tile-overlay collapsible">
                    <div className="ad-tile-overlay-line header" {...getToggleProps()}></div>
                    <div {...getCollapseProps()}>
                        <div className="content">
                          <div className='overlay-expanded-text-business-name'>
                            <div>{userAd.business.name}</div>
                            <div className='ad-preferred-brand-wrapper' style={userAd.isSuperPreferredBrand === false && userAd.isPreferredBrand === true ? {display: 'block'} : {display: 'none'}}><span className='ad-preferred-brand-icon'><FontAwesomeIcon icon={faThumbsUp}/></span> Preferred Brand</div>
                            <div className='ad-favorite-brand-wrapper' style={userAd.isSuperPreferredBrand === true && userAd.isPreferredBrand === false ? {display: 'block'} : {display: 'none'}}><span className='ad-favorite-brand-icon'><FontAwesomeIcon icon={faHeart}/></span> Favorite Brand</div>
                          </div>
                          <div>
                              <span>{userAd.businessCategoryName}</span>    
                              <div style={userAd.isActivelyShopping ? {display: 'block'} : {display: 'none'}}>
                                <span className='actively-shopping-indicator-text'><FontAwesomeIcon icon={faTag}/> Actively Shopping</span>
                              </div>                          
                          </div>
                          <div className='overlay-expanded-text-dollars'>
                              <span></span>                              
                          </div>
                          <div>
                              <span>{userAd.sharedBy !== '' ? <span>Shared by: {userAd.sharedBy}</span> : userAd.adSource === 'Gmail' ? <span>Email received</span> : <span>Interacted</span>} on {getDate(userAd.createDate)}</span>                              
                          </div>
                          <div>
                              <span>{userAd.adSource}</span>               
                          </div>
                          <div style={userAd.adSource === "Gmail" ? {display: 'none'} : {display: 'block'}}>
                            <span><a href={userAd.advertisementHtml} target='_blank'>Shop</a></span>
                          </div>
                          <div style={userAd.adSource === "Gmail" ? {display: 'block'} : {display: 'none'}}>
                            <span><a href={userAd.business.webUrl} target='_blank'>Shop</a></span>
                          </div>
                        </div>
                      </div> 
                    <div className="overlay-feedback-btn-row">
                        <button className='overlay-feedback-btn-arrow overlay-feedback-btn-arrow-left' style={hasPrev ? {} : {display: 'none'}} onClick={()=>{navToPrevAd(currentIndex - 1)}}>
                            <FontAwesomeIcon icon={faChevronCircleLeft} className='overlay-feedback-btn-arrow-icon'/>
                        </button>
                        <button onClick={openNotInterestedModal} className="overlay-feedback-btn">
                            <FontAwesomeIcon icon={faCommentSlash}/>
                            <span style={{ display: 'block' }}>Not Interested</span>
                        </button>
                        <button onClick={openFeedbackModal} className="overlay-feedback-middle">
                            <FontAwesomeIcon icon={faCommentDots}/>
                        </button>
                        <button onClick={openInterestedModal} className="overlay-feedback-btn-interested">
                            <FontAwesomeIcon icon={faCommentDollar}/>
                            <span style={{ display: 'block' }}>Interested</span>
                        </button>
                        <button className='overlay-feedback-btn-arrow overlay-feedback-btn-arrow-right' style={hasNext ? {} : {display: 'none'}} onClick={()=>{navToNextAd(currentIndex + 1)}}>
                            <FontAwesomeIcon icon={faChevronCircleRight} className='overlay-feedback-btn-arrow-icon'/>
                        </button>
                        <div><button className='ad-does-not-apply-wrapper' onClick={()=>{notApplicabeResponse()}}>Doesn't Apply or Not an Ad</button></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    );
  }

export default Advertisement;