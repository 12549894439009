import '../App.css';
import { React, useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useApi } from '../Contexts/ApiProvider'
import { useNavigate } from 'react-router-dom';

function Banner({isHomePage}){
    const { user, getAccessTokenSilently } = useAuth0();
    const navigate = useNavigate();
    const api = useApi();
    const [userId, setUserId] = useState(0);
    const [userAvailableAdCount, setSerAvailableAdCount] = useState(0);

    {/*useEffect(() => {
        (async () => {
            const response = await api.get('consumer/available/' + user.email, null, {
                headers: {
                  Authorization:  'Bearer ' + await getAccessTokenSilently()
                }});
            if (response.ok) {
                setSerAvailableAdCount(response.body);
            }
            else {
                setSerAvailableAdCount(0);
            }
        })();
        }, [api]);*/}

    const navToPage = () => {
        navigate('/ads');
    }
        return (
            <div className='non-home-page-banner-container'>
                <div className='banner-welcome-text'>Welcome Back, {user.name} </div>
                <div className='non-home-summary-bubble' onClick={navToPage}>
                    <div className='non-home-summary-bubble-content'>{userAvailableAdCount}</div>
                </div>
            </div>
            );
    };

export default Banner;