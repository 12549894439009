import { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { useApi } from '../Contexts/ApiProvider';
import { useAuth0 } from "@auth0/auth0-react";
import ParsalIconLg from '../ParsalIcon64.png'
import {toast} from 'react-toastify';
import LoadingSpinner from "../Components/loadingSpinner";

export default function DataSyncPage() {
  const [formErrors, setFormErrors] = useState({});
  let [profileFirstName, setProfileFirstName] = useState('');
  let [profileLastName, setProfileLastName] = useState('');
  let [profileEmail, setProfileEmail] = useState('');
  let [externalDataRequest, setExternalDataRequest] = useState('NOT RUN');
  const { user, getAccessTokenSilently } = useAuth0();
  const navigate = useNavigate();
  const api = useApi();
  const [isLoading, setIsLoading] = useState(true);
  const [consumerExternalData, setConsumerExternalData] = useState({
    firstName: user.given_name,
    lastName: user.family_name,
    gender: '',
    address: '',
    city: '',
    state: '',
    postalCode: '',
    addressLine1: '',
    addressLine2: '',
    lengthOfResidency: '',
    headOfHouseHold: false,
    hasChildren: false,
    ethnicity: '',
    educationLevel: '',
    estimatedIncomeRange: '',
    estimatedWealthRange: '',
    age: 0,
    maritalStatus: '',
    homeownerType: '',
    medHomeValue: '',
    ageRange: '',
    dOB: ''
  });

  const getExternalData = () => {
    (async () => {
        setIsLoading(true);
        var apiUrl = 'consumer/sync';
        const response = await api.get(apiUrl, null, {
            headers: {
              Authorization:  'Bearer ' + await getAccessTokenSilently()
            }});
        if (response.ok && response.body !== null) {
          setExternalDataRequest('MATCH');
          setConsumerExternalData(response.body);
          setIsLoading(false);
        }
        else {
          setExternalDataRequest('FAILED');
          setIsLoading(false);
        }
    })();
  };

  const navToPage = () => {
    navigate('/social');
}

  useEffect(() => {
    setIsLoading(true);
    setProfileFirstName(user.given_name);
    setProfileLastName(user.family_name);
    setProfileEmail(user.email);
    (async () => {
        var apiUri = 'consumer';
        
        const response = await api.get(apiUri, null, {
            headers: {
              Authorization:  'Bearer ' + await getAccessTokenSilently()
            }});
        if (response.ok) {
          setIsLoading(false);
        }
        else {
          setIsLoading(false);
        }
    })();
    }, []);

  const onSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    const data = await api.post('consumer/demographic', {
        gender: consumerExternalData.gender,
        ethnicity: consumerExternalData.ethnicity,
        educationLevel: consumerExternalData.educationLevel,
        maritalStatus: consumerExternalData.maritalStatus,
        estimatedIncomeRange: consumerExternalData.estimatedIncomeRange,
        estimatedWealthRange: consumerExternalData.estimatedWealthRange,
        homeownerType: consumerExternalData.homeownerType,
        medHomeValue: consumerExternalData.medHomeValue,
        ageRange: consumerExternalData.ageRange,
        age: consumerExternalData.age,
        lengthOfResidency: consumerExternalData.lengthOfResidency,
        headOfHouseHold: consumerExternalData.headOfHouseHold,
        hasChildren: consumerExternalData.hasChildren,
        dob: consumerExternalData.dOB
      }, {
        headers: {
          Authorization:  'Bearer ' + await getAccessTokenSilently()
        }});
      if (!data.ok) {
        toast.error('Unable to save demographic data');
        setIsLoading(false);
      }
      else {
        setIsLoading(false);
        navigate('/social');
      }
    };

  return (
    <div className='registration-content'>
      <div className='registration-content-inner'>
        <div className='registration-content-header'>
          <div className='registration-content-header-logo-container'><img src={ParsalIconLg} className='registration-content-header-logo'/></div>
          <div className='registration-content-header-text'>See what data is availabe about you</div>
          <div className='data-sync-subtext-wrapper'>
            <h2>See what information companies have about you that they are selling and take advantage of it by importing it into your profile</h2>
            <div style={externalDataRequest === 'MATCH' ? {display: 'block', paddingTop: '30px'} : {display: 'none'}}>
              <h2>Your data was found! Now you can decide to save it to your profile to increase you chances of being sold as a lead by clicking save below, or you can skip and we will discard this data. You can make this request again from your profile section later.</h2>
            </div>
            <div style={externalDataRequest === 'FAILED' ? {display: 'block', paddingTop: '30px'} : {display: 'none'}}>
              <h2>We were not able to find a match with your information to boost your profile. You can manually enter your information here, or try again later from your profile section.</h2>
            </div>
            <div style={externalDataRequest === 'NOT RUN' ? {display: 'block', paddingTop: '30px'} : {display: 'none'}}>
              <button className='standard-btn' onClick={() => {getExternalData()}}>See Data</button>
            </div>
          </div>
        </div>
        <div style={isLoading ? {display: 'block'} : {display: 'none'}}>
          <LoadingSpinner />
        </div>
        <div style={isLoading ? {display: 'none'} : {display: 'block'}}>
          <Form onSubmit={onSubmit} style={externalDataRequest !== 'NOT RUN' ? {display: 'block'} : {display: 'none'}}>
            <Form.Group className="mb-3 form-section" controlId="exampleForm.ControlInput1">
                <Form.Label className="registration-form-section-label">Gender</Form.Label>
                <Form.Control className='registration-form-input' type="text" value={consumerExternalData.gender} onChange={(selectedValue) => {setConsumerExternalData({...consumerExternalData, gender: selectedValue.target.value})}}/>
            </Form.Group>
            <Form.Group className="mb-3 form-section" controlId="exampleForm.ControlTextarea1">
                <Form.Label className="registration-form-section-label">Age</Form.Label>
                <Form.Control className='registration-form-input' type="text" value={consumerExternalData.age} onChange={(selectedValue) => {setConsumerExternalData({...consumerExternalData, age: selectedValue.target.value})}}/>
            </Form.Group>
            <Form.Group className="mb-3 form-section" controlId="exampleForm.ControlTextarea1">
                <Form.Label className="registration-form-section-label">Date of Birth</Form.Label>
                <Form.Control className='registration-form-input' type="text" value={consumerExternalData.dOB} onChange={(selectedValue) => {setConsumerExternalData({...consumerExternalData, dOB: selectedValue.target.value})}}/>
            </Form.Group>
            <Form.Group className="mb-3 form-section" controlId="exampleForm.ControlTextarea1">
                <Form.Label className="registration-form-section-label">Ethnicity</Form.Label>
                <Form.Control className='registration-form-input' type="text" value={consumerExternalData.ethnicity} onChange={(selectedValue) => {setConsumerExternalData({...consumerExternalData, ethnicity: selectedValue.target.value})}}/>
            </Form.Group>
            <Form.Group className="mb-3 form-section" controlId="exampleForm.ControlTextarea1">
                <Form.Label className="registration-form-section-label">Martial Status</Form.Label>
                <Form.Control className='registration-form-input' type="text" value={consumerExternalData.maritalStatus} onChange={(selectedValue) => {setConsumerExternalData({...consumerExternalData, maritalStatus: selectedValue.target.value})}}/>
            </Form.Group>
            <Form.Group className="mb-3 form-section" controlId="exampleForm.ControlTextarea1">
                <Form.Label className="registration-form-section-label">Homeowner</Form.Label>
                <Form.Control className='registration-form-input' type="text" value={consumerExternalData.homeownerType} onChange={(selectedValue) => {setConsumerExternalData({...consumerExternalData, homeownerType: selectedValue.target.value})}}/>
            </Form.Group>
            <Form.Group className="mb-3 form-section" controlId="exampleForm.ControlTextarea1">
                <Form.Label className="registration-form-section-label">Length of Residency</Form.Label>
                <Form.Control className='registration-form-input' type="text" value={consumerExternalData.lengthOfResidency} onChange={(selectedValue) => {setConsumerExternalData({...consumerExternalData, lengthOfResidency: selectedValue.target.value})}}/>
            </Form.Group>
            <Form.Group className="mb-3 form-section" controlId="exampleForm.ControlTextarea1">
                <Form.Label className="registration-form-section-label">Head of Household</Form.Label>
                <Form.Control className='registration-form-input' type="text" value={consumerExternalData.headOfHouseHold ? 'Yes' : 'No'} onChange={(selectedValue) => {setConsumerExternalData({...consumerExternalData, headOfHouseHold: selectedValue.target.value})}}/>
            </Form.Group>
            <Form.Group className="mb-3 form-section" controlId="exampleForm.ControlTextarea1">
                <Form.Label className="registration-form-section-label">Has Children</Form.Label>
                <Form.Control className='registration-form-input' type="text" value={consumerExternalData.hasChildren ? 'Yes' : 'No'} onChange={(selectedValue) => {setConsumerExternalData({...consumerExternalData, hasChildren: selectedValue.target.value})}}/>
            </Form.Group>
            <Form.Group className="mb-3 form-section" controlId="exampleForm.ControlTextarea1">
                <Form.Label className="registration-form-section-label">Education Level</Form.Label>
                <Form.Control className='registration-form-input' type="text" value={consumerExternalData.educationLevel} onChange={(selectedValue) => {setConsumerExternalData({...consumerExternalData, educationLevel: selectedValue.target.value})}}/>
            </Form.Group>
            <Form.Group className="mb-3 form-section" controlId="exampleForm.ControlTextarea1">
                <Form.Label className="registration-form-section-label">Estimated Income</Form.Label>
                <Form.Control className='registration-form-input' type="text" value={consumerExternalData.estimatedIncomeRange} onChange={(selectedValue) => {setConsumerExternalData({...consumerExternalData, estimatedIncomeRange: selectedValue.target.value})}}/>
            </Form.Group>
            <Form.Group className="mb-3 form-section" controlId="exampleForm.ControlTextarea1">
                <Form.Label className="registration-form-section-label">Estimated Net Worth</Form.Label>
                <Form.Control className='registration-form-input' type="text" value={consumerExternalData.estimatedWealthRange} onChange={(selectedValue) => {setConsumerExternalData({...consumerExternalData, estimatedWealthRange: selectedValue.target.value})}}/>
            </Form.Group>
            <div className='data-sync-save-btn-wrapper'>
              <Button style={externalDataRequest !== 'NOT RUN' ? {display: 'block'} : {display: 'none'}} variant="primary" type="submit" className='profile-registration-btn'>Save</Button>
            </div>
          </Form>
        </div>
          <div style={{paddingTop: '25px'}}>
            <Button variant="primary" type="button" className='social-continue-btn' onClick={navToPage}>Skip</Button>
          </div>   
      </div>
    </div>
  );
}