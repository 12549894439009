import '../App.css';
import { faQrcode, faXmarkCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useApi } from '../Contexts/ApiProvider';
import React, { useState } from "react";
import Button from 'react-bootstrap/Button';
import { useAuth0 } from "@auth0/auth0-react";
import Modal from 'react-modal';
import { useNavigate, useLocation } from 'react-router-dom';
import {toast} from 'react-toastify';
import QrReader from '../Components/QrReader';
import LoadingSpinner from "../Components/loadingSpinner";

export default function AddNewAdBrandModal() {
    const api = useApi();
    const { user, getAccessTokenSilently } = useAuth0();
    const [isLoading, setIsLoading] = useState(false);
    const [brandSearchResult, setBrandSearchResult] = useState({});
    let [isAdUrl, setIsAdUrl] = useState(false);
    let [brandSource, setBrandSource] = useState('');
    let [searchError, setSearchError] = useState(false);
    let [isAdLink, setIsAdLink] = useState(true);
    let [hasSearchResults, setHasSearchResults] = useState(false);
    let [qrOpen, setQrOpen] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const customStylesAddBrand = {
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          transform: 'translate(-50%, -50%)',
          background: '#ffffff',
          width: '630px',
          textAlign: 'left',
          height: '650px'
        },
      };
    const [searchBrandModalIsOpen, setSearchBrandModalIsOpen] = React.useState(false);

    const saveBrand = async () => {
        setIsLoading(true);
        const data = await api.post('interaction/manual', {
            brandId: brandSearchResult.id,
            searchUrl: document.getElementById('search-brand-term').value,
            isAdUrl: isAdLink,
            source: brandSource
          }, {
            headers: {
              Authorization:  'Bearer ' + await getAccessTokenSilently()
            }});
          if (!data.ok) {
            toast.error('Unable to save ad/brand');
            closeSearchBrandModal();
          }
          else {
            if(location.pathname === '/ads') {
                window.location.reload();
            }
            else{
                navigate('/ads');
            }
          }
        };

    const getBrandSearchResults = () => {
        (async () => {
            setIsLoading(true);
            var searchVal = document.getElementById('search-brand-term').value;
            var apiUrl = 'business/search'
            if (searchVal !== ''){
                apiUrl += '?searchTerm=' + searchVal;
            }
            const response = await api.get(apiUrl, null, {
                headers: {
                  Authorization:  'Bearer ' + await getAccessTokenSilently()
                }});
            if (response.ok && response.body !== null && response.body.id !== 0) {                
                setSearchError(false);
                setBrandSearchResult(response.body);
                setHasSearchResults(true);
                setIsLoading(false);
            }
            else {
                setSearchError(true);
                setBrandSearchResult(response.body);
                setIsLoading(false);
            }
        })();
    };

    function openAddBrand(){
        setSearchBrandModalIsOpen(true);
    }

    function afterOpenModal() {
        // references are now sync'd and can be accessed.
      }
    
    function closeSearchBrandModal() {
        setSearchBrandModalIsOpen(false);
        setBrandSearchResult({});
        setSearchError(false);
        setQrOpen(false);
    }

  return (
    <div>
        <Modal
            isOpen={searchBrandModalIsOpen}
            onAfterOpen={afterOpenModal}
            onRequestClose={closeSearchBrandModal}
            style={customStylesAddBrand}
            contentLabel="Example Modal"
            id='search-brand-modal'>
            <div style={isLoading ? {display: 'block'} : {display: 'none'}}>
                <LoadingSpinner/>
            </div>
            <div style={isLoading ? {display: 'none'} : {display: 'block'}}>
                <div className="actively-shopping-done-prompt-header">
                    <div className="actively-shopping-done-prompt-header-text">
                        <span>Add New Brand or Ad</span>
                        <span className="actively-shopping-done-prompt-x" onClick={() => {closeSearchBrandModal()}}><FontAwesomeIcon icon={faXmarkCircle}/></span>
                    </div>
                </div>
                <div className='add-new-business-modal-search'>
                    <input id='search-brand-term' className='business-modal-search-bar' type='search' placeholder='Paste a link...'/>
                    <span style={searchError ? {color:'red', display:'inline-block', position: 'relative', top: '65px'} : {display:'none'}}>Unable to find business</span>
                    <button className='bus-search-btn' onClick={()=>{getBrandSearchResults()}}>Search</button>
                    {/*<div>
                        <button className='qr-reader-btn' onClick={()=>{setQrOpen(!qrOpen)}}><FontAwesomeIcon icon={faQrcode}/></button>
                    </div>*/}
                </div>
                {/*<div style={qrOpen ? {display: 'block'} : {display: 'none'}}>                        
                    <div className='qr-reader-wrapper'>
                        <QrReader qrCodeFound={qrCodeValue => {
                            document.getElementById('search-brand-term').value = qrCodeValue;
                            getBrandSearchResults();
                            setQrOpen(false);
                        }}/>
                    </div>
                </div>*/}
                <div className='add-brand-ad-modal-type-selector'>
                    <div className='add-brand-ad-modal-type-selector-option-wrapper' onClick={() => (setIsAdLink(true))}>
                        <div className='add-brand-ad-modal-type-selector-option' style={isAdLink ? {backgroundColor: 'white'} : {backgroundColor: '#F5F5F5'}}>Advertisement</div>
                    </div>
                    <div className='add-brand-ad-modal-type-selector-option-wrapper' onClick={() => (setIsAdLink(false))}>
                        <div className='add-brand-ad-modal-type-selector-option' style={isAdLink ? {backgroundColor: '#F5F5F5'} : {backgroundColor: 'white'}}>Brand</div>
                    </div>
                </div>
                <div style={hasSearchResults ? {display: 'block'} : {display: 'none'}} className='add-new-business-modal-result'>
                    <div className='add-new-business-modal-result-img-wrapper'>
                        <img src={brandSearchResult.businessImageUrl} alt='Brand Icon' className='add-new-business-result-logo'/>
                    </div>
                    <div className='add-new-business-modal-result-details-wrapper'>
                        <div><span className='add-new-business-label'>{brandSearchResult.name}</span></div>
                        <div>{brandSearchResult.baseCategory}</div>
                        <div><span className='add-new-business-label'>Website: </span>{brandSearchResult.webUrl}</div>
                        <div><span className='add-new-business-label'>Source: <br/></span>
                            <select onChange={(selectedVal) => {setBrandSource(selectedVal.target.value)}}>
                                <option>Manual Search</option>
                                <option>Facebook</option>
                                <option>Instagram</option>
                                <option>Twitter (X)</option>
                                <option>TikTok</option>
                                <option>Other</option>
                            </select>
                        </div>
                    </div>
                    <div className='add-new-business-modal-btn-wrapper'>
                        <Button variant="primary" type="button" className='social-continue-btn' onClick={() => {saveBrand()}}>Save</Button>
                    </div>                        
                </div>
            </div>
        </Modal>
        <div className='add-business-btn-wrapper'>
            <button className='add-brand-btn' onClick={() => {openAddBrand()}}>+</button><span>Add New</span>
        </div>
    </div>
  );
}