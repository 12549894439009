import '../LandingPage.css';
import LandingNav from '../Components/LandingPage/LandingNav';
import LandingFooter from '../Components/LandingPage/LandingFooter';
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { useApi } from '../Contexts/ApiProvider';
import BehaviorEcoPt21 from '../BehaviorEcoPt21.png';
import BehaviorEcoPt22 from '../BehaviorEcoPt22.png';
import BehaviorEcoPt23 from '../BehaviorEcoPt23.png';
import { useNavigate } from 'react-router-dom';
import AdSense from '../Components/AdSense';

function LandingBlogBehaviorContinued() {
    const api = useApi();
    const [isLoading, setIsLoading] = useState(true);
    const navigate = useNavigate();

    return(
        <div className='landing-page-wrapper'>
            <LandingNav />
            <div style={{height: '50px'}}></div>
            <div className='landing-about-blog-content-wrapper'>
                {/*<AdSense dataAdSlot='2013861535' />*/}
                <div className='landing-about-blog-articles-wrapper'>
                    <div className='landing-about-blog-article'>
                        <div className='landing-about-blog-back-btn' onClick={() => (navigate('/about'))}>
                            <FontAwesomeIcon icon={faChevronLeft}/><span className='landing-about-blog-back-text'>Go Back</span>
                        </div>
                        <div className='landing-about-blog-article-title'>
                            <p>Behavioral Economics, The Secret Language of Business Continued</p>
                            <p className='landing-about-blog-article-author'>Written by The Parsal Team</p>
                        </div>
                        <div className='landing-about-blog-article-image'>
                            <img src={BehaviorEcoPt21} alt='Behavor Economics'/>
                        </div>
                        <div className='landing-about-blog-article-body'>
                            <p>In part one of this article, we covered some tactics that businesses use to leverage the data
                                 they capture or purchase about you to create effective, sometime insidious, behavioral loops
                                  that draw you into their web of discounts, offers, and more. While generally, these methods
                                   of marketing aren’t in and of themselves harmful, we believe that through the level of personal
                                    data that they have access too, whether you as a consumer like it or not, they have become
                                     much more of an infringement on our lives. At Parsal, we believe that the best way to create
                                      good data for marketing is to provide it, uniquely, directly, and proactively – with transparency
                                       from both sides. So, we share these examples to better arm you with knowledge, and as G.I.
                                        Joe says – knowing is half the battle.  If you see these methods in use, tread carefully:</p>

                            <div>
                                <div className='landing-about-blog-article-image'>
                                    <img src={BehaviorEcoPt22} alt='Behavor Economics'/>
                                </div>
                                <p>The “Better Mouse Trap”: The most effective marketing funnels use what are called
                                     triggers to watch for your activity, then they spring the trap. Usually what this
                                      looks like is, you visit a site and put a few things into the cart, then come to
                                       your senses and realize that even the most dedicated athlete doesn’t need three
                                        Bowflexes, let alone you who saw them while on the couch watching Netflix at 2am.
                                         You’ll soon receive an email or a text message from the helpful human-like brand
                                          reminding you that “Hey, you left something in the cart! To help you make your
                                           decision, we’ve even discounted it for you!”. Even more, many businesses will
                                            stack these trigger systems to create an entire staircase of interconnected
                                             actions, acting almost as an artificial intelligence itself. Often, these messages
                                              will be well crafted and personalized to you, and will only ask for a small,
                                               almost inconsequential next step – like signing up for a newsletter, or agreeing
                                                to reminders for sales, but then it will be followed by another small prompt,
                                                 then another. There is a common old-school adage in sales that if you can get
                                                  someone to say yes several times in a row, the momentum will make the sale
                                                   itself much easier, and these systems follow that same mentality.</p>
                                <div className='landing-about-blog-article-image'>
                                    <img src={BehaviorEcoPt23} alt='Behavor Economics'/>
                                </div>
                                <p>The Data Cabal: Did you know that the United States Postal Service keeps a database of everyone in the county’s
                                     address and keeps a running stream of updates whenever anyone moves, and that they make it fully available
                                      to businesses to purchase, append, and target advertising using that data? Well, they do. And they aren’t
                                       alone. Many of the services we rely on every day to live and go about our personal lives are just as reliant
                                        on your data to provide those services, and generate revenue, as the big social media brands. Credit companies,
                                         online recipe mavens, real estate shopping networks, and many many more provide a free-to-use service by 
                                         extracting their payment in other ways.</p>
                                <p>To be continued…</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <LandingFooter/>
        </div>
    )
}

export default LandingBlogBehaviorContinued;