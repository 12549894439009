import '../App.css';
import NavBar from '../Components/NavBar';
import Banner from '../Components/Banner';
import Advertisement from '../Components/Advertisement';
import NoAdvertisements from '../Components/NoAdvertisements';
import { useApi } from '../Contexts/ApiProvider';
import { React, useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import LoadingSpinner from '../Components/loadingSpinner';
import { useSearchParams } from 'react-router-dom';

function Advertisements() {
    const api = useApi();
    const { user, getAccessTokenSilently } = useAuth0();
    let [userAds, setUserAds] = useState([]);
    let [newPageUserAds, setNewPageUserAds] = useState([]);
    let [currentIndexVal, setCurrentIndexVal] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [searchParams] = useSearchParams();
    let [isAutoUnsubscribe, setIsAutoUnsubscribe] = useState(false);
    let [isAutoActivelyShopping, setIsAutoActivelyShopping] = useState(false);
    let [pageSize, setPageSize] = useState(10);
    let [pageNumber, setPageNumber] = useState(1);

    useEffect(() => {
        (async () => {
            setIsLoading(true);
            var apiUri = '';
            var userPreferencesUri = 'consumer/preferences/';
            if(searchParams.get('businessID') != null){
                apiUri = 'advertisement/business/' + searchParams.get('businessID') + '/consumer';
            }
            else{
                apiUri = 'advertisement/consumer';
            }
            
            const response = await api.get(apiUri, null, {
                headers: {
                  Authorization:  'Bearer ' + await getAccessTokenSilently()
                }});
            const prefResponse = await api.get(userPreferencesUri, null, {
                headers: {
                    Authorization:  'Bearer ' + await getAccessTokenSilently()
                }});
            if (response.ok && prefResponse.ok) {
                setUserAds(response.body);
                setIsAutoUnsubscribe(prefResponse.body.autoUnsubscribeEmail);
                setIsAutoActivelyShopping(prefResponse.body.autoActivelyShopping);
                setIsLoading(false);
            }
            else {
                setUserAds([]);
                setIsLoading(false);
            }
        })();
        }, []);

        const loadAds = (adId) => {
            (async () => {
                setIsLoading(true);
                var nextPageNum = pageNumber + 1;
                setPageNumber(nextPageNum);
                var apiUri = '';
                if(searchParams.get('businessID') != null){
                    apiUri = 'advertisement/business/' + searchParams.get('businessID') + '/consumer';
                }
                else{
                    apiUri = 'advertisement/consumer?pageNumber=' + nextPageNum;
                }
                
                const response = await api.get(apiUri, null, {
                    headers: {
                      Authorization:  'Bearer ' + await getAccessTokenSilently()
                    }});
                if (response.ok && response.body.length > 0) {
                    var concatAds = userAds.concat(response.body);
                    setUserAds(concatAds);
                    var indexToUpdate = currentIndexVal + 1;
                    setCurrentIndexVal(indexToUpdate);
                    setIsLoading(false);
                }
                else {
                    setIsLoading(false);
                    setCurrentIndexVal(0);
                }
            })();
        };

    return(
        <div className='ads-page'>
            <header className="App-header">
                <NavBar currentPage={'ads'}/>
            </header>
            <Banner isHomePage={false}/>
            <div className='ad-page-sub-banner'>More ad categories... { userAds == null || userAds.length <= 0 ? '' : isLoading ? '' : userAds[currentIndexVal].businessCategoryName}</div>
            <div className='ad-wrapper-container'>
                <div className='ad-wrapper'>
                    {userAds == null || userAds.length <= 0? isLoading ? <LoadingSpinner/> : <NoAdvertisements pageName='ads'/> :
                    isLoading ? <LoadingSpinner/> : 
                    userAds.map((adToReview, index) => (<Advertisement key={adToReview.id} userAd={adToReview} adIndex={index} currentIndex={currentIndexVal} autoShop={isAutoActivelyShopping} autoUnsubscribe={isAutoUnsubscribe} hasNext={userAds.length > currentIndexVal + 1 ? true : false} hasPrev={currentIndexVal == 0 ? false : true}
                        onReviewSubmission={async adId => {
                            setUserAds(userAds.filter(userAd => userAd.id !== adId));                            
                            if(currentIndexVal + 1 >= userAds.length) 
                            {
                                await loadAds(adId);                                
                            };
                        }}
                        navToNextAd={navToAd => {setCurrentIndexVal(navToAd)}}
                        navToPrevAd={navToAd => {setCurrentIndexVal(navToAd)}}
                    />))}
                    {/*<AdSense dataAdSlot='2013861535' />*/}
                    {/*<AdsterraAd />*/}
                </div>
            </div>
            <div className='ad-page-sub-banner-bottom'>More ads by... <a style={{color: 'white'}} href={ userAds == null || userAds.length <= 0? '' : isLoading ? '' :  'http://devconsumer.parsal.app/ads?businessID=' + userAds[currentIndexVal].business.id}>
                                                                {userAds == null || userAds.length <= 0 ? '' : isLoading ? '' :  userAds[currentIndexVal].business.name}</a></div>
        </div>
    )
}

export default Advertisements;