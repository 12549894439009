import React from "react";
import '../App.css';

export default function ReviewedBrand(brand) {
  return (
    <div style={{display: 'inline-block', width: '33%'}}>
        <div><img src={brand.brand.businessLogoUrl} style={{borderRadius: '75px', width: '34%'}}/></div>
        <div>{brand.brand.businessName}</div>
    </div>
  );
}