import React from "react";
import '../App.css';
import { useAuth0 } from "@auth0/auth0-react";
import { faUserCircle, faXmarkCircle, faQrcode, faGear, faCreditCard } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from 'react-router-dom';

export default function ProfilePopup() {
    const { logout } = useAuth0();
    const navigate = useNavigate();
    const { user } = useAuth0();

    const navToProfilePage = () => {
        navigate('/profile');
    }
  return (
    <div className="profile-popup-container">
      <div className="profile-popup-container-top">
        <div>
            <div className="profile-popup-container-close-icon">
                <FontAwesomeIcon icon={faXmarkCircle}/>
            </div>
            <div className="profile-popup-container-profile-icon">
                <FontAwesomeIcon icon={faUserCircle}/>
            </div>
            <div>
                <span style={{color: 'gray', fontWeight: 'bold'}}>{user.name}</span>
            </div>
            <div style={{paddingTop: '25px'}}>
                <div className="profile-popup-link-icon-container" onClick={navToProfilePage}>
                    <FontAwesomeIcon icon={faGear}/>
                    <span className="profile-popup-icon-label">Profile</span>
                </div>
            </div>
            <div>
                <span style={{color: 'gray', fontSize: '16px', cursor: 'pointer'}} onClick={() => logout({ logoutParams: { returnTo: window.location.origin + '/landing' } })}>Sign Out</span>
            </div>
        </div>
      </div>
      <div className="profile-popup-container-bottom">
        <div className="profile-popup-container-dollar">
            <span style={{fontWeight: 'bold'}}></span>
        </div>
        <div className="profile-popup-container-credit-content-wrapper">
            <div className="profile-popup-container-credit-icon-wrapper">                
            </div>
            <div className="profile-popup-container-credit-label-wrapper">
                <span className="profile-popup-credit-card-label"></span>
            </div>
        </div>
      </div>
    </div>
  );
}