import React, { useEffect, useState } from "react";
import { useApi } from '../Contexts/ApiProvider';
import '../App.css';
import { useAuth0 } from "@auth0/auth0-react";
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import {toast} from 'react-toastify';
import LoadingSpinner from "./loadingSpinner";

export default function ProfileBadges() {
    let [userProfile, setUserProfile] = useState({});
    const { user, getAccessTokenSilently } = useAuth0();
    const api = useApi();
    let [newInterests, setNewInterests] = useState([]);
    let [newInterest, setNewInterest] = useState('');
    let [profileInterests, setProfileInterests] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const onSubmit = async (event) => {
        setIsLoading(true);
        event.preventDefault();
        newInterests.push(newInterest);
        const data = await api.post('consumer/interests', {
            interests: newInterests
          }, {
            headers: {
              Authorization:  'Bearer ' + await getAccessTokenSilently()
            }});
          if (!data.ok) {
            setIsLoading(false);
            toast.error('Unable to update interests');
          }
          else {            
            toast('Interests successfully updated');
            profileInterests.push(newInterest);
            setIsLoading(false);
            setNewInterests([]);
            setNewInterest('');
          }
        };

      const deleteInterest = async (interest) => {
        setIsLoading(true);
        const data = await api.post('consumer/interests/delete/' + interest, null, {
            headers: {
              Authorization:  'Bearer ' + await getAccessTokenSilently()
            }});
          if (!data.ok) {
            setIsLoading(false);
            toast.error('Unable to delete interests');            
          }
          else {
            setIsLoading(false);
            toast('Interests successfully deleted');
            setProfileInterests(profileInterests.filter(i => i !== interest));
          }
        };

    useEffect(() => {
        (async () => {
            var apiUri = 'consumer/interests';
            
            const response = await api.get(apiUri, null, {
                headers: {
                  Authorization:  'Bearer ' + await getAccessTokenSilently()
                }});
            if (response.ok) {
                setProfileInterests(response.body);
                setIsLoading(false);
            }
            else {
                setUserProfile([]);
                setIsLoading(false);
            }
        })();
        }, []);

  return (
    <div>
      <div>
        <Form onSubmit={onSubmit}>
              <Form.Group className="mb-3 form-section" controlId="exampleForm.ControlInput1">
                  <Form.Label className="form-section-label">Interest</Form.Label>
                  <Form.Control type="text" placeholder="ex: 'Kayaking'" value={newInterest} onChange={(selectedValue) => {setNewInterest(selectedValue.target.value)}}/>
              </Form.Group>
              <Button variant="primary" type="submit" className='interested-modal-interested-btn'>
                  <span className='feedback-modal-btn-icon'></span>
                  <span>Save</span>
              </Button>   
        </Form>
        <div style={isLoading ? {display: 'block'} : {display: 'none'}}>
          <LoadingSpinner/>
        </div>
        <div style={isLoading ? {display: 'none'} : {display: 'block', paddingTop: '25px'}}>
          {profileInterests.map((interest) => (
            <div className="profile-interest">
              <span className="profile-interest-text">{interest}<span className="profile-interest-delete-icon" onClick={() => {deleteInterest(interest)}}>x</span></span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}