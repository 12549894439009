import React, { useEffect, useState } from "react";
import '../App.css';
import { useApi } from '../Contexts/ApiProvider';
import { useAuth0 } from "@auth0/auth0-react";
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';
import {toast} from 'react-toastify';
import moment from 'moment';

export default function ProfileActivity() {
    const api = useApi();
    const { user, getAccessTokenSilently } = useAuth0();
    const [isLoading, setIsLoading] = useState(false);
    let [profileActivities, setProfileActivities] = useState([]);   

    useEffect(() => {
        (async () => {
        setIsLoading(true);
        var apiUri = 'consumer/activities';
        
        const response = await api.get(apiUri, null, {
            headers: {
              Authorization:  'Bearer ' + await getAccessTokenSilently()
            }});
        if (response.ok) {
            setProfileActivities(response.body);
            setIsLoading(false);
        }
        else {
            setIsLoading(false);
        }
        })();
    }, []);

  return (
    <div className="actively-shopping-wrapper">
        <div>
            <div><h2>Activity</h2></div>
            <div>
                <ul className="activity-stream">
                    {profileActivities.map((activity) => (
                        <li>
                            <span className="timestamp">{moment(activity.createDate).format('LLL')}</span>
                            <p><b>{activity.subject}</b></p>
                            <p>{activity.body}</p>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    </div>
  );
}