import '../LandingPage.css';
import LandingNav from '../Components/LandingPage/LandingNav';
import LandingFooter from '../Components/LandingPage/LandingFooter';
import { faChevronCircleDown, faChevronCircleUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { useApi } from '../Contexts/ApiProvider';
import LoadingSpinner from '../Components/loadingSpinner';
import LandingLeaderSection from '../Components/LandingPage/LandingLeaderSection';
import AdSense from '../Components/AdSense';

function LandingHowItWorks() {
    const api = useApi();
    const [isLoading, setIsLoading] = useState(true);

    return(
        <div className='landing-page-wrapper'>
            <LandingNav />
            <div className='landing-how-it-works-content-wrapper'>
                {/*<AdSense dataAdSlot='2013861535' />*/}
                <div className='landing-how-it-works-content-header'>
                    <span>How it Works</span>
                </div>
                <div className='landing-how-it-works-items-wrapper'>
                    <div className='landing-how-it-works-item'>
                        <div className='landing-how-it-works-item-video-thumb'>
                            <div style={{height: '100%', position: 'relative'}}><iframe src="https://player.vimeo.com/video/932346365?h=d8ec0252d7&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture; clipboard-write" style={{width:'100%',height:'100%'}} title="Create Account &amp; Upload First Ads"></iframe></div><script src="https://player.vimeo.com/api/player.js"></script>
                        </div>
                        <div className='landing-how-it-works-item-text'>
                            <p className='landing-how-it-works-item-text-header'>Create Account & Upload First Ads</p>
                            <p className='landing-how-it-works-item-text-content'>Once you're ready to get Parsal-ing, you need to create an account. This step by step guide will help you build the most valuable profile possible, and see what other personal data of yours companies are selling now. Then, it's time to upload businesses, ads, or purchases to start creating your unique, valuable data. Here, we show you how to grab the easy stuff (emails and more!) or the more hidden ad data that ad networks or social media brands don't always want you to see.</p>
                        </div>
                    </div>
                    <div className='landing-how-it-works-item'>
                        <div className='landing-how-it-works-item-video-thumb'>
                        <div style={{height: '100%', position: 'relative'}}><iframe src="https://player.vimeo.com/video/928695342?h=3a41320b3f&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture; clipboard-write" style={{width:'100%',height:'100%'}} title="Review Ads &amp; Brands"></iframe></div><script src="https://player.vimeo.com/api/player.js"></script>
                        </div>
                        <div className='landing-how-it-works-item-text'>
                            <p className='landing-how-it-works-item-text-header'>Review Ads and Brands</p>
                            <p className='landing-how-it-works-item-text-content'>The best way to build a valuable profile that businesses will pay for is to let them know exactly what you're interested in, what you're NOT interested in, and any feedback you want to provide to brands.</p>
                        </div>
                    </div>
                    <div className='landing-how-it-works-item'>
                        <div className='landing-how-it-works-item-video-thumb'>
                        <div style={{height: '100%', position: 'relative'}}><iframe src="https://player.vimeo.com/video/928693279?h=c6e7f6512a&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture; clipboard-write" style={{width:'100%',height:'100%'}} title="Shop, Save, and Share"></iframe></div><script src="https://player.vimeo.com/api/player.js"></script>
                        </div>
                        <div className='landing-how-it-works-item-text'>
                            <p className='landing-how-it-works-item-text-header'>Shop, Save, and Share</p>
                            <p className='landing-how-it-works-item-text-content'>Indicate to businesses when you are actively shopping certain products or product categories. Save ads that you want to review again later and share with friends or family to help them build an even more valuable profile.</p>
                        </div>
                    </div>
                    <div className='landing-how-it-works-item'>
                        <div className='landing-how-it-works-item-video-thumb'>
                        <div style={{height: '100%', position: 'relative'}}><iframe src="https://player.vimeo.com/video/928692385?h=9e3bbe2593&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture; clipboard-write" style={{width:'100%',height:'100%'}} title="Create a Network"></iframe></div><script src="https://player.vimeo.com/api/player.js"></script>
                        </div>
                        <div className='landing-how-it-works-item-text'>
                            <p className='landing-how-it-works-item-text-header'>Create a Network</p>
                            <p className='landing-how-it-works-item-text-content'>Invite others to join Parsal to start building their profile and associate them to you for future communications, engagement, and ad sharing.</p>
                        </div>
                    </div>
                    <div className='landing-how-it-works-item'>
                        <div className='landing-how-it-works-item-video-thumb'>
                        <div style={{height: '100%', position: 'relative'}}><iframe src="https://player.vimeo.com/video/928691049?h=5a19394bfd&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture; clipboard-write" style={{width:'100%',height:'100%'}} title="Review Your Purchases"></iframe></div><script src="https://player.vimeo.com/api/player.js"></script>
                        </div>
                        <div className='landing-how-it-works-item-text'>
                            <p className='landing-how-it-works-item-text-header'>Review Your Purchases</p>
                            <p className='landing-how-it-works-item-text-content'>Let businesses know when, why, and how you purchased a product from them or a competitor, and how your experience was. Your opinion is valuable!</p>
                        </div>
                    </div>
                    <div className='landing-how-it-works-item'>
                        <div className='landing-how-it-works-item-video-thumb'>
                        <div style={{height: '100%', position: 'relative'}}><iframe src="https://player.vimeo.com/video/928689652?h=e0fb01d3df&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture; clipboard-write" style={{width:'100%',height:'100%'}} title="Get Targeted, Get Paid"></iframe></div><script src="https://player.vimeo.com/api/player.js"></script>
                        </div>
                        <div className='landing-how-it-works-item-text'>
                            <p className='landing-how-it-works-item-text-header'>Get Targeted, Get Paid</p>
                            <p className='landing-how-it-works-item-text-content'>As your profile gains interest and requests from businesses for targeting, you earn money! Remove the middle man and create a better data future together.</p>
                        </div>
                    </div>
                </div>
            </div>
            <LandingFooter/>
        </div>
    )
}

export default LandingHowItWorks;