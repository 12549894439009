import '../App.css';
import NavBar from '../Components/NavBar';
import Banner from '../Components/Banner';
import Button from 'react-bootstrap/Button';
import { useApi } from '../Contexts/ApiProvider'
import { useNavigate } from 'react-router-dom';
import { React, useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import HomeContentCarousel from '../Components/HomeContentCarousel';
import {ToastContainer, toast} from 'react-toastify';
import LoadingSpinner from '../Components/loadingSpinner';

function HomeContent() {
    const navigate = useNavigate();
    const api = useApi();
    const { user, getAccessTokenSilently } = useAuth0();
    const [userFeedback, setUserFeedback] = useState([]);
    const [showInstallPrompt, setShowInstallPrompt] = useState(false);
    const [userHomeSummary, setUserHomeSummary] = useState({
        consumerId: 0,
        businessTargetCount: 0,
        availableFeedbackCount: 0,
        availablePurchaseCount: 0,
        feedbackProvidedCount: 0,
        positiveFeedbackPercentage: 0,
        topPositiveBrands: [],
        topCategories: [],
        topTargetingBrands: []
    });

    let installPrompt = null;

    window.addEventListener("beforeinstallprompt", (event) => {
        event.preventDefault();
        installPrompt = event;
        setShowInstallPrompt(true);
      });

    window.addEventListener("appinstalled", () => {
        setShowInstallPrompt(false);
    });

    useEffect(() => {
        (async () => {
            const response = await api.get('consumer/email', null, {
                headers: {
                  Authorization:  'Bearer ' + await getAccessTokenSilently()
                }});
            if (response.ok) {
                if(response.body == null || response.body === 0)
                {
                    navigate('/register');
                }
                else {
                    const summaryResponse = await api.get('consumer/summary', null, {
                        headers: {
                          Authorization:  'Bearer ' + await getAccessTokenSilently()
                        }});
                    if (summaryResponse.ok) {
                        setUserHomeSummary({
                            consumerId: summaryResponse.body.consumerID,
                            businessTargetCount: summaryResponse.body.businessTargetCount,
                            availableFeedbackCount: summaryResponse.body.availableFeedbackCount,
                            availablePurchaseCount: summaryResponse.body.availablePurchaseCount,
                            feedbackProvidedCount: summaryResponse.body.feedbackProvidedCount,
                            positiveFeedbackPercentage: summaryResponse.body.positiveFeedbackPercentage,
                            topPositiveBrands: summaryResponse.body.topPositiveBrands,
                            topCategories: summaryResponse.body.topCategories,
                            topTargetingBrands: summaryResponse.body.topTargetingBrands
                        });
                    }
                    else {
                        setUserHomeSummary(null);
                    }
                }
            
                var emailResponse = await api.get('interaction/Gmailads'+ '?emailLimit=10', null, {
                    headers: {
                      Authorization:  'Bearer ' + await getAccessTokenSilently()
                    }});
                if(emailResponse.ok && emailResponse.body.responseMessage !== 'Failed'){
                    toast('Gmail details imported (' + emailResponse.body.successfulUploadCount + ')');
                    const summaryResponse = await api.get('consumer/summary', null, {
                        headers: {
                          Authorization:  'Bearer ' + await getAccessTokenSilently()
                        }});
                    if (summaryResponse.ok) {
                        setUserHomeSummary({
                            consumerId: summaryResponse.body.consumerID,
                            businessTargetCount: summaryResponse.body.businessTargetCount,
                            availableFeedbackCount: summaryResponse.body.availableFeedbackCount,
                            availablePurchaseCount: summaryResponse.body.availablePurchaseCount,
                            feedbackProvidedCount: summaryResponse.body.feedbackProvidedCount,
                            positiveFeedbackPercentage: summaryResponse.body.positiveFeedbackPercentage,
                            topPositiveBrands: summaryResponse.body.topPositiveBrands,
                            topCategories: summaryResponse.body.topCategories,
                            topTargetingBrands: summaryResponse.body.topTargetingBrands
                        });
                    }
                }
            }
        })();
        }, []);

    const navToPage = () => {
        navigate('/ads');
    }

    const install = () => {
        (async () => {
            if (!installPrompt) {
                return;
              }
              const result = await installPrompt.prompt();
        })();
    };

    return(
        <div className='home-page'>
            <header className="App-header">
                <NavBar currentPage={'home'}/>
            </header>
            <div className='banner-container'>
                <div className='banner-welcome-text'>Welcome Back, {user.name}</div>
                <div className='home-summary-bubble home-summary-bubble-first' onClick={() => {navigate('/ads')}}>
                    <div className='home-summary-bubble-content gold'>{userHomeSummary == null ? '' : userHomeSummary.availableFeedbackCount}</div>
                    <div>new ads</div>
                </div>
                <div className='home-summary-bubble home-summary-bubble-second' onClick={() => {navigate('/brands')}}>
                    <div className='home-summary-bubble-content'>{userHomeSummary == null ? '' : userHomeSummary.businessTargetCount}</div>
                    <div>new brands</div>
                </div>
                <div className='home-summary-bubble home-summary-bubble-third' onClick={() => {navigate('/purchases')}}>
                    <div className='home-summary-bubble-content'>{userHomeSummary == null ? '' : userHomeSummary.availablePurchaseCount}</div>
                    <div>new purchases</div>
                </div>
            </div>
            <div className='home-content-wrapper'>
                <HomeContentCarousel/>
            </div>
            <div className='home-page-summary-wrapper'>
                <div className='home-page-summary-section' style={{display: 'none'}}>
                    <span className='home-page-summary-section-label'>Install Parsal App:</span>
                    <Button variant="primary" type="button" className='social-continue-btn' onClick={() => {install()}}>Install</Button>
                </div>
                <div className='home-page-summary-section'>
                    <span className='home-page-summary-section-label'>Total Responses:</span>
                     <span className='total-responses-count'>{userHomeSummary.feedbackProvidedCount}</span>
                </div>
                <div className='home-page-summary-section home-page-summary-percentage'>
                    <span className='home-page-summary-section-label'>Positive Feedback Percentage:</span>
                    <span className='positive-feedback-percentage'>{userHomeSummary.positiveFeedbackPercentage}%</span>
                </div>
                <div className='home-page-summary-section'>
                    <span className='home-page-summary-section-label'>Categories Targeting You Most:</span>
                    {userHomeSummary.topCategories.map((category) => (<div>{category}</div>))}
                </div>
                <div className='home-page-summary-section'>
                    <span className='home-page-summary-section-label'>Brands Targeting You Most:</span>
                    {userHomeSummary.topTargetingBrands.map((topTargetBrand) => (<div>{topTargetBrand}</div>))}
                </div>
                <div className='home-page-summary-section'>
                    <span className='home-page-summary-section-label'>Your Top Positive Brands:</span>
                    {userHomeSummary.topPositiveBrands.map((positiveBrand) => (<div>{positiveBrand}</div>))}
                </div>
            </div>
        </div>
    )
}

export default HomeContent;