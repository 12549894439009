import '../App.css';
import NavBar from '../Components/NavBar';
import Banner from '../Components/Banner';
import { useApi } from '../Contexts/ApiProvider';
import React, { useEffect, useState } from "react";
import { faCommentSlash, faCommentDots, faCommentDollar, faPencil, faClose, faSave } from "@fortawesome/free-solid-svg-icons";
import { useAuth0 } from "@auth0/auth0-react";
import LoadingSpinner from '../Components/loadingSpinner';
import moment from 'moment';
import { useSearchParams } from 'react-router-dom';
import AdFeedbackList from '../Components/AdFeedbackList';
import BrandFeedbackList from '../Components/BrandFeedbackList';
import PurchaseFeedbackList from '../Components/PurchaseFeedbackList';

function Feedbacks() {
    let [showAdFeedback, setShowAdFeedback] = useState(true);
    let [showBrandFeedback, setShowBrandFeedback] = useState(false);
    let [showPurchaseFeedback, setShowPurchaseFeedback] = useState(false);

    function toggleShowFeedbackLists(feedbackType)
    {
        if(feedbackType === 'ads'){
            setShowAdFeedback(true);
            setShowBrandFeedback(false);
            setShowPurchaseFeedback(false);
        }
        if(feedbackType === 'brands'){
            setShowAdFeedback(false);
            setShowBrandFeedback(true);
            setShowPurchaseFeedback(false);
        }
        if(feedbackType === 'purchases'){
            setShowAdFeedback(false);
            setShowBrandFeedback(false);
            setShowPurchaseFeedback(true);
        }
    }

    return(
        <div className='feedbacks-page'>
            <header className="App-header">
                <NavBar currentPage={'feedback'}/>
            </header>
            <Banner isHomePage={false}/>
            <div className='feedback-review-table-header'>
                <div className='feedback-nav-option-wrapper'>
                    <div className={showAdFeedback ?'feedback-nav-option-active' : 'feedback-nav-option'} onClick={() => {toggleShowFeedbackLists('ads')}}>Ads</div>
                    <div className={showBrandFeedback ?'feedback-nav-option-active' : 'feedback-nav-option'} onClick={() => {toggleShowFeedbackLists('brands')}}>Brands</div>
                    <div className={showPurchaseFeedback ?'feedback-nav-option-active' : 'feedback-nav-option'} onClick={() => {toggleShowFeedbackLists('purchases')}}>Purchases</div>
                </div>
            </div>
            <div style={showAdFeedback ? {} : {display:'none'}}>
                <AdFeedbackList/>
            </div>
            <div style={showBrandFeedback ? {} : {display:'none'}}>
                <BrandFeedbackList/>
            </div>
            <div style={showPurchaseFeedback ? {} : {display:'none'}}>
                <PurchaseFeedbackList/>
            </div>
            </div>
    )
}

export default Feedbacks;