import React from "react";
import '../App.css';

export default function ReviewedBrand(brand, index) {
  return (
    <div style={{display: 'inline-block', width: '33%'}} key={index}>
        <div><img src={brand.brand.businessImageUrl} style={{borderRadius: '75px', width: '34%'}}/></div>
        <div>{brand.brand.name}</div>
    </div>
  );
}