import React, { useEffect, useState } from "react";
import { faFacebook, faGoogle, faAmazon, faTiktok, faYoutube } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import '../App.css';
import { useNavigate } from 'react-router-dom';
import { useAuth0 } from "@auth0/auth0-react";
import { useApi } from '../Contexts/ApiProvider';
import {ToastContainer, toast} from 'react-toastify';
import { useFilePicker } from 'use-file-picker';
import LoadingSpinner from "./loadingSpinner";

export default function LinkedAccounts() {
  const navigate = useNavigate();
  const api = useApi();
  const [isLoading, setIsLoading] = useState(false);
  const { user, getAccessTokenSilently } = useAuth0();
  const [emailCount, setEmailCount] = useState(10);    
  const [userLastDates, setUserLastDates] = useState({
    googleUploadDate: "",
    facebookUploadDate: "",
    amazonUploadDate: ""
  });

  const [openFileSelector] = useFilePicker({
    onFilesSelected: ({ filesContent }) => {
        setIsLoading(true);
        {filesContent.map(
            async(file, index) => {
            if(file.name.includes('interacted')) {
                var historyObj = JSON.parse(file.content);
                const data = await api.post('interaction/facebookads' , {
                    history_v2: historyObj.history_v2
                  }, {
                    headers: {
                      Authorization:  'Bearer ' + await getAccessTokenSilently()
                    }});
                if (!data.ok || data.body.responseMessage === 'Failed') {
                    setIsLoading(false);
                    toast.error('Unable to import Facebook details');
                }
                else {
                    setIsLoading(false);
                    toast('Facebook details imported (' + data.body.successfulUploadCount + ')');
                }
            }
            else {
                var requestVal = file.content.replaceAll("\n", ",");
                requestVal = requestVal.replaceAll("\"", "");
                const data = await api.post('interaction/amazonbusinesses' , {
                    businessName: requestVal
                  }, {
                    headers: {
                      Authorization:  'Bearer ' + await getAccessTokenSilently()
                    }});
                if (!data.ok || data.body.responseMessage === 'Failed') {
                    setIsLoading(false);
                    toast('Unable to import Amazon details');
                }
                else {
                    setIsLoading(false);
                    toast('Amazon details imported (' + data.body.successfulUploadCount + ')');
                }
            }
        })};
      }
  });

  {/*useEffect(() => {
      (async () => {
          await getUserId();
          const response = await api.get('interaction/lastupload/consumer/' + user.email, null, {
            headers: {
              Authorization:  'Bearer ' + await getAccessTokenSilently()
            }});
          if (response.ok) {
            setUserLastDates(response.body);
          }
          else {
            setUserLastDates(null);
          }
      })();
      }, []);*/}

  const getFacebook = async () => {
      await openFileSelector();
  }

  const navToPage = () => {
      navigate('/');
  }

  const getGmail = async () => {
      (async () => {
          setIsLoading(true);
          const response = await api.get('Interaction/Gmailads?emailLimit=' + emailCount, null, {
              headers: {
                Authorization:  'Bearer ' + await getAccessTokenSilently()
              }});
              if (response.ok && response.body.responseMessage !== 'Failed') {
                  setIsLoading(false);
                  toast('Gmail details imported (' + response.body.successfulUploadCount + ')');
              }
              else {
                  setIsLoading(false);
                  toast.error('Unable to import Gmail details');
              }
          })();
  };

  return (
    <div style={{marginTop: '20px'}}>
      <div style={isLoading ? {display: 'block'} : {display: 'none'}}>
        <LoadingSpinner/>
      </div>
      <div style={isLoading ? {display: 'none'} : {display: 'block'}}>
        <div style={{padding: '15px'}}>
          <div style={{display: 'inline-block', width: '20%'}}>
            <FontAwesomeIcon icon={faFacebook} style={{fontSize: '3em'}}/>
          </div>
          <div style={{display: 'inline-block', width: '80%'}}>
            <span>Facebook Ad Interactions</span>
            <button style={{marginLeft: '15px', height: '40px', width: '140px', fontSize:'18px', marginTop: '10px'}}  onClick={() => getFacebook()}>Upload</button>
          </div>
        </div>
        <div style={{padding: '15px'}}>
          <div style={{display: 'inline-block', width: '20%'}}>
            <FontAwesomeIcon icon={faAmazon} style={{fontSize: '3em'}}/>
          </div>
          <div style={{display: 'inline-block', width: '80%'}}>
            <span>Amazon Advertisers</span>
            <button style={{marginLeft: '15px', height: '40px', width: '150px', fontSize:'18px', marginTop: '10px'}} onClick={() => getFacebook()}>Upload</button>
          </div>
        </div>
        <div style={{padding: '15px'}}>
          <div style={{display: 'inline-block', width: '20%'}}>
            <FontAwesomeIcon icon={faGoogle} style={{fontSize: '3em'}}/>
          </div>
          <div style={{display: 'inline-block', width: '80%'}}>
            <span>Top </span>
            <input style={{width: '50px'}} type="number" value={emailCount} onChange={(newValue) => {setEmailCount(newValue.target.value)}} max={25} min={1}/>
            <span> Emails</span>
            <button style={{marginLeft: '15px', marginRight: '10px', height: '40px', width: '140px', fontSize:'18px', marginTop: '10px'}} onClick={getGmail}>Get Emails</button>
          </div>
        </div>
        {/*<div style={{fontSize: '3em'}}><FontAwesomeIcon icon={faTiktok} /></div>
        <div style={{fontSize: '3em'}}><FontAwesomeIcon icon={faYoutube} /></div>
        <div className="integration-social-link" onClick={() => {navigate('/social')}}>Upload</div>*/}
      </div>
    </div>
  );
}