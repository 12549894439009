import '../App.css';
import NavBar from '../Components/NavBar';
import Banner from '../Components/Banner';
import ProfileSection from '../Components/ProfileSection';
import ProfileBanking from '../Components/ProfileBanking';
import { useApi } from '../Contexts/ApiProvider';
import React, { useState, useEffect} from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import { faUserCircle, faCopy } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {toast} from 'react-toastify';
import ProfileActivelyShopping from '../Components/ProfileActivelyShopping';
import copy from "copy-to-clipboard";

function Profile() {
    const api = useApi();
    const { user, getAccessTokenSilently, logout } = useAuth0();
    const [userProfile, setUserProfile] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    let [isProfile, setIsProfile] = useState(true);
    let [isTransactions, setIsTransactions] = useState(false);
    let [isBanking, setIsBanking] = useState(false);
    let [profileRefLink, setProfileRefLink] = useState({
        referralLink: ''
    });
    let [profileLeaderboard, setProfileLeaderboard] = useState({
        leaderboardName: ''
    });

    function NavToSection(sectionName){
        switch(sectionName){
            case 'isProfile':
                setIsProfile(true);
                setIsTransactions(false);
                setIsBanking(false);            
            break;
            case 'isTransactions':
                setIsProfile(false);
                setIsTransactions(true);
                setIsBanking(false); 
            break;
            case 'isBanking':
                setIsProfile(false);
                setIsTransactions(false);
                setIsBanking(true); 
            break;
            default:
                setIsProfile(true);
                setIsTransactions(false);
                setIsBanking(false); 
        }
    }

    const copyToClipboard = () => {
        let isCopy = copy(profileRefLink.referralLink);
        if (isCopy) {
          toast.success("Copied to Clipboard");
        }
      };


    useEffect(() => {
        (async () => {
            setIsLoading(true);
            var apiUri = 'consumer/referralLink';
            const response = await api.get(apiUri, null, {
                headers: {
                  Authorization:  'Bearer ' + await getAccessTokenSilently()
                }});
            if (response.ok) {
                setProfileRefLink(response.body);
                setIsLoading(false);
            }
            else {
                setProfileRefLink('Coming Soon');
                setIsLoading(false);
            }

            var lbApiUrl = 'consumer/leaderboard';
            const lbResponse = await api.get(lbApiUrl, null, {
                headers: {
                  Authorization:  'Bearer ' + await getAccessTokenSilently()
                }});
            if (lbResponse.ok) {
                setProfileLeaderboard(lbResponse.body);
            }
            else {
                setProfileLeaderboard('Generating...');
            }
        })();
        }, []);

    return(
        <div className='profile-page-wrapper'>
            <header className="App-header">
                <NavBar currentPage={'profile'}/>
            </header>
            <Banner isHomePage={false}/>
            <div className='profile-content-wrapper'>
                <div className='profile-content-top'>
                    <div className='profile-content-top-img'><FontAwesomeIcon icon={faUserCircle}/></div>
                    <div className='profile-content-top-content'>
                        <div className='profile-cotent-top-content-name'>{user.name}</div>
                        <div className='profile-cotent-top-content-name'><i style={{fontSize: '24px', color: 'gray'}}>{profileLeaderboard.leaderboardName}</i></div>
                        <div className='profile-content-top-content-link'>Invite: <i>{profileRefLink.referralLink}</i><button className='copy-link-btn' onClick={() => {copyToClipboard()}}><FontAwesomeIcon icon={faCopy}/></button></div>
                    </div>
                </div>
                <div style={{height: '90%', paddingTop: '25px'}}>
                    <div className='profile-content-btn-row'>
                        <div className='profile-content-btn-row-btn-container'>
                            <button className={isProfile ? 'profile-content-btn-row-btn-container-btn-active' : 'profile-content-btn-row-btn-container-btn'} onClick={() => {NavToSection("isProfile")}}>Profile</button>
                        </div>
                        <div className='profile-content-btn-row-btn-container'>
                            <button className={isTransactions ? 'profile-content-btn-row-btn-container-btn-active' : 'profile-content-btn-row-btn-container-btn'} onClick={() => {NavToSection("isTransactions")}}>Actively Shopping</button>
                        </div>
                        <div className='profile-content-btn-row-btn-container'>
                            <button className={isBanking ? 'profile-content-btn-row-btn-container-btn-active' : 'profile-content-btn-row-btn-container-btn'} onClick={() => {NavToSection("isBanking")}}>Leaderboard</button>
                        </div>
                    </div>
                    <div className='profile-section' style={isProfile ? {display: 'block', padding: '15px'} : {display: 'none'}}>
                        <ProfileSection user={user}/>
                    </div>
                    <div className='profile-section' style={isTransactions ? {display: 'block', padding: '15px'} : {display: 'none'}}>
                        <ProfileActivelyShopping user={user}/>
                    </div>
                    <div className='profile-section' style={isBanking ? {display: 'block', padding: '15px'} : {display: 'none'}}>
                        <ProfileBanking user={user} userLeaderboardName={profileLeaderboard.leaderboardName}/>
                    </div>
                </div>
                <div className='logout-btn-wrapper'>
                    <button className='logout-btn' onClick={() => logout({ logoutParams: { returnTo: window.location.origin + '/' } })}>Sign Out</button>
                </div>
            </div>
        </div>
    )
}

export default Profile;