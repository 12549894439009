import { React, useEffect, useState } from "react";
import ReviewedBrand from "./ReviewedBrand";
import '../App.css';
import { useApi } from '../Contexts/ApiProvider';
import LoadingSpinner from '../Components/loadingSpinner';
import { useAuth0 } from "@auth0/auth0-react";

export default function ProfileLikes() {
    const [isLoading, setIsLoading] = useState(false);
    let [userLikes, setUserLikes] = useState([]);
    const { user, getAccessTokenSilently } = useAuth0();
    const api = useApi();

    useEffect(() => {
        (async () => {
            setIsLoading(true);
            var apiUri = 'consumer/preferredBrands';
            
            const response = await api.get(apiUri, null, {
                headers: {
                  Authorization:  'Bearer ' + await getAccessTokenSilently()
                }});
            if (response.ok) {
                setUserLikes(response.body.brands);
                setIsLoading(false);
            }
            else {
                setUserLikes([]);
                setIsLoading(false);
            }
        })();
        }, []);
  return (
    <div className="profile-likes-wrapper">
        <div>
            <h4>Favorites</h4>
            <div>{userLikes.filter(revBrands => revBrands.isSuperLike).map((userLike, index) => (<ReviewedBrand brand={userLike} key={index}/>))}</div>
        </div>
        <div>
            <h4>Likes</h4>
            <div>{userLikes.filter(revBrands => !revBrands.isSuperLike && revBrands.isLike).map((userLike, index) => (<ReviewedBrand brand={userLike} key={index}/>))}</div>
        </div>
        <div>
            <h4>Dislikes</h4>
            <div>{userLikes.filter(revBrands => !revBrands.isLike).map((userLike, index) => (<ReviewedBrand brand={userLike} key={index}/>))}</div>
        </div>
    </div>
  );
}