import '../App.css';
import { faCommentDollar, faSmile, faFrown, faCommentDots, faChevronCircleLeft, faChevronCircleRight, faCommentSlash, faHeart } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useApi } from '../Contexts/ApiProvider';
import React, { useState, useRef} from 'react';
import {useCollapse} from 'react-collapsed';
import { useAuth0 } from "@auth0/auth0-react";
import Form from 'react-bootstrap/Form';
import Modal from 'react-modal';
import Button from 'react-bootstrap/Button';
import moment from 'moment';
import InputField from '../Components/InputField';
import DefaultPurchase from '../DefaultPurchase.jpg';


function PurchaseReview({userPurchase, purchaseIndex, onReviewSubmission, currentIndex, navToNextBrand, navToPrevBrand, hasNext, hasPrev}) {
    const [formErrors, setFormErrors] = useState({});
    const wouldPurchaseAgainField = useRef(false);
    const paidTooMuchField = useRef(false);
    const poorQualityField = useRef(false);
    const notRightProductField = useRef(false);
    const badExperienceField = useRef(false);
    const goodPriceField = useRef(false);
    const highQualityField = useRef(false);
    const preferredBrandField = useRef(false);
    const goodExperienceField = useRef(false);
    const api = useApi();
    const { user, getAccessTokenSilently } = useAuth0();
    const { getCollapseProps, getToggleProps } = useCollapse();
    const [feedbackModalIsOpen, setFeedbackModalIsOpen] = React.useState(false);
    const [interestedModalIsOpen, setInterestedModalIsOpen] = React.useState(false);
    const [notInterestedModalIsOpen, setNotInterestedModalIsOpen] = React.useState(false);
    let [feedbackNotes, setFeedbackNotes] = useState('');
    const customStylesInterested = {
      content: {
        top: '55%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        transform: 'translate(50%, -50%)',
        background: '#ffbf46',
        width: '25%',
        opacity: '0.8',
        textAlign: 'left',
        height: '400px'
      },
    };
  const customStylesNotInterested = {
      content: {
        top: '55%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        transform: 'translate(-150%, -50%)',
        background: '#575761',
        width: '25%',
        opacity: '0.8',
        textAlign: 'left',
        height: '400px'
      },
    };
  const customStylesFeedback = {
      content: {
        top: '20%',
        left: '35%',
        right: 'auto',
        bottom: 'auto',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        width: '30%',
        textAlign: 'center',
        height: '65%'
      },
    };
  

    function openInterestedModal() {setInterestedModalIsOpen(true);}

    function openNotInterestedModal() {setNotInterestedModalIsOpen(true);}

    function afterOpenModal() {
      // references are now sync'd and can be accessed.
    }
  
    function closeInterestedModal() 
    {
      setInterestedModalIsOpen(false);
    }

    function closeNotInterestedModal() {setNotInterestedModalIsOpen(false);}
    
    async function SaveFeedback(feedbackValue) {
        
        const data = await api.post('purchase/feedback', {
            purchaseId: userPurchase.id.current.checked,
            wouldPurchaseAgain: wouldPurchaseAgainField.current.checked,
            paidTooMuch: paidTooMuchField.current.checked,
            poorQuality: poorQualityField.current.checked,
            notRightProduct: notRightProductField.current.checked,
            badExperience: badExperienceField.current.checked,
            goodPrice : goodPriceField.current.checked,
            highQuality : highQualityField.current.checked,
            preferredBrand : preferredBrandField.current.checked,
            goodExperience : goodExperienceField.current.checked,
            comments: feedbackNotes
          }, {
            headers: {
              Authorization:  'Bearer ' + await getAccessTokenSilently()
            }});
          if (!data.ok) {
            
          }
          else {            
            onReviewSubmission(userPurchase.id);
          }
        };

    function openFeedbackModal() {setFeedbackModalIsOpen(true);}
    function closeFeedbackModal() {setFeedbackModalIsOpen(false);}
    function afterOpenModal() {
        // references are now sync'd and can be accessed.
      }

    function getDate(dateVal)
    {
      var formattedDate = moment(dateVal).format('LL');
      return formattedDate;
    }

    const onSubmit = async () => {
      const data = await api.post('purchase/feedback', {
        purchaseId: userPurchase.id,
        wouldPurchaseAgain: wouldPurchaseAgainField.current.checked,
        paidTooMuch: paidTooMuchField.current.checked,
        poorQuality: poorQualityField.current.checked,
        notRightProduct: notRightProductField.current.checked,
        badExperience: badExperienceField.current.checked,
        goodPrice : goodPriceField.current.checked,
        highQuality : highQualityField.current.checked,
        preferredBrand : preferredBrandField.current.checked,
        goodExperience : goodExperienceField.current.checked,
        comments: feedbackNotes
        }, {
          headers: {
            Authorization:  'Bearer ' + await getAccessTokenSilently()
          }});
        if (!data.ok) {
          setFormErrors(data.body.errors.json);
        }
        else {
          setFormErrors({});       
          onReviewSubmission(userPurchase.id);
        }
      };

    return (
    <div>
      <div>
        <Modal
                    isOpen={interestedModalIsOpen}
                    onAfterOpen={afterOpenModal}
                    onRequestClose={closeInterestedModal}
                    style={customStylesInterested}
                    contentLabel="Example Modal"
                    id='is-interested-modal'>
                    <Form onSubmit={onSubmit}>
                        <InputField
                        name="goodPrice" label="Was a good price" type="checkbox"
                        error={formErrors.goodPrice} fieldRef={goodPriceField} />
                        <InputField
                        name="highQuality" label="Product was high quality" type="checkbox"
                        error={formErrors.highQuality} fieldRef={highQualityField} />
                        <InputField
                        name="preferredBrand" label="Was a preferred brand" type="checkbox"
                        error={formErrors.preferredBrand} fieldRef={preferredBrandField} />
                        <InputField
                        name="goodExperience" label="Positive shopping experience" type="checkbox"
                        error={formErrors.goodExperience} fieldRef={goodExperienceField}/>
                        <InputField
                        name="wouldPurchaseAgain" label="Would Purchase Again" type="checkbox"  shouldShow="false"
                        error={formErrors.wouldPurchaseAgain} fieldRef={wouldPurchaseAgainField} />
                        <InputField
                        name="paidTooMuch" label="Paid Too Much" type="checkbox" shouldShow="false"
                        error={formErrors.paidTooMuch} fieldRef={paidTooMuchField} />
                        <InputField
                        name="poorQuality" label="Poor Quality" type="checkbox" shouldShow="false"
                        error={formErrors.poorQuality} fieldRef={poorQualityField} />
                        <InputField
                        name="notRightProduct" label="Not Right Product" type="checkbox" shouldShow="false"
                        error={formErrors.notRightProduct} fieldRef={notRightProductField} />
                        <InputField
                        name="badExperience" label="Bad Experience" type="checkbox" shouldShow="false"
                        error={formErrors.badExperience} fieldRef={badExperienceField} />
                        <Button variant="primary" type="submit" className='interested-modal-interested-btn'>
                            <span className='feedback-modal-btn-icon'><FontAwesomeIcon icon={faCommentDollar}/></span>
                            <span>Submit</span>
                        </Button>                     
                    </Form>
                </Modal>
              </div>
              <div>
                <Modal
                    isOpen={notInterestedModalIsOpen}
                    onAfterOpen={afterOpenModal}
                    onRequestClose={closeNotInterestedModal}
                    style={customStylesNotInterested}
                    contentLabel="Example Modal"
                    id='not-interested-modal'>
                    <Form onSubmit={onSubmit}>
                    <InputField
                        name="goodExperience" label="Good Experience" type="checkbox" shouldShow="false"
                        error={formErrors.goodExperience} fieldRef={goodExperienceField}/>
                        <InputField
                        name="wouldPurchaseAgain" label="Would Purchase Again" type="checkbox" shouldShow="false"
                        error={formErrors.wouldPurchaseAgain} fieldRef={wouldPurchaseAgainField} />
                        <InputField
                        name="goodPrice" label="Good Price" type="checkbox" shouldShow="false"
                        error={formErrors.goodPrice} fieldRef={goodPriceField} />
                        <InputField
                        name="paidTooMuch" label="Paid too much" type="checkbox"
                        error={formErrors.paidTooMuch} fieldRef={paidTooMuchField} />
                        <InputField
                        name="highQuality" label="High Quality" type="checkbox" shouldShow="false"
                        error={formErrors.highQuality} fieldRef={highQualityField} />
                        <InputField
                        name="preferredBrand" label="Preferred Brand" type="checkbox" shouldShow="false"
                        error={formErrors.preferredBrand} fieldRef={preferredBrandField} />
                        <InputField
                        name="poorQuality" label="Product was poor quality" type="checkbox"
                        error={formErrors.poorQuality} fieldRef={poorQualityField} />
                        <InputField
                        name="notRightProduct" label="Was not the right product" type="checkbox"
                        error={formErrors.notRightProduct} fieldRef={notRightProductField} />
                        <InputField
                        name="badExperience" label="Bad shopping experience" type="checkbox"
                        error={formErrors.badExperience} fieldRef={badExperienceField}/>
                        <Button variant="primary" type="submit" className='not-interested-modal-not-interested-btn'>
                            <span className='feedback-modal-btn-icon'><FontAwesomeIcon icon={faCommentSlash}/></span>
                            <span>Submit</span>
                        </Button>
                    </Form>
                </Modal>
      </div>
      <div>
        <Modal
            isOpen={feedbackModalIsOpen}
            onAfterOpen={afterOpenModal}
            onRequestClose={closeFeedbackModal}
            style={customStylesFeedback}
            contentLabel="Example Modal"
            id='provide-feedback-modal'>
            <Form>
                <Form.Group className="mb-3 form-section" controlId="exampleForm.ControlInput1">
                  <Form.Label className="form-section-label"></Form.Label>
                  <textarea className='review-feedback-txt-area' placeholder='Feedback' value={feedbackNotes} onChange={(feedbackNotesValue) => {setFeedbackNotes(feedbackNotesValue.target.value)}}/>
                </Form.Group>
                <Button variant="primary" type="submit" className='send-feedback-modal-btn' onClick={() => {closeFeedbackModal()}}>
                    <span>Save Feedback</span>
                </Button>               
            </Form>
        </Modal>
    </div> 
    <div className="ad-main-container"  style={purchaseIndex === currentIndex ? {display: 'block'} : {display: 'none'}}>
        <div className='brand-feedback-super-like-btn-wrapper'>
        </div>
        <div className='brand-feedback-content-wrapper'>
            <div className='purchase-feedback-content-logo-wrapper'>
              <img src={DefaultPurchase} alt='purchase Image'/>
            </div>            
        </div>
        <div className="ad-container-inner" id="carouselExampleIndicators">
            <div id="ad-carousel-main">
                <div className="ad-tile-overlay collapsible">
                    <div className="ad-tile-overlay-line header" {...getToggleProps()}></div>
                    <div {...getCollapseProps()}>
                        <div className="content">
                          <div className='overlay-expanded-text-business-name'>
                            <span>{userPurchase.business.name}</span>
                            <br/>
                            <span>{userPurchase.productDescription}</span>
                          </div>
                          <div>
                              <span>Purchased from {userPurchase.purchaseLocation} on {getDate(userPurchase.purchaseDate)}</span>
                          </div>
                          <div>
                              <span style={{color:'#889b73', fontWeight: 'bold'}}>${userPurchase.price}</span>
                          </div>
                        </div>
                      </div> 
                    <div className="overlay-feedback-btn-row">
                        <button className='overlay-feedback-btn-arrow' style={hasPrev ? {} : {display: 'none'}} onClick={()=>{navToPrevBrand(currentIndex - 1)}}>
                            <FontAwesomeIcon icon={faChevronCircleLeft} className='overlay-feedback-btn-arrow-icon'/>
                        </button>
                        <button onClick={openNotInterestedModal} className="overlay-feedback-btn">
                            <span style={{ fontSize: '30px'}}><FontAwesomeIcon icon={faFrown}/></span>
                            <span style={{ display: 'block' }}>Negative</span>
                        </button>
                        <button onClick={openFeedbackModal} className="overlay-feedback-middle">
                            <span style={{ fontSize: '30px'}}><FontAwesomeIcon icon={faCommentDots}/></span>
                        </button>
                        <button onClick={openInterestedModal} className="overlay-feedback-btn-interested">
                            <span style={{ fontSize: '30px'}}><FontAwesomeIcon icon={faSmile}/></span>
                            <span style={{ display: 'block' }}>Positive</span>
                        </button>
                        <button className='overlay-feedback-btn-arrow' style={hasNext ? {left: '15px'} : {display: 'none'}} onClick={()=>{navToNextBrand(currentIndex + 1)}}>
                            <FontAwesomeIcon icon={faChevronCircleRight} className='overlay-feedback-btn-arrow-icon'/>
                        </button>
                    </div>            
                </div>
            </div>
        </div>
    </div>
    </div>
    );
  }

export default PurchaseReview;