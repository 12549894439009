import '../App.css';
import { React, useState } from "react";
import CategoryBusinessTile from './CategoryBusinessTile';
import { faChevronCircleRight, faChevronCircleDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function BusinessCategory({businessCategory}) {
    const [isCategoryShown, setIsCategoryShown] = useState(false);

    return(
        <div className='category-section-wrapper'>
            <div className='category-section-name'>
                <div className='chevron-down-category-wrapper' onClick={()=>{setIsCategoryShown(!isCategoryShown)}}>
                    <FontAwesomeIcon icon={faChevronCircleDown} style={!isCategoryShown ? {display: 'none'}:{}}/>
                    <FontAwesomeIcon icon={faChevronCircleRight} style={isCategoryShown ? {display: 'none'}:{}}/>
                </div>
                <div className='business-category-name'>{businessCategory.baseCategoryName}</div>
            </div>
            <div className='category-section-businesses' style={!isCategoryShown ? {display: 'none'}:{}}>
                {businessCategory.businesses.map((business, index) => <CategoryBusinessTile key={index} categoryBusiness={business} adIndex={index}/>)}
            </div>
        </div>
    );
}

export default BusinessCategory;