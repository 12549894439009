import React from 'react';
import { slide as Menu } from 'react-burger-menu';
import '../Sidebar.css';
import { useNavigate, useLocation } from 'react-router-dom';

export default props => {
    const navigate = useNavigate();
    const location = useLocation();
  return (
    <Menu right>
      <div className={location.pathname === '/' ? 'menu-item active' : 'menu-item'} onClick={() => {navigate('/')}}>
        Home
      </div>
      <div className={location.pathname === '/leaderboard' ? 'menu-item active' : 'menu-item'} onClick={() => {navigate('/leaderboard')}}>
        Leaderboard
      </div>
      <div className={location.pathname === '/about' ? 'menu-item active' : 'menu-item'} onClick={() => {navigate('/about')}}>
        About
      </div>
      <div className={location.pathname === '/howitworks' ? 'menu-item active' : 'menu-item'} onClick={() => {navigate('/howitworks')}}>
        How it works
      </div>
    </Menu>
  );
};