import React from 'react';
import '../App.css';
import ActivelyShopping from '../Components/ActivelyShopping';

export default function ProfileActivelyShopping(user) {
  return (
    <div className='profile-content-main-wrapper'>
        <div className='profile-content-inner'>
            <div>
                <h2>Actively Shopping</h2>
            </div>
            <ActivelyShopping/>
        </div>
    </div>
  );
}