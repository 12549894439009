import '../App.css';
import { React, useEffect, useState } from "react";
import { useApi } from '../Contexts/ApiProvider';
import { useAuth0 } from "@auth0/auth0-react";
import Button from 'react-bootstrap/esm/Button';
import { useNavigate } from 'react-router-dom';
import { useSearchParams } from 'react-router-dom';
import AddNewAdBrandModal from './AddNewAdBrandModal';
import LoadingSpinner from './loadingSpinner';
import {toast} from 'react-toastify';

function Banner(pageName){
    const navigate = useNavigate();
    const { user, getAccessTokenSilently } = useAuth0();
    const [searchParams] = useSearchParams();
    const [isLoading, setIsLoading] = useState(false);
    const api = useApi();
    const navToPage = () => {
        if(searchParams.get('businessID') != null){
            navigate('/brands?businessID=' + searchParams.get('businessID'));
        }
        else{
            navigate('/brands');
        }
    }
    const navToProfilePage = () => {
        if(searchParams.get('businessID') != null){
            navigate('/feedbacks?businessID=' + searchParams.get('businessID'));
        }
        else{
            navigate('/feedbacks');
        }        
    }
    const navToPurchasesPage = () => {
        if(searchParams.get('businessID') != null){
            navigate('/purchases?businessID=' + searchParams.get('businessID'));
        }
        else{
            navigate('/purchases');
        }        
    }
    const getParsalAds = async () => {
        setIsLoading(true);
        const data = await api.get('advertisement/parsalads?activelyShopping=true', null, {
            headers: {
              Authorization:  'Bearer ' + await getAccessTokenSilently()
            }});
          if (!data.ok) {
            setIsLoading(false);
            toast.error('Unable to get Parsal Ads');
          }
          else {
            window.location.reload();
          }
        };

    if(pageName.pageName === 'ads'){
        return (
            <div className='no-ads-container'>
                <div style={isLoading ? {display: 'block'} : {display: 'none'}}>
                    <LoadingSpinner/>
                </div>
                <div style={isLoading ? {display: 'none'} : {display: 'block'}}>
                    <div className='no-ads-text-container'>No Available Ads</div>
                    <AddNewAdBrandModal/>
                    <div>
                        <Button variant="primary" type="button" className='social-continue-btn' onClick={() => {getParsalAds()}}>Get Parsal Ads</Button>
                    </div>
                    <div>
                        <Button variant="primary" type="button" className='social-continue-btn' onClick={navToPage}>Review Businesses</Button>
                    </div>
                </div>
            </div>
          );
    }
    else if(pageName.pageName === 'brands'){
        return (
            <div className='no-ads-container'>
                <div className='no-ads-text-container'>No Available Brands</div>
                <AddNewAdBrandModal/>
                <div>
                    <Button variant="primary" type="button" className='social-continue-btn' onClick={navToPurchasesPage}>Review Purchases</Button>
                </div>
            </div>
          );
    }
    else{
        return (
            <div className='no-ads-container'>
                <div className='no-ads-text-container'>No Pending Review</div>
                <div>
                    <Button variant="primary" type="button" className='social-continue-btn' onClick={navToProfilePage}>View Response(s)</Button>
                </div>
            </div>
          );
    }

    };

export default Banner;