import { React, useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import '../App.css';
import ToggleSwitch from "./ToggleSwitch";
import { useApi } from '../Contexts/ApiProvider';
import {toast} from 'react-toastify';

export default function ProfilePreferences() {
    let [isAutoUnsubscribe, setIsAutoUnsubscribe] = useState(false);
    let [isAutoActivelyShopping, setIsAutoActivelyShopping] = useState(false);
    let [isAutoEmailLoad, setIsAutoEmailLoad] = useState(false);
    let [isAutoAcceptAllSales, setIsAutoAcceptAllSales] = useState(false);
    let [isSuggestAds, setIsSuggestAds] = useState(false);
    let [isAcceptBrandFeedbackRequest, setIsAcceptBrandFeedbackRequest] = useState(false);
    const { user, getAccessTokenSilently } = useAuth0();
    const api = useApi();

    const savePreferences = async () => {
        const data = await api.post('consumer/preferences', {
            AutoUnsubscribeEmail: isAutoUnsubscribe,
            AutoActivelyShopping: isAutoActivelyShopping,
            AutoLoadEmails: isAutoEmailLoad,
            AcceptAllLeadSales: isAutoAcceptAllSales,
            ShowSuggestedAds: isSuggestAds,
            AcceptBrandFeedbackRequests: isAcceptBrandFeedbackRequest
          }, {
            headers: {
              Authorization:  'Bearer ' + await getAccessTokenSilently()
            }});
          if (!data.ok) {
            toast.error('Unable to update preferences');
          }
          else {
            toast('Preferences successfully updated');
          }
        };

    useEffect(() => {
        (async () => {
            var apiUri = 'consumer/preferences';
            
            const response = await api.get(apiUri, null, {
                headers: {
                    Authorization:  'Bearer ' + await getAccessTokenSilently()
                }});
            if (response.ok) {
                setIsAutoUnsubscribe(response.body.autoUnsubscribeEmail);
                setIsAutoActivelyShopping(response.body.autoActivelyShopping);
                setIsAutoEmailLoad(response.body.autoLoadEmails);
                setIsAutoAcceptAllSales(response.body.acceptAllLeadSales);
                setIsSuggestAds(response.body.showSuggestedAds);
                setIsAcceptBrandFeedbackRequest(response.body.acceptBrandFeedbackRequests);
            }
            else {
            }
        })();
        }, []);

  return (
    <div>
        <div className="preference-item-container">
            <div><span>Auto Unsubscribe from Emails: </span><span><ToggleSwitch label=" " toggleSelection={toggle => {
                            setIsAutoUnsubscribe(toggle);}} preferenceStatus={isAutoUnsubscribe}/></span></div>
        </div>
        <div className="preference-item-container">
            <div><span>Auto Actively Shopping: </span><span><ToggleSwitch label="  " toggleSelection={toggle => {
                            setIsAutoActivelyShopping(toggle);}} preferenceStatus={isAutoActivelyShopping}/></span></div>
        </div>
        <div className="preference-item-container">
            <div><span>Load New Promo Emails on Login: </span><span><ToggleSwitch label="              " toggleSelection={toggle => {
                            setIsAutoEmailLoad(toggle);}} preferenceStatus={isAutoEmailLoad}/></span></div>
        </div>
        {/*<div className="preference-item-container">
            <div><span>Accept All Lead Sales: </span><span><ToggleSwitch label="   " toggleSelection={toggle => {
                            setIsAutoAcceptAllSales(toggle);}} preferenceStatus={isAutoAcceptAllSales}/></span></div>
        </div>
        <div className="preference-item-container">
            <div><span>Show Suggested Ads: </span><span><ToggleSwitch label="     " toggleSelection={toggle => {
                            setIsSuggestAds(toggle);}} preferenceStatus={isSuggestAds}/></span></div>
        </div>
        <div className="preference-item-container">
            <div><span>Accept Brand Feedback Requests: </span><span><ToggleSwitch label="      " toggleSelection={toggle => {
                            setIsAcceptBrandFeedbackRequest(toggle);}} preferenceStatus={isAcceptBrandFeedbackRequest}/></span></div>
            </div>*/}
        <div>
            <button onClick={() => {savePreferences()}}>Save</button>
        </div>
    </div>
  );
}