import '../App.css';
import { faFireFlameCurved, faMagnifyingGlass, faAddressCard, faTags, faListCheck } from "@fortawesome/free-solid-svg-icons";
import { faFacebook, faGoogle, faAmazon, faInstagram, faYoutube, faTwitter } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from 'react-router-dom';
import ParsalIcon from '../ParsalIcon32.png'
import ParsalIconLg from '../ParsalIcon64.png'

export function Icon({iconName, currentPage=''}) {
    if(iconName === "icon"){
        return (        
            <div>
              <Link to={"/home"} className='white-text'>
                <div className={currentPage.currentPage === 'home' ? "icon-container-active" : "icon-container"} style={{fontSize: '28px'}}>
                    <img src={ParsalIcon} className='icon-container-svg'/>
                </div>
              </Link>
            </div>
          );
    }
    if(iconName === "icon-social"){
      return (        
          <div>
            <div className={currentPage.currentPage === 'social' ? "icon-container-active social-icon-bubble" : "icon-container social-icon-bubble"} style={{fontSize: '28px'}}>
              <img src={ParsalIconLg} className='icon-container-svg-main-icon'/>
            </div>
          </div>
        );
  }
    if(iconName === "search"){
        return (        
            <div>
              <Link to={"/categories"} className='white-text'>
              <div className={currentPage.currentPage === 'categories' ? "icon-container-active" : "icon-container"} style={{fontSize: '28px'}}>
                  <FontAwesomeIcon icon={faMagnifyingGlass} className='icon-container-svg'/>
                </div>
              </Link>
            </div>
          );
    }
    if(iconName === "ads"){
        return (        
            <div>
              <Link to={"/ads"} className='white-text'>
              <div className={currentPage.currentPage === 'ads' ? "icon-container-active" : "icon-container"} style={{fontSize: '28px'}}>
                  <FontAwesomeIcon icon={faAddressCard} className='icon-container-svg'/>
                </div>
              </Link>
            </div>
          );
    }
    if(iconName === "payments"){
        return (        
            <div>
              <Link to={"/social"} className='white-text'>
              <div className={currentPage.currentPage === 'payment' ? "icon-container-active" : "icon-container"} style={{fontSize: '28px'}}>
                  <FontAwesomeIcon icon={faTags} className='icon-container-svg'/>
                </div>
              </Link>
            </div>
          );
    }
    if(iconName === "feedback"){
      return (        
          <div>
            <Link to={"/feedbacks"} className='white-text'>
            <div className={currentPage.currentPage === 'feedback' ? "icon-container-active" : "icon-container"} style={{fontSize: '28px'}}>
                <FontAwesomeIcon icon={faListCheck} className='icon-container-svg'/>
              </div>
            </Link>
          </div>
        );
  }
    if(iconName === "facebook"){
        return (        
            <div>
              <div className="icon-container-large">
              <FontAwesomeIcon icon={faFacebook} className='icon-container-svg large-icon'/>
              </div>
            </div>
          );
    }
    if(iconName === "amazon"){
      return (        
          <div>
            <div className="icon-container-large">
            <FontAwesomeIcon icon={faAmazon} className='icon-container-svg large-icon'/>
            </div>
          </div>
        );
  }
    if(iconName === "google"){
        return (        
            <div>
              <div className="icon-container-large">
              <FontAwesomeIcon icon={faGoogle} className='icon-container-svg large-icon'/>
              </div>
            </div>
          );
    }
    if(iconName === "instagram"){
      return (        
          <div>
            <div className="icon-container-large">
            <FontAwesomeIcon icon={faInstagram} className='icon-container-svg large-icon'/>
            </div>
          </div>
        );
  }
  if(iconName === "youtube"){
    return (        
        <div>
          <div className="icon-container-large">
          <FontAwesomeIcon icon={faYoutube} className='icon-container-svg large-icon'/>
          </div>
        </div>
      );
  }
  if(iconName === "twitter"){
    return (        
        <div>
          <div className="icon-container-large">
          <FontAwesomeIcon icon={faTwitter} className='icon-container-svg large-icon'/>
          </div>
        </div>
      );
  }
  }