import { React, useEffect, useState } from "react";
import '../App.css';
import { useApi } from '../Contexts/ApiProvider';
import LoadingSpinner from '../Components/loadingSpinner';
import { useAuth0 } from "@auth0/auth0-react";
import {toast} from 'react-toastify';
import AddSocialConnectionModal from "./AddSocialConnectionModal";
import { faCheckCircle, faX, faXmarkCircle, faXmark, faCheck, faEye } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from 'moment';
import Modal from 'react-modal';

export default function SocialShopping() {
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingDetails, setIsLoadingDetails] = useState(false);
    let [connections, setConnections] = useState([]);
    let [connectionDetails, setConnectionDetails] = useState({
        connectionFavoriteBrands: [],
        connectionInterests: []
    });
    let [selectedConnection, setSelectedConnection] = useState({});
    const { user, getAccessTokenSilently } = useAuth0();
    const api = useApi();
    const [connectionDetailsModalIsOpen, setConnectionDetailsModalIsOpen] = useState(false);
    const customStylesConnectionDetails = {
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          transform: 'translate(-50%, -50%)',
          background: '#ffffff',
          width: '630px',
          textAlign: 'left',
          height: '650px'
        },
      }; 


      function openConnectionDetails(){
        setConnectionDetailsModalIsOpen(true);
    }

    function afterOpenModal() {
        // references are now sync'd and can be accessed.
      }
    
    function closeConnectionDetailsModal() {
        setConnectionDetailsModalIsOpen(false);
        setSelectedConnection({});
    }

    const removeConnection = async (connectionId) => {
        setIsLoading(true);
        const data = await api.post('social/remove/' + connectionId, null, {
            headers: {
              Authorization:  'Bearer ' + await getAccessTokenSilently()
            }});
          if (!data.ok) {
            setIsLoading(false);
            toast.error('Unable to remove connection');            
          }
          else {
            setIsLoading(false);
            toast('Connection successfully removed');
            setConnections(connections.filter(i => i.connectionId !== connectionId));
          }
        };

    const respondToConnection = async (connectionId, response) => {
        setIsLoading(true);
        const data = await api.post('social/respond/' + connectionId + '/' + response, null, {
            headers: {
                Authorization:  'Bearer ' + await getAccessTokenSilently()
            }});
            if (!data.ok) {
                setIsLoading(false);
                toast.error('Unable to respond to request');            
            }
            else {
                setIsLoading(false);
                toast('Connection successfully ' + response);
                await getConnections();
            }
        };

    const getConnectionDetails = async (connection) => {
        setIsLoadingDetails(true);
        setSelectedConnection(connection);
        setConnectionDetailsModalIsOpen(true);
        const data = await api.get('social/connectiondetails/' + connection.connectionId, null, {
            headers: {
                Authorization:  'Bearer ' + await getAccessTokenSilently()
            }});
            if (data.ok) {
                setConnectionDetails(data.body);
                setIsLoadingDetails(false);
            }
            else {
                setConnectionDetails();
                setIsLoadingDetails(false);
            }
        };


    const getConnections = async () => {
        setIsLoading(true);
        const data = await api.get('social', null, {
            headers: {
                Authorization:  'Bearer ' + await getAccessTokenSilently()
            }});
            if (data.ok) {
                setConnections(data.body);
                setIsLoading(false);
            }
            else {
                setConnections([]);
                setIsLoading(false);
            }
        };

    useEffect(() => {
        (async () => {
            await getConnections();
        })();
        }, []);

  return (
    <div>
        <Modal
            isOpen={connectionDetailsModalIsOpen}
            onAfterOpen={afterOpenModal}
            onRequestClose={closeConnectionDetailsModal}
            style={customStylesConnectionDetails}
            contentLabel="Example Modal"
            id='search-brand-modal'>
            <div style={isLoadingDetails ? {display: 'block'} : {display: 'none'}}>
                <LoadingSpinner/>
            </div>
            <div style={isLoadingDetails ? {display: 'none'} : {display: 'block'}}>
                <div>
                    <div className="connection-details-connection-intials-wrapper">
                        {selectedConnection.firstName !== undefined ? selectedConnection.firstName.substring(0, 1) : ''}{selectedConnection.lastName !== undefined ?selectedConnection.lastName.substring(0, 1): ''}
                    </div>
                    <div className="connection-details-connection-details-wrapper">
                        <div>
                            {selectedConnection.firstName} {selectedConnection.lastName}
                        </div>
                        <div className="social-connection-leaderboard-name">
                            {selectedConnection.leaderboardName} 
                        </div>
                        <div>
                            Joined {moment(selectedConnection.connectionCreateDate).format('LL')}
                        </div> 
                    </div>
                </div>
                <div>
                    <h2>Interests</h2>
                    {connectionDetails.connectionInterests.map((interest) => (
                        <div className="connection-details-interest">{interest}</div>
                    ))}
                </div>
                <div>
                    <h2>Favorite Brands</h2>
                    {connectionDetails.connectionFavoriteBrands.map((brand) => (
                        <div className="connection-details-favorite-brand-wrapper">
                            <img className="connection-details-favorite-brand-img" src={brand.businessImageUrl} title={brand.name} onClick={() => {window.open(brand.webUrl)}}/>
                        </div>
                    ))}
                </div>
            </div>
        </Modal>
        <div className="social-connections-pending-requests-wrapper">
            <div>
                <AddSocialConnectionModal/>
            </div>
            <div>
                <h2>Invites Received</h2>
            </div>
            <ul className="social-connection-list">
            {connections.filter(i => i.status === "Pending" && i.requiresResponse).map((connection, index) => (
                <li className="social-connection">
                    <div className="social-connection-icon">
                        {connection.firstName.substring(0, 1)}{connection.lastName.substring(0, 1)}
                    </div>
                    <div className="social-connection-details-wrapper">
                        <div className="social-connection-name">
                            {connection.firstName} {connection.lastName}
                        </div>
                        <div style={{color: '#8acb88'}}>
                            Received {moment(connection.connectionCreateDate).format('LL')}
                        </div>
                    </div>
                    <div className="social-connection-pending-actions">
                        <FontAwesomeIcon icon={faCheck} style={{color: 'green', margin: '10px'}} onClick={() => {respondToConnection(connection.connectionId, "accept")}}/>
                        <FontAwesomeIcon icon={faXmark} style={{color: 'red', margin: '10px'}} onClick={() => {respondToConnection(connection.connectionId, "reject")}}/>
                    </div>
                </li>
            ))}
            </ul>
        </div>
        <div className="social-connections-pending-sent-requests-wrapper">
            <div>
                <h2>Invites Sent</h2>
            </div>
            <ul className="social-connection-list">
            {connections.filter(i => i.status === "Pending" && !i.requiresResponse).map((connection, index) => (
                <li className="social-connection-list">
                    <div className="social-connection-icon">
                        {connection.firstName.substring(0, 1)}{connection.lastName.substring(0, 1)}
                    </div>
                    <div className="social-connection-details-wrapper">
                        <div className="social-connection-name">
                            {connection.firstName} {connection.lastName}
                        </div>
                        <div style={{color: '#8acb88'}}>
                            Sent {moment(connection.connectionCreateDate).format('LL')}
                        </div>
                    </div>
                    <div className="social-connection-remove">
                        <FontAwesomeIcon icon={faXmark} style={{color: 'red'}} onClick={() => {removeConnection(connection.connectionId)}}/>
                    </div>  
                </li>
            ))}
            </ul>
        </div>
        <div className="social-connections-wrapper">
            <div>
                <h2>Friends Network</h2>
            </div>
            <ul className="social-connection-list">
                {connections.filter(i => i.status === "Accepted").map((connection, index) => (
                    <li className="social-connection">
                        <div className="social-connection-icon">
                            {connection.firstName.substring(0, 1)}{connection.lastName.substring(0, 1)}
                        </div>
                        <div className="social-connection-details-wrapper">
                            <div className="social-connection-name">
                                {connection.firstName} {connection.lastName}
                            </div>
                            <div className="social-connection-leaderboard-name">
                                {connection.leaderboardName} 
                            </div>
                            <div>
                                Joined {moment(connection.connectionCreateDate).format('LL')}
                            </div>  
                        </div>
                        <div className="social-connection-remove">
                            <FontAwesomeIcon icon={faXmark} style={{color: 'red'}} onClick={() => {removeConnection(connection.connectionId)}}/>
                            <FontAwesomeIcon icon={faEye} onClick={() => {getConnectionDetails(connection)}}/>
                        </div>                
                    </li>
                ))}
            </ul>
        </div>
    </div>
  );
}