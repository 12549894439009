import React from "react";
import '../../LandingPage.css';
import { useNavigate } from 'react-router-dom';

export default function LandingFooter() {
    const navigate = useNavigate();
  return (
    <div className='landing-page-footer-wrapper'>
        <div className='footer-links'>
            <div className='footer-link' onClick={() => {navigate('/termsconditions')}}>Terms of Use</div>
            <div className='footer-link footer-link-right' onClick={() => {navigate('/privacy')}}>Privacy Policy</div>
            {/*<div className='footer-link footer-link-right'>Investor Requests</div>*/}
        </div>
    </div>
  );
}