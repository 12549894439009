import '../LandingPage.css';
import LandingNav from '../Components/LandingPage/LandingNav';
import LandingFooter from '../Components/LandingPage/LandingFooter';
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { useApi } from '../Contexts/ApiProvider';
import SpotAi from '../SpotAI.png';
import { useNavigate } from 'react-router-dom';
import AdSense from '../Components/AdSense';

function LandingAboutSpotAI() {
    const api = useApi();
    const [isLoading, setIsLoading] = useState(true);
    const navigate = useNavigate();

    return(
        <div className='landing-page-wrapper'>
            <LandingNav />
            <div style={{height: '50px'}}></div>
            <div className='landing-about-blog-content-wrapper'>      
                {/*<AdSense dataAdSlot='2013861535' />*/}         
                <div className='landing-about-blog-articles-wrapper'>
                    <div className='landing-about-blog-article'>
                        <div className='landing-about-blog-back-btn' onClick={() => (navigate('/about'))}>
                            <FontAwesomeIcon icon={faChevronLeft}/><span className='landing-about-blog-back-text'>Go Back</span>
                        </div>
                        <div className='landing-about-blog-article-title'>
                            <p>Spotting AI Fakes: A Beginner's Guide</p>
                            <p className='landing-about-blog-article-author'>Generated by the Parsal Team</p>
                        </div>
                        <div className='landing-about-blog-article-image'>
                            <img src={SpotAi} alt='Spot AI'/>
                        </div>
                        <div className='landing-about-blog-article-body'>
                            <p>Hey there, savvy internet surfer! Ever stumbled upon content that left you scratching your head, wondering if it was crafted by a creative genius or a mischievous AI? Fear not, because in this guide, we're diving into the delightful world of AI-generated content detection, armed with wit, wisdom, and maybe
                                 a sprinkle of existential dread. So, buckle up and let's embark on this journey through the digital jungle together.</p>
                            <div>
                                <p>⦁	The Language of Bots and Banter: Picture this: you're scrolling through a blog post when suddenly, you encounter a sentence that reads like it was written by a hyperactive squirrel on caffeine. Yup, that's our cue to suspect AI involvement! Keep an eye out for bizarre sentence structures, repetitive phrases, 
                                    or word choices that seem straight out of a sci-fi novel. After all, even AI has its quirks, and boy, are they entertaining!</p>

                                <p>⦁	Logic vs. AI: The Battle Royale: Ah, logic – the age-old nemesis of AI-generated content. Prepare yourself for a showdown of epic proportions as we pit human reasoning against AI randomness. From stories that take more twists and turns than a rollercoaster to conclusions that make less sense than a pineapple
                                     on pizza, you'll soon be able to spot AI shenanigans from a mile away. Game on, bots!
                                </p>
                                
                                <p>⦁	Metadata Madness: CSI Internet Edition: Now, here's where things get a tad Sherlockian. Dive deep into the murky waters of metadata and uncover clues that even the most cunning AI can't hide. Timestamps that don't add up? Author attributions as mysterious as a disappearing act? Sounds like a job for the
                                     digital detectives in all of us! Get ready to channel your inner CSI investigator and crack the case wide open.
                                </p>
                                <p>
                                ⦁	AI Detection Tools: The Unsung Heroes: Meet the unsung heroes of the digital realm – AI detection tools! Armed with algorithms sharper than a freshly sharpened pencil, these tools are here to save the day. With their help, you can sift through mountains of content with the precision of a surgeon and the
                                 swagger of a superhero. Cue the dramatic music, because it's time to uncover some AI fakes!
                                </p>
                                <p>
                                ⦁	Reverse Image Searches: Where Art Meets AI: Ever stumbled upon an image that seemed too good to be true? Cue the reverse image search! From unmasking image manipulation to tracking down the original source faster than you can say "Abracadabra," this nifty tool is your ticket to unraveling visual mysteries.
                                 Say cheese, AI – we're onto you!
                                </p>
                                <p>
                                ⦁	Sources and Citations: Truth or Dare? Last but not least, let's play a game of truth or dare with our sources and citations. From fact-checking like a pro to sniffing out fake references faster than a bloodhound, we're leaving no stone unturned in our quest for authenticity. So, buckle up, because things
                                 are about to get real... or should I say, "reel"?
                                </p>
                                <p>
                                In conclusion, spotting AI-generated content is as much about wit and humor as it is about sleuthing skills. With a dash of sarcasm, a sprinkle of skepticism, and a whole lot of laughter, you'll be navigating the digital landscape like a seasoned pro in no time. So, go forth, my friend, armed with knowledge,
                                 curiosity, and maybe a few jokes – because in the world of AI detection, laughter truly is the best medicine.
                                </p>
                                <p>
                                Did you spot it? Did you notice a sudden increase in the format and structure of the writing? Because this entire article was written almost entirely by the generative AI tool ChatGPT. The influx of generative AI is both wonderous and potentially terrifying. After all, how can businesses know that the attractive,
                                 good-looking customer they’ve been messaging is interested in their products? What if they are just being AI catfished? 
                                </p>
                                <p>
                                AI in all its exciting forms can be a powerful tool for businesses and consumers alike, when used well. However, at the end of the day, we are all looking for actual connections. After all, AI isn’t quite human-like enough to buy 24 cases of Flamin Hot Doritos, and until it needs to do that, businesses will need to
                                 make a truly human connection.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <LandingFooter/>
        </div>
    )
}

export default LandingAboutSpotAI;