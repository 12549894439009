import '../App.css';
import { React } from "react";
import { useNavigate } from 'react-router-dom';

function PrivacyPolicy() {
    const navigate = useNavigate();
    return(
        <div className='privacy-page' style={{backgroundColor: 'white'}}>
            <div style={{width:'100%', backgroundColor:'#514b4b'}}>
                <div style={{textAlign: 'left'}} onClick={() => {navigate('/')}}><img style={{width:'15%', marginTop:'25px'}} src='https://parsalgeneralstorage.blob.core.windows.net/generalresources/icon_brand.png'/></div>
            </div>
            <div style={{textAlign: 'left', backgroundColor: '#F5F5F', marginLeft: '20%', textWrap: 'pretty', width:'60%'}}>
            <h1  style={{textAlign: 'center'}}>Parsal App Privacy Policy</h1>
            <p>Last modified: June 6, 2024</p>
            <h2 style={{textDecoration: 'underline', fontWeight: 'normal'}}>Introduction</h2>
            <p>Parsal, LLC ("<b>Company</b>" or "<b>We</b>") respects your privacy, and we are committed to protecting it through our compliance with this policy. This policy describes: </p>
            <ul>
                <li>The types of information we may collect or that you may provide when you download, install, register with, access, or use the Parsal App (the "App"), or that you may choose to upload to the App.</li>
                <li>Our practices for collecting, using, maintaining, protecting, and disclosing that information.</li>
            </ul>
            <p>This policy applies only to information we collect in the App, and in email, text, and other electronic communications sent through or in connection with this App.</p>
            <p>This policy DOES NOT apply to information that:</p>
            <ul>
                <li>We collect offline;</li>
                <li>That others collect through websites, advertisements, and apps you may access or interact with through the App; or</li>
                <li>You provide to or is collected by any third party (see Third-Party Information Collection) related to or arising from your use of the App.</li>
            </ul>
            <p>Other third parties may have their own privacy policies, which we encourage you to read before providing information on or through them. Any privileges you grant to the App for the automated uploading of your information through Google, Amazon, Meta, or other service providers is governed by those third parties’ privacy policies. You are not obligated to upload any information to the App, but the App’s effectiveness in assisting you to curate your personal marketing lead data is dependent on the information that you choose to provide.</p>
            <p>Please read this policy carefully to understand our policies and practices regarding your information and how we will treat it. If you do not agree with our policies and practices, do not download, register with, or use this App or upload any information to the App. By downloading, registering with, or using this App, or uploading information to the App, you agree to this privacy policy. This policy may change from time to time (see Changes to Our Privacy Policy). Your continued use of this App after we revise this policy means you accept those changes, so please check the policy periodically for updates.</p>
            <h2 style={{textDecoration: 'underline', fontWeight: 'lighter'}}>Children Under the Age of 16</h2>
            <p>The App is not intended for children under 16 years of age, and we do not knowingly collect personal information from children under 16. If we learn we have collected or received personal information from a child under 16 without verification of parental consent, we will delete that information. If you believe we might have any information from or about a child under 16, please contact us at jason@parsal.app.</p>
            <h2 style={{textDecoration: 'underline', fontWeight: 'lighter'}}>Information We Collect and How We Collect It</h2>
            <p>We collect information from and about users of our App:</p>
            <ul>
                <li>Directly from you when you provide it to us;</li>
                <li>Automatically when you use the App;</li>
                <li>Information you upload to the App through your Google, Amazon, Meta, or other accounts;</li>
                <li>Automatically when you provide permissions to the App to pull your “Promotions” folder from your Gmail account;</li>
                <li>Advertisement data from advertisements you interacted with on Facebook that you upload to the App;</li>
                <li>The list of advertisers targeting you on Amazon that you upload to the App;</li>
                <li>Advertisements you share with others through the App;</li>
                <li>Advertisements that you interact with using your device’s camera, such as through an advertisement’s QR code;</li>
                <li>Responses to micro surveys regarding advertisements and brands, including your preferences for advertisements and brands;</li>
                <li>Information regarding a your past purchases and anticipated future purchases that you provide to the App;</li>
                <li>Information regarding advertisers whose advertisement you upload into the App; and</li>
                <li>From third-party advertisements and marketing materials pulled into the App from your email, social media, and other sources to which you give access to the App;</li>
            </ul>
            <h2 style={{fontWeight: 'lighter'}}><i>Information You Provide to Us</i></h2>
            <p>When you download, register with, or use this App, we may ask you to provide information:</p>
            <ul>
                <li>By which you may be personally identified, such as your name, postal address, email address, telephone number, gender, race, and birth date ("<b>personal information</b>").</li>
                <li>That is about you but individually does not identify you, such as your purchasing habits, product and brand preferences, and other advertisement and marketing related information.</li>
            </ul>
            <p>This information includes:</p>
            <ul>
                <li>Information that you provide by filling in forms in the App, including your personal profile. This also includes information provided at the time of registering to use the App, responding to micro surveys on the App, selecting preferred products and brands, and curating your personal marketing lead generating identity. We may also ask you for information requested by our business customers to increase the quality of your personal data, and when you report a problem with the App.</li>
                <li>Records and copies of your correspondence (including email addresses and phone numbers), if you contact us.</li>
                <li>Records and copies of the email-based advertisements uploaded or pulled into the App from any account that you link to the App.</li>
                <li>Your responses to micro surveys that we might ask you to complete for improving the quality for your marketing lead generating data.</li>
                <li>Your preferences for advertisements and brands.</li>
                <li>Your past purchases and anticipated future purchases.</li>
                <li>Advertisements that you interact with using your device’s camera, such as through an advertisements QR code.</li>
                <li>Advertisements that you share with others through the App.</li>
                <li>Details of transactions you carry out through the App including information relating to any compensation you receive through use of the App. You may be required to provide your bank account or Venmo information (“Financial Information”) to us to receive compensation through the App. We store your Financial Information to make payments to you through the App.</li>
                <li>Your search queries on the App.</li>
            </ul>
            <h2 style={{fontWeight: 'lighter'}}><i>Automatic Information Collection</i></h2>
            <p>When you download, access, and use the App, it may use technology to automatically collect:</p>
            <ul>
                <li><b>Usage Details.</b> When you access and use the App, we may automatically collect certain details of your access to and use of the App, including traffic data, browser data, location data, logs, and other communication data and the resources that you access and use on or through the App.</li>
                <li><b>Device Information.</b> We may collect information about your mobile device and internet connection, including the device’s unique device identifier, IP address, operating system, browser type, mobile network information, and the device’s telephone number.</li>
                <li><b>Stored Information and Files.</b> The App also may access metadata and other information associated with other files stored on your device. This includes the file explorer used through the App to interact with the Facebook and Amazon files you upload to the App.  This may also include, for example, photographs, audio and video clips, personal contacts, and address book information.</li>
            </ul>
            <p>If you do not want us to collect this information do not download the App or delete it from your device, or otherwise cease using the Parsal website.</p>
            <p>We also may use these technologies to collect information about your activities over time and across third-party websites, apps, or other online services (behavioral tracking). The App will expressly ask for your consent before permitting any technologies to collect information about your activities.</p>
            <h2 style={{fontWeight: 'lighter'}}><i>Information Collection and Tracking Technologies</i></h2>
            <p>The technologies we use for automatic information collection may include:</p>
            <ul>
                <li><b>Cookies (or mobile cookies).</b> A cookie is a small file placed on your smartphone or in your browser. It may be possible to refuse to accept mobile cookies by activating the appropriate setting on your smartphone. However, if you select this setting you may be unable to access certain parts of our App.</li>
            </ul>
            <h2 style={{fontWeight: 'lighter'}}><i>Third-Party Information Collection</i></h2>
            <p>Third parties will not collect information about you through your use of the App through automatic collection technologies. Parsal does not and will not share information from your Gmail account with third parties or other users of the App. However, you may choose to share promotions and advertisements received and stored in your Gmail promotions folder with third parties and other users of the App.</p> 
            <p>Parsal may share information with third parties that is generated by you through your use of the App. This information includes your responses to questions in the App regarding your advertisement and brand preferences, shopping history and plans. This curated marketing lead generating data is created by you through your use of the App, including your responses to micro surveys and selections made by you regarding your brand and product preferences and related marketing information. Additional information shared with third parties through your use of the App may also include any information you choose to provide to a business targeting your through the App if you consent to continued communication between you and that business. We may sell this information that is generated by your use of the App and responses to inquiries regarding advertisements, brands, and shopping habits to third parties as curated marketing leads. This process may be automated, but the App will request your consent before any such automation is applied. Any information shared with, transferred to, or sold to a third party will not include information that is pulled from your Gmail account.</p>
            <h2 style={{textDecoration: 'underline', fontWeight: 'lighter'}}>How We Use Your Information</h2>
            <p>We use information that we collect about you or that you provide to us through use of the App, including any personal information, to:</p>
            <ul>
                <li>Provide you with the App and its contents, and any other information, products, or services that you request from us.</li>
                <li>Improve your data profile for marketing and advertisement purposes.</li>
                <li>Extrapolate and analyze your advertisement and brand preferences, past and anticipated future purchases, and interaction with advertisements.</li>
                <li>Sell your micro survey responses, affiliate traffic, and your answers to shopping preferences while using the App. We DO NOT sell Google account information or any information pulled from your Gmail account.</li>
                <li>Any other purpose for which you provide it on the App.</li>
                <li>Give you notices about your account, including expiration and renewal notices.</li>
                <li>Carry out our obligations and enforce our rights arising from any contracts entered into between you and us, including for billing, collection, and payment as applicable.</li>
                <li>Notify you when App updates are available, and of changes to any products or services we offer or provide though it.</li>
            </ul>
            <p>The usage information we collect helps us to improve our App and to deliver a better and more personalized experience by enabling us to:</p>
            <ul>
                <li>Estimate our audience size and usage patterns.</li>
                <li>Improve advertiser’s information about you as a marketing lead, including your brand preferences, purchasing habits, and related information.</li>
                <li>Store information about your preferences, allowing us to customize our App according to your individual interests.</li>
                <li>Speed up your searches.</li>
                <li>Recognize you when you use the App.</li>
            </ul>
            <p>We may use any location information we collect to further refine your data profile, including to link you with local vendors and service providers and to provide advertisement suggestions for you to respond to based on your brand and product preferences.</p>
            <p>We will also use your information to contact you about our own and third parties’ goods and services that may be of interest to you. If you do not want us to use your information in this way, please delete or otherwise install the App from your device. For more information, see Your Choices About Our Collection, Use, and Disclosure of Your Information.</p>
            <p>We will also use the information you provide and we collect to display advertisements to our advertisers’ target audiences. Even though we do not disclose your personal information for these purposes without your consent, if you click on or otherwise interact with an advertisement, the advertiser may assume that you meet its target criteria.</p>
            <p>Parsal will not use the information from your Gmail account for any purpose other than to present you with the advertisements in your Gmail Promotions folder to allow you to respond to inquiries regarding those advertisements. While Parsal may gather, use, and share with others your responses to such inquiries related to advertisements in your Gmail account, Parsal does not and will not transfer or share the information held in your Gmail account.</p>
            <h2 style={{textDecoration: 'underline', fontWeight: 'lighter'}}>Disclosure of Your Information</h2>
            <p>We may disclose aggregated information about our users, and information that does not identify any individual, or device, without restriction.</p>
            <p>In addition, we may disclose personal information that we collect or you provide through use of the App and in response to inquiries regarding advertisement and brand preferences and shopping habits and plans:</p>
            <ul>
                <li>To third parties that pay for your curated marketing lead data profile to market their products and services to you, which you have consented to by using the App. Your Choices About Our Collection, Use, and Disclosure of Your Information.</li>
                <li>To our subsidiaries and affiliates.</li>
                <li>To contractors, service providers, and other third parties we use to support our business and who are bound by contractual obligations to keep personal information confidential and use it only for the purposes for which we disclose it to them.</li>
                <li>To a buyer or other successor in the event of a merger, divestiture, restructuring, reorganization, dissolution, or other sale or transfer of some or all of Parsal’s assets, whether as a going concern or as part of bankruptcy, liquidation, or similar proceeding, in which personal information held by Parsal about our App users is among the assets transferred.</li>
                <li>To fulfill the purpose for which you provide it, such as improving the quality of your marketing lead profile.</li>
                <li>For any other purpose disclosed by us when you provide the information.</li>
                <li>With your consent.</li>
                <li>To comply with any court order, law, or legal process, including to respond to any government or regulatory request.</li>
                <li>To enforce our rights arising from any contracts entered into between you and us, including the Parsal End User Licensing Agreement and for billing and collection.</li>
                <li>If we believe disclosure is necessary or appropriate to protect the rights, property, or safety of Parsal, our customers, or others. This includes exchanging information with other companies and organizations for the purposes of fraud protection and credit risk reduction.</li>
                <li>Parsal will not disclose or share information pulled from your Gmail account, but you may choose to share such information with others through the App or otherwise.</li>
            </ul>
            <h2 style={{textDecoration: 'underline', fontWeight: 'lighter'}}>Your Choices About Our Collection, Use, and Disclosure of Your Information</h2>
            <p>We strive to provide you with choices regarding the personal information you provide to us. This section describes mechanisms we provide for you to control certain uses and disclosures of over your information.</p>
            <ul>
                <li><b>Tracking Technologies.</b> You can set your browser to refuse all or some browser cookies, or to alert you when cookies are being sent. If you disable or refuse cookies or block the use of other tracking technologies, some parts of the App may then be inaccessible or not function properly.</li>
                <li><b>Promotion by the Company.</b> If you do not want us to use your information to promote our own or third parties' products or services, you can opt-out by sending an email indicating your desire to opt-out to jason@parsal.app.</li>
                <li><b>Disclosure of Your Information for Third-Party Advertising and Marketing.</b> If you do not want us to use information that we collect or that you provide to us to deliver advertisements according to our advertisers' target-audience preferences, you can opt-out by sending an email indicating your desire to opt-out to jason@parsal.app.</li>
                <li><b>Providing Your Curated Marketing Lead Data to Third Parties.</b> If you do not want us to sell or otherwise share your personal information, including your curated marketing lead data, to or with unaffiliated or non-agent third parties for advertising and marketing purposes, you can opt-out by sending an email indicating your desire to opt-out to jason@parsal.app.</li>
            </ul>
            <p>We do not control third parties' collection or use of your information to serve interest-based advertising. However these third parties may provide you with ways to choose not to have your information collected or used in this way. You can opt out of receiving targeted ads from members of the Network Advertising Initiative ("<b>NAI</b>") on the NAI's website.</p>
            <p>Residents in certain states, such as California, may have additional personal information rights and choices. Please see your state’s respective privacy laws for more information.</p>
            <h2 style={{textDecoration: 'underline', fontWeight: 'lighter'}}>Accessing and Correcting Your Personal Information</h2>
            <p>You can review and change your personal information by logging into the App and visiting your account profile page.</p>
            <p>You may also send us an email at jason@parsal.app to request access to, correct, or delete any personal information that you have provided to us. We cannot delete your personal information except by also deleting your user account. We may not accommodate a request to change information if we believe the change would violate any law or legal requirement or cause the information to be incorrect.</p>
            <p>Residents in certain states, such as California, may have additional personal information rights and choices. Please see your state’s respective privacy laws for more information.</p>
            <p>We do not control third parties' collection or use of your information to serve interest-based advertising. However these third parties may provide you with ways to choose not to have your information collected or used in this way. You can opt out of receiving targeted ads from members of the Network Advertising Initiative ("<b>NAI</b>") on the NAI's website.</p>
            <p>Residents in certain states, such as California, may have additional personal information rights and choices. Please see your state’s respective privacy laws for more information.</p>
            <h2 style={{textDecoration: 'underline', fontWeight: 'lighter'}}>Your State Privacy Rights</h2>
            <p>State consumer privacy laws may provide their residents with additional rights regarding our use of their personal information. Please review your state’s privacy laws for more information.</p>
            <p>California’s "Shine the Light" law (Civil Code Section § 1798.83) permits users of our App that are California residents to request certain information regarding our disclosure of personal information to third parties for their direct marketing purposes. To make such a request, please send an email to jason@parsal.app.</p>
            <p>To learn more about consumer privacy rights in other states and how to exercise them, we encourage you to review your state’s privacy laws.</p>
            <h2 style={{textDecoration: 'underline', fontWeight: 'lighter'}}>Data Security</h2>
            <p>We have implemented measures designed to secure your personal information from accidental loss and from unauthorized access, use, alteration, and disclosure. All information you provide to us is stored on our secure servers behind firewalls. Any payment transactions will be encrypted.</p>
            <p>The safety and security of your information also depends on you. Where we have given you (or where you have chosen) a password for access to certain parts of our App, you are responsible for keeping this password confidential. We ask you not to share your password with anyone.</p>
            <p>Unfortunately, the transmission of information via the internet and mobile platforms is not completely secure. Although we do our best to protect your personal information, we cannot guarantee the security of your personal information transmitted through our App. Any transmission of personal information is at your own risk. We are not responsible for circumvention of any privacy settings or security measures we provide.</p>
            <h2 style={{textDecoration: 'underline', fontWeight: 'lighter'}}>Changes to Our Privacy Policy</h2>
            <p>We may update our privacy policy from time to time. If we make material changes to how we treat our users’ personal information, we will post the new privacy policy on this page with a notice that the privacy policy has been updated and notify you by email to the primary email address specified in your account and an in-App alert the first time you use the App after we make the change.</p>
            <p>The date the privacy policy was last revised is identified at the top of the page. You are responsible for ensuring we have an up-to-date active and deliverable email address for you and for periodically visiting this privacy policy to check for any changes.</p>
            <h2 style={{textDecoration: 'underline', fontWeight: 'lighter'}}>Contact Information</h2>
            <p>To ask questions or comment about this privacy policy and our privacy practices, contact us at:</p>
            <p>jason@parsal.app.</p>
            <p>To register a complaint or concern, please send an email detailing your complaint or concern to jason@parsal.app.</p>
        </div>
        </div>
    )
}

export default PrivacyPolicy;