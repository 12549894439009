import '../LandingPage.css';
import LandingNav from '../Components/LandingPage/LandingNav';
import LandingFooter from '../Components/LandingPage/LandingFooter';
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { useApi } from '../Contexts/ApiProvider';
import adCollage from '../AdCollage.png';
import OriginalAd from '../OriginalAd.png';
import { useNavigate } from 'react-router-dom';
import AdSense from '../Components/AdSense';

function LandingAboutDeal() {
    const api = useApi();
    const [isLoading, setIsLoading] = useState(true);
    const navigate = useNavigate();

    return(
        <div className='landing-page-wrapper'>
            <LandingNav />
            <div style={{height: '50px'}}></div>
            <div className='landing-about-blog-content-wrapper'>
                {/*<AdSense dataAdSlot='2013861535' />*/}
                <div className='landing-about-blog-articles-wrapper'>
                    <div className='landing-about-blog-article'>
                        <div className='landing-about-blog-back-btn' onClick={() => (navigate('/about'))}>
                            <FontAwesomeIcon icon={faChevronLeft}/><span className='landing-about-blog-back-text'>Go Back</span>
                        </div>
                        <div className='landing-about-blog-article-title'>
                            <p>A Data Deal with the Devil</p>
                            <p className='landing-about-blog-article-author'>Written by The Parsal Team</p>
                        </div>
                        <div className='landing-about-blog-article-image'>
                            <img src={adCollage} alt='adCollage'/>
                        </div>
                        <div className='landing-about-blog-article-body'>
                            <p>Your data is sold every day for thousands of dollars, if not more. Its a fact that our 
                                use of online brands like Facebook, Instagram, Twitter, Google, and more make up a 
                                large portion of our everyday life, and that those services are funded by your data 
                                getting sold, sold, and sold again. How did this happen?</p>

                            <div>
                                <p>When the internet was first brought to the mainstream, it changed everything. 
                                    Location and distance were no longer the barriers they once were, you could 
                                    communicate, shop, and visit the Space Jam website (<a href='https://www.spacejam.com/' target='_blank'>still online by the way</a>).</p>

                                <p>In 1994, as the internet began to take hold and grow in popularity, a small off shoot 
                                    company of Wired magazine called Hotwired, created the first “banner ad”. </p>

                                <img src={OriginalAd} alt='OriginalBannerAd'/>

                                <p>Hotwired needed to find a way to generate revenue to fund its free online content, 
                                    in place of its traditional cousin's magazine subscription feeds. So, they created a 
                                    brilliant and world changing idea. They would create small sections of their site that 
                                    they could sell to advertisers, like how magazine and newspapers would sell ad 
                                    space. </p>

                                <p>AT&T paid $30k to for the right to display the banner ad above on the Hotwire site 
                                    for three months. The ad generated massive response, the first of its kind, including
                                    a clickthrough rate of nearly half of the traffic to the site – 44%. For comparison, 
                                    ad’s today can range from .01% to .1% on averaged depending on the content.</p>
                                
                                <p>Hotwired had struck a gold mine, but like a Tolkienesque curse, soon we would dig 
                                    too deep. In 1995, inspired by the momentum that banner ad's were gaining, an ad 
                                    agency called WebConnect began offering tracking for their clients to better 
                                    understand what websites their customer's were visiting, connect user data and 
                                    behavior to targeted advertising online for the first time. The Balrog was awakened.</p>

                                <p>In 2000, a little up and coming search engine company launched what would 
                                    become their most successful financial product, Goodle Adwords. Today, Google's 
                                    total yearly revenue is in excess of $300 billion (with a B) : more than 60% of that 
                                    revenue is generated through ad sales.
                                    </p>
                                
                                <p>With the advent of social media in the 2000s, starting with myspace in 2003, the 
                                    internet transformed from message boards, shopping, and hobby sites to a massive 
                                    social communications hub : enhancing and expanding on all that came before it.</p>

                                <p>The best thing about the amazing new world of social media? It was all free.
                                    However, being free to use came at a different price and these two related streams 
                                    of innovation, online advertising using personal data and the glut of personal data 
                                    provided by social media melded together into a giant eye of fire and flame... 
                                    perhaps these Lord of the Rings references have gone too far.
                                    </p>
                                
                                <p>Today, advertising and lead generation built on the back of your personal online 
                                    data is projected to reach nearly $750 billion dollars in market size by 2030, with 
                                    companies like Google, Facebook, Amazon and many others continuing to grow by 
                                    taking your personal data and offering it to companies to target with advertising 
                                    and marketing. </p>

                                <p>Many of those who use the internet the most, those born in the 1990s and onward, 
                                    have never known it any other way than as a value exchange of personal data for 
                                    services : when they are aware at all.</p>
                                
                                <p>There are companies, like Parsal, working to change this data marketplace. Its your 
                                    data, why should big companies be the only ones getting to leverage it to earn 
                                    revenue, why not you? </p>

                                <p>A big hurdles in pushing back against the current online advertising standards are 
                                    that:
                                    <p style={{paddingLeft: '4em'}}>1: They work. Even if everyone hates it, targeted online ads work and drive 
                                    revenue for the companies buying them.</p>
                                    <p style={{paddingLeft: '4em'}}>2: The big players have cornered the market. The big social media and ad 
                                    publishing giants provide the biggest bang for advertisers buck with the least 
                                    amount of work.</p>
                                    <p style={{paddingLeft: '4em'}}>3: One user doesnt have the leverage to argue for their ownership of the data.</p>
                                </p>

                                <p>So, to build something better it has to work BETTER, create a NEW market, give 
                                    USERS the power to negotiate value in return for their personal data. </p>

                                <p>Join Parsal today to help create this new marketplace, together we can take back 
                                    the internet.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <LandingFooter/>
        </div>
    )
}

export default LandingAboutDeal;