import Form from 'react-bootstrap/Form';
import React, {useState} from 'react';

export default function InputField(
  { name, label, type, placeholder, error, fieldRef, shouldShow, isChecked}
) {
  const [checked, setChecked] = useState(isChecked != undefined ? isChecked : false);
  return (
    <Form.Group controlId={name} 
    className='InputField'
    style={shouldShow === "false" ? {display: 'none'} : {}} onClick={(elemTest) => {
      if(elemTest.target.nodeName === 'DIV'){
        elemTest.target.defaultChecked = !elemTest.target.defaultChecked;
        elemTest.target.defaultChecked ? elemTest.target.classList.add('InputFieldChecked') : elemTest.target.classList.remove('InputFieldChecked')
        setChecked(!checked);
      }
    }}>
      {label && <Form.Label><span onClick={(elemTest) => {
        elemTest.target.defaultChecked = !elemTest.target.defaultChecked;
        elemTest.target.defaultChecked ? elemTest.target.parentElement.parentElement.classList.add('InputFieldChecked') : elemTest.target.parentElement.parentElement.classList.remove('InputFieldChecked')
        setChecked(!checked);
      }}>{label}</span></Form.Label>}
      {type === 'textarea' ? <Form.Control
        type={type || 'text'}
        as='textarea'
        placeholder={placeholder}
        ref={fieldRef}
        className='feedback-form-text-area'
      /> : 
      type === 'checkbox' ? <Form.Control
      type='checkbox'
      placeholder={placeholder}
      ref={fieldRef}
      checked={checked}
      defaultChecked={false}
      style={{visibility:'hidden'}}
      /> :
      <Form.Control
      type={type || 'text'}
      placeholder={placeholder}
      ref={fieldRef}
      />}
      <Form.Text className="text-danger">{error}</Form.Text>
    </Form.Group>
  );
}