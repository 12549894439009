import '../App.css';
import { faXmarkCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useApi } from '../Contexts/ApiProvider';
import React, { useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import Modal from 'react-modal';
import {toast} from 'react-toastify';
import LoadingSpinner from "../Components/loadingSpinner";

export default function AddSocialConnectionModal() {
    const api = useApi();
    const { user, getAccessTokenSilently } = useAuth0();
    const [isLoading, setIsLoading] = useState(false);
    let [inviteUserEmail, setInviteUserEmail] = useState('');
    const customStylesAddSocialConnection = {
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          transform: 'translate(-50%, -50%)',
          background: '#ffffff',
          width: '630px',
          textAlign: 'left',
          height: '650px'
        },
      };
    const [addSocialConnectionModalIsOpen, setAddSocialConnectionModalIsOpen] = React.useState(false);

    const sendInvite = async () => {
        setIsLoading(true);
        const data = await api.post('social/send', {
            socialShopperEmail: inviteUserEmail
          }, {
            headers: {
              Authorization:  'Bearer ' + await getAccessTokenSilently()
            }});
          if (!data.ok) {
            toast.error('Unable to send invite');
            closeAddSocialConnectionModal();
            setIsLoading(false);
          }
          else {
            toast('Invite sent');
            closeAddSocialConnectionModal();
            setIsLoading(false);
          }
        };

    function openAddSocialConnection(){
        setAddSocialConnectionModalIsOpen(true);
    }

    function afterOpenModal() {
        // references are now sync'd and can be accessed.
      }
    
    function closeAddSocialConnectionModal() {
        setAddSocialConnectionModalIsOpen(false);
        setInviteUserEmail('');        
    }

  return (
    <div>
        <Modal
            isOpen={addSocialConnectionModalIsOpen}
            onAfterOpen={afterOpenModal}
            onRequestClose={closeAddSocialConnectionModal}
            style={customStylesAddSocialConnection}
            contentLabel="Example Modal"
            id='search-brand-modal'>
            <div style={isLoading ? {display: 'block'} : {display: 'none'}}>
                <LoadingSpinner/>
            </div>
            <div style={isLoading ? {display: 'none'} : {display: 'block'}}>
                <div className="actively-shopping-done-prompt-header">
                    <div className="actively-shopping-done-prompt-header-text">
                        <span>Invite Friends by Email</span>
                        <span className="actively-shopping-done-prompt-x" onClick={() => {closeAddSocialConnectionModal()}}><FontAwesomeIcon icon={faXmarkCircle}/></span>
                    </div>
                </div>
                <div className='add-new-business-modal-search'>
                    <input id='search-brand-term' className='business-modal-search-bar' type='search' value={inviteUserEmail} onChange={(selectedValue) => {setInviteUserEmail(selectedValue.target.value)}}/>
                    <button className='bus-search-btn' onClick={()=>{sendInvite()}}>Send</button>
                </div>
            </div>
        </Modal>
        <div className='add-social-connection-wrapper'>
            <button className='add-social-connection-btn' onClick={() => {openAddSocialConnection()}}>+</button><div className='invite-friends-social-btn-label'>Invite Friends</div>
        </div>
    </div>
  );
}