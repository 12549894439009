import { faAddressCard } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from 'react-router-dom';

function CategoryBusinessTile({categoryBusiness, adIndex}) {
    const navigate = useNavigate();

    const navToPage = () => {
        navigate('/ads?businessID=' + categoryBusiness.id);
    }
    return(
        <div className="category-section-tile-wrapper">
            <div className="category-section-tile-img-wrapper"><img src={categoryBusiness.businessImageUrl} className="category-section-tile-img"/></div>
            <div className="cateogry-section-tile-content-wrapper">
                <div className="category-section-tile-business-name">{categoryBusiness.name}</div>
                <div>{categoryBusiness.industry}</div>
            </div>
            <div className="category-section-tile-view-btn-wrapper">
                <button className="category-section-tile-view-btn" onClick={navToPage}><div style={{fontSize: '32px'}}><FontAwesomeIcon icon={faAddressCard}/></div><div>View Ads</div></button>
            </div>
        </div>
    );
}

export default CategoryBusinessTile;