import './App.css';
import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { AuthenticationGuard } from './Components/AuthenticationGuard';
import ApiProvider from './Contexts/ApiProvider';
import HomeContent from './Pages/HomeContent';
import SocialConnections from './Pages/SocialConnections';
import Advertisements from './Pages/Advertisements';
import RegistrationPage from './Pages/Registration';
import Landing from './Pages/Landing';
import LandingLeaderboard from './Pages/LandingLeaderboard';
import LandingAbout from './Pages/LandingAbout';
import LandingHowItWorks from './Pages/LandingHowItWorks';
import Feedbacks from './Pages/Feedbacks';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Categories from './Pages/Categories';
import LoadingSpinner from './Components/loadingSpinner';
import Profile from './Pages/Profile';
import Brands from './Pages/Brands';
import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Purchases from './Pages/Purchases';
import Footer from './Components/Footer';
import PrivacyPolicy from './Pages/PrivacyPolicy';
import TermsConditions from './Pages/TermsConditions';
import Notification from './Components/Notification';
import DataSyncPage from './Pages/ProfileDataSync';
import GetStarted from './Pages/GetStarted';
import LandingAboutDeal from './Pages/LandingBlogDeal';
import LandingAboutTellMe from './Pages/LandingBlogTellMe';
import LandingBlogBehavior from './Pages/LandingBlogBehavior';
import LandingBlogBehaviorContinued from './Pages/LandingBlogBehaviorContinued';
import LandingAboutSpotAI from './Pages/LandingBlogSpotAI';

export function App() {
  const { isLoading } = useAuth0();

  if (isLoading) {
    return (
      <div className="page-layout">
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <div className="App">
      <ToastContainer
          autoClose={5000}
      />
      <Notification />
    <BrowserRouter>
      <ApiProvider>
        <Routes>
            <Route path="Home" element={<AuthenticationGuard component={HomeContent} />} />
            <Route path="Social" element={<AuthenticationGuard component={SocialConnections} />} />
            <Route path="Ads" element={<AuthenticationGuard component={Advertisements} />} />
            <Route path="Register" element={<AuthenticationGuard component={RegistrationPage} />} />
            <Route path="Sync" element={<AuthenticationGuard component={DataSyncPage} />} />
            <Route path="GetStarted" element={<AuthenticationGuard component={GetStarted} />} />
            <Route path="Feedbacks" element={<AuthenticationGuard component={Feedbacks} />} />
            <Route path="Categories" element={<AuthenticationGuard component={Categories} />} />
            <Route path="Brands" element={<AuthenticationGuard component={Brands} />} />
            <Route path="Purchases" element={<AuthenticationGuard component={Purchases} />} />
            <Route path="Profile" element={<AuthenticationGuard component={Profile} />} />
            <Route index element={<Landing/>}/>
            <Route path="Leaderboard" element={<LandingLeaderboard/>}/>
            <Route path="About" element={<LandingAbout/>}/>
            <Route path="HowItWorks" element={<LandingHowItWorks/>}/>
            <Route path="Privacy" element={<PrivacyPolicy/>}/>
            <Route path="TermsConditions" element={<TermsConditions/>}/>
            <Route path="Blog/DataDeal" element={<LandingAboutDeal/>}/>
            <Route path="Blog/TellMe" element={<LandingAboutTellMe/>}/>
            <Route path="Blog/Behavior" element={<LandingBlogBehavior/>}/>
            <Route path="Blog/BehaviorContinued" element={<LandingBlogBehaviorContinued/>}/>
            <Route path="Blog/SpotAI" element={<LandingAboutSpotAI/>}/>
        </Routes>
        <Footer />
      </ApiProvider>
    </BrowserRouter>
    </div>
  );
}
