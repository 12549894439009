import '../App.css';
import NavBar from '../Components/NavBar';
import Banner from '../Components/Banner';
import { faQrcode } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useApi } from '../Contexts/ApiProvider';
import React, { useEffect, useState } from "react";
import Button from 'react-bootstrap/Button';
import { useAuth0 } from "@auth0/auth0-react";
import BusinessCategory from '../Components/BusinessCategory';
import LoadingSpinner from '../Components/loadingSpinner';
import Modal from 'react-modal';
import { useNavigate } from 'react-router-dom';
import {toast} from 'react-toastify';
import QrReader from '../Components/QrReader';
import AddNewAdBrandModal from '../Components/AddNewAdBrandModal';

function Businesses() {
    const api = useApi();
    const { user, getAccessTokenSilently } = useAuth0();
    const [businessCategories, setbusinessCategories] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [brandSearchResult, setBrandSearchResult] = useState({});
    let [isAdUrl, setIsAdUrl] = useState(false);
    let [brandSource, setBrandSource] = useState('');
    let [searchString, setSearchString] = useState('');
    let [searchError, setSearchError] = useState(false);
    let [qrOpen, setQrOpen] = useState(false);
    const navigate = useNavigate();
    const customStylesAddBrand = {
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          transform: 'translate(-50%, -50%)',
          background: '#ffffff',
          width: '630px',
          textAlign: 'left',
          height: '650px'
        },
      };
      const [searchBrandModalIsOpen, setSearchBrandModalIsOpen] = React.useState(false);

    
    const getBusinesses = () => {
        (async () => {
            setIsLoading(true);
            var searchVal = document.getElementById('search-cat-bus').value;
            var apiUrl = 'consumer/category'
            if (searchVal !== ''){
                apiUrl += '?search=' + searchVal;
            }
            const response = await api.get(apiUrl, null, {
                headers: {
                  Authorization:  'Bearer ' + await getAccessTokenSilently()
                }});
            if (response.ok && response.body !== null) {
                setbusinessCategories(response.body);
                setIsLoading(false);
            }
            else {
                setbusinessCategories(null);
                setIsLoading(false);
            }
        })();
    };

    const saveBrand = async () => {
        const data = await api.post('interaction/manual', {
            brandId: brandSearchResult.id,
            searchUrl: document.getElementById('search-brand-term').value,
            isAdUrl: isAdUrl,
            source: brandSource
          }, {
            headers: {
              Authorization:  'Bearer ' + await getAccessTokenSilently()
            }});
          if (!data.ok) {
            toast.error('Unable to save ad/brand');
          }
          else {
            navigate('/ads');
          }
        };

    const getBrandSearchResults = () => {
        (async () => {
            var searchVal = document.getElementById('search-brand-term').value;
            var apiUrl = 'business/search'
            if (searchVal !== ''){
                apiUrl += '?searchTerm=' + searchVal;
            }
            const response = await api.get(apiUrl, null, {
                headers: {
                  Authorization:  'Bearer ' + await getAccessTokenSilently()
                }});
            if (response.ok && response.body !== null && response.body.id !== 0) {
                setSearchError(false);
                setBrandSearchResult(response.body);
            }
            else {
                setSearchError(true);
                setBrandSearchResult(response.body);
            }
        })();
    };

    function openAddBrand(){
        setSearchBrandModalIsOpen(true);
    }

    function afterOpenModal() {
        // references are now sync'd and can be accessed.
      }
    
    function closeSearchBrandModal() {
        setSearchBrandModalIsOpen(false);
        setBrandSearchResult({});
        setSearchError(false);
        setQrOpen(false);
    }

    useEffect(() => {
        (async () => {
            await getBusinesses();
        })();
        }, []);

    return(
        <div className='categories-page'>            
            <header className="App-header">
                <NavBar currentPage={'categories'}/>
            </header>
            <Banner isHomePage={false}/>
            <div className='category-wrapper'>
                <div className='business-category-search-wrapper'>
                    <input id='search-cat-bus' className='business-category-search-bar' type='search' placeholder='Businesses Targeting You...'/>
                    <button className='cat-search-btn' onClick={()=>{getBusinesses()}}>Search</button>
                </div>
                <AddNewAdBrandModal/>
                {businessCategories.length <= 0 || businessCategories == null ? isLoading ? <LoadingSpinner/> : <div></div> : isLoading ? <LoadingSpinner/> : businessCategories.map((category, index) => <BusinessCategory key={index} businessCategory={category} adIndex={index}/>)}
            </div>
        </div>
    )
}

export default Businesses;