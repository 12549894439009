import { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { useApi } from '../Contexts/ApiProvider';
import { useAuth0 } from "@auth0/auth0-react";
import ParsalIconLg from '../ParsalIcon64.png';
import { useSearchParams } from 'react-router-dom';
import {toast} from 'react-toastify';
import { faPhone, faHouse, faEnvelope, faCity, faPerson, faLocationPin } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function RegistrationPage() {
  const [formErrors, setFormErrors] = useState({});
  const [searchParams] = useSearchParams();
  let [profileFirstName, setProfileFirstName] = useState('');
  let [profileLastName, setProfileLastName] = useState('');
  let [profileEmail, setProfileEmail] = useState('');
  let [profilePhone, setProfilePhone] = useState('');
  let [profileGender, setProfileGender] = useState('');
  let [profileBirthday, setProfileBirthday] = useState('');
  let [profileState, setProfileState] = useState('');
  let [profileCity, setProfileCity] = useState('');
  let [profileZip, setProfileZip] = useState('');
  let [profileAddressLine1, setProfileAddressLine1] = useState('');
  let [profileAddressLine2, setProfileAddressLine2] = useState('');
  let [profileBio, setProfileBio] = useState('');
  let [profileInterests, setProfileInterests] = useState('');
  let [profileReferralCode, setProfileReferralCode] = useState('');
  const stateOptions = [
    'Alabama', 'Alaska', 'Arizona', 'Arkansas', 'California', 'Colorado', 'Connecticut', 'Delaware', 'Florida', 'Georgia', 'Hawaii', 'Idaho', 'Illinois', 'Indiana',
    'Iowa', 'Kansas', 'Kentucky', 'Louisiana', 'Maine', 'Maryland', 'Massachusetts', 'Michigan', 'Minnesota', 'Mississippi', 'Missouri', 'Montana', 'Nebraska',
    'Nevada', 'New Hampshire', 'New Jersey', 'New Mexico', 'New York', 'North Carolina', 'North Dakota', 'Ohio', 'Oklahoma', 'Oregon', 'Pennsylvania', 'Rhode Island',
    'South Carolina', 'South Dakota', 'Tennessee', 'Texas', 'Utah', 'Vermont', 'Virginia', 'Washington', 'West Virginia', 'Wisconsin', 'Wyoming'
  ];
  const [isExistingProfile, setIsExistingProfile] = useState(false);
  const { user, getAccessTokenSilently } = useAuth0();
  const navigate = useNavigate();
  const api = useApi();

  useEffect(() => {
    setProfileFirstName(user.given_name);
    setProfileLastName(user.family_name);
    setProfileEmail(user.email);
    setProfileReferralCode(searchParams.get('refCode'));
    (async () => {
        var apiUri = 'consumer/' + user.email;
        
        const response = await api.get(apiUri, null, {
            headers: {
              Authorization:  'Bearer ' + await getAccessTokenSilently()
            }});
        if (response.ok) {
            if (response.body.id !== null && response.body.id !== 0){
              navigate('/');
            }
        }
        else {
        }
    })();
    }, []);

  const handleSelectChange = (e) => {
    setProfileState(e.target.value);
  };

  const onSubmit = async (event) => {
    event.preventDefault();
    const data = await api.post('consumer', {
        email: profileEmail,
        firstName: profileFirstName,
        lastName: profileLastName,
        phone: profilePhone,
        addressLine1: profileAddressLine1,
        addressLine2: profileAddressLine2,
        gender: profileGender,
        birthday: profileBirthday,
        state: profileState,
        city: profileCity,
        zip : profileZip,
        bio: profileBio,
        interests: profileInterests,
        referralCode: profileReferralCode
      }, {
        headers: {
          Authorization:  'Bearer ' + await getAccessTokenSilently()
        }});
      if (!data.ok) {
        toast.error("Unable to create profile");
      }
      else {
        navigate('/sync');
      }
    };

  return (
    <div className='registration-content'>
      <div className='registration-content-inner'>
        <div className='registration-content-header'>
          <div className='registration-content-header-logo-container'><img src={ParsalIconLg} className='registration-content-header-logo'/></div>
          <div className='registration-content-header-text'>Create Your Parsal Profile</div>
        </div>
        <Form onSubmit={onSubmit}>
          <Form.Group className="mb-3 form-section" controlId="exampleForm.ControlInput1">
              <Form.Label className="registration-form-section-label">Name</Form.Label>
              <div className='registration-form-section-label-icon'>
                <FontAwesomeIcon icon={faPerson}/>
              </div>
              <Form.Control className='registration-form-input' type="text" value={profileFirstName + ' ' + profileLastName} disabled/>
          </Form.Group>
          <Form.Group className="mb-3 form-section" controlId="exampleForm.ControlTextarea1">
              <Form.Label className="registration-form-section-label">Email</Form.Label>
              <div className='registration-form-section-label-icon'>
                <FontAwesomeIcon icon={faEnvelope}/>
              </div>
              <Form.Control className='registration-form-input' type="email" value={profileEmail} disabled/>
          </Form.Group>
          <Form.Group className="mb-3 form-section" controlId="exampleForm.ControlTextarea1">
              <Form.Label className="registration-form-section-label">Phone (numbers only)</Form.Label>
              <div className='registration-form-section-label-icon'>
                <FontAwesomeIcon icon={faPhone}/>
              </div>
              <Form.Control className='registration-form-input' type="phone" value={profilePhone} onChange={(selectedValue) => {setProfilePhone(selectedValue.target.value)}}/>
          </Form.Group>
          <Form.Group className="mb-3 form-section" controlId="exampleForm.ControlTextarea1">
              <Form.Label className="registration-form-section-label">Address Line 1</Form.Label>
              <div className='registration-form-section-label-icon'>
                <FontAwesomeIcon icon={faHouse}/>
              </div>
              <Form.Control className='registration-form-input' type="text" value={profileAddressLine1} onChange={(selectedValue) => {setProfileAddressLine1(selectedValue.target.value)}}/>
          </Form.Group>
          <Form.Group className="mb-3 form-section" controlId="exampleForm.ControlTextarea1" style={{display: 'none'}}>
              <Form.Label className="registration-form-section-label">Address Line 2</Form.Label>
              <div className='registration-form-section-label-icon'>
                <FontAwesomeIcon icon={faHouse}/>
              </div>
              <Form.Control className='registration-form-input' type="text" value={profileAddressLine2} onChange={(selectedValue) => {setProfileAddressLine2(selectedValue.target.value)}}/>
          </Form.Group>
          <Form.Group className="mb-3 form-section" controlId="exampleForm.ControlTextarea1">
              <Form.Label className="registration-form-section-label">City</Form.Label>
              <div className='registration-form-section-label-icon'>
                <FontAwesomeIcon icon={faCity}/>
              </div>
              <Form.Control className='registration-form-input' type="text" value={profileCity} onChange={(selectedValue) => {setProfileCity(selectedValue.target.value)}}/>
          </Form.Group>
          <Form.Group className="mb-3 form-section">
            <Form.Label className="registration-form-section-label">State</Form.Label>
            <Form.Select className='registration-form-input' value={profileState} onChange={handleSelectChange}>
              <option>Select a State</option>
                {stateOptions.map((state) => (
                  <option value={state}>
                    {state}
                  </option>
                ))}
            </Form.Select>
          </Form.Group>
          <Form.Group className="mb-3 form-section" controlId="exampleForm.ControlTextarea1">
              <Form.Label className="registration-form-section-label">Zip</Form.Label>
              <div className='registration-form-section-label-icon'>
                <FontAwesomeIcon icon={faLocationPin}/>
              </div>
              <Form.Control className='registration-form-input' type="text" value={profileZip} onChange={(selectedValue) => {setProfileZip(selectedValue.target.value)}}/>
          </Form.Group>
          <Form.Group className="mb-3 form-section" controlId="exampleForm.ControlTextarea1" hidden='true'>
              <Form.Label className="registration-form-section-label">Referral Code</Form.Label>
              <Form.Control className='registration-form-input' type="text" value={profileReferralCode}/>
          </Form.Group>
          <div className='profile-registration-btn-wrapper'>
            <Button variant="primary" type="submit" className='profile-registration-btn'>Register</Button>
          </div>
        </Form>
      </div>
    </div>
  );
}