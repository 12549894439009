import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

const Footer = () => {
    const navigate = useNavigate();
    const location = useLocation();

    return (
        <footer className='footer' style={location.pathname === '/ads' || location.pathname === '/brands' || location.pathname === '/purchases' ? {display: 'none'} : {}}>
            <p>
                <span style={{color:'black', textDecoration:'underline', cursor:'pointer'}} onClick={() => {navigate('/termsconditions')}}>Terms and Conditions</span> | <span style={{color:'black', textDecoration:'underline', cursor:'pointer'}} onClick={() => {navigate('/privacy')}}>Privacy Policy</span>
            </p>
        </footer>
    );
}

export default Footer;