import '../App.css';
import Login from './Login';
import Logout from './Logout';
import {Icon} from './NavIcon';

function NavBar(currentPage) {
    return(
        <div>
            <div className='icon-container-main'>
                <Icon iconName = "icon" currentPage={currentPage}/>
                <Icon iconName = "ads" currentPage={currentPage}/>
                <Icon iconName = "search" currentPage={currentPage}/>                
                <Icon iconName = "feedback" currentPage={currentPage}/>
            </div>
            <div className='profile-button-container'>
                <Logout />
            </div>
        </div>
    )
}

export default NavBar;