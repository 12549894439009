import React, { useEffect, useState } from "react";
import '../../LandingPage.css';
import { faUserCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function LandingFooter(leaderboardSection) {
    const [sectionCategory, setSectionCategory] = useState('');
    const [sectionUsers, setSectionUsers] = useState([]);

    useEffect(() => {
            setSectionCategory(leaderboardSection.leaderboardSection.category);
            setSectionUsers(leaderboardSection.leaderboardSection.users);
        });

    return (
        <div className='landing-page-leaderboard-rank-section-wrapper'>
            <div className='leaderboard-rank-title'>
                <span>{sectionCategory}</span>
            </div>
            <div className='leaderboard-rank-section'>
                <div className='leaderboard-rank-tile-second'>
                    <div className='leaderboard-rank-tile-icon-wrapper'>
                        <div className='leaderboard-rank-tile-icon'>
                            <FontAwesomeIcon icon={faUserCircle}/>
                        </div>
                        <div className='leaderboard-rank-tile-name'>
                            <span>{sectionUsers.find((user) => user.position === 2)?.name}</span>
                        </div>
                    </div>
                    <div className='leaderboard-rank-position-bubble'>
                        <span>2</span>
                    </div>
                </div>
                <div className='leaderboard-rank-tile-first'>
                    <div className='leaderboard-rank-tile-icon-wrapper-first'>
                        <div className='leaderboard-rank-tile-icon-first'>
                            <FontAwesomeIcon icon={faUserCircle}/>
                        </div>
                        <div className='leaderboard-rank-tile-name'>
                            <span>{sectionUsers.find((user) => user.position === 1)?.name}</span>
                        </div>
                    </div>
                    <div className='leaderboard-rank-position-bubble-first'>
                        <span>1</span>
                    </div>
                </div>
                <div className='leaderboard-rank-tile-second'>
                    <div className='leaderboard-rank-tile-icon-wrapper'>
                        <div className='leaderboard-rank-tile-icon'>
                            <FontAwesomeIcon icon={faUserCircle}/>
                        </div>
                        <div className='leaderboard-rank-tile-name'>
                            <span>{sectionUsers.find((user) => user.position === 3)?.name}</span>
                        </div>
                    </div>
                    <div className='leaderboard-rank-position-bubble'>
                        <span>3</span>
                    </div>
                </div>
            </div>
        </div>
  );
}