import React from "react"; 
import "../ToggleSwitch.css"; 
  
const ToggleSwitch = ({ label, toggleSelection, preferenceStatus }) => { 
  return ( 
    <div className="container"> 
      {label}{" "} 
      <div className="toggle-switch"> 
        <input type="checkbox" className="checkbox" checked={preferenceStatus}
               name={label} id={label} onChange={(toggle) => toggleSelection(toggle.target.checked)}/> 
        <label className="label" htmlFor={label}> 
          <span className="inner" /> 
          <span className="switch" /> 
        </label> 
      </div> 
    </div> 
  ); 
}; 
  
export default ToggleSwitch;