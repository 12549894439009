import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import {App} from './App';
import { Auth0Provider } from "@auth0/auth0-react";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(

      <Auth0Provider
        domain="auth.parsal.app"
        clientId="SvK5kSBBcNbfUMQStUhjFZwwkIA3OAW5"
        useRefreshTokens={true}
        authorizationParams={{
          redirect_uri: window.location.origin + '/home',
          audience: "https://localhost:44347",
          access_type: "offline",
          scope: "openid profile email offline_access consumerService_Full",
          connection_scope: "https://www.googleapis.com/auth/gmail.readonly",
          approval_prompt: "force"
        }} >
        <App />
      </Auth0Provider>

);
