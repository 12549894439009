import React, { useEffect, useState } from "react";
import { useApi } from '../Contexts/ApiProvider';
import '../App.css';
import { useAuth0 } from "@auth0/auth0-react";
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import {toast} from 'react-toastify';

export default function ProfileSettings() {
  let [userProfile, setUserProfile] = useState({});
  const { user, getAccessTokenSilently } = useAuth0();
  const api = useApi();
  let [profileDemoData, setProfileDemoData] = useState([]);
  let [externalDataRequest, setExternalDataRequest] = useState('NOT RUN');

  const [consumerExternalData, setConsumerExternalData] = useState({
    firstName: user.given_name,
    lastName: user.family_name,
    gender: '',
    address: '',
    city: '',
    state: '',
    postalCode: '',
    addressLine1: '',
    addressLine2: '',
    lengthOfResidency: '',
    headOfHouseHold: false,
    hasChildren: false,
    ethnicity: '',
    educationLevel: '',
    estimatedIncomeRange: '',
    estimatedWealthRange: '',
    age: 0,
    maritalStatus: '',
    homeownerType: '',
    medHomeValue: '',
    ageRange: '',
    dOB: ''
  });

  const getExternalData = () => {
    (async () => {
        var apiUrl = 'consumer/sync';
        const response = await api.get(apiUrl, null, {
            headers: {
              Authorization:  'Bearer ' + await getAccessTokenSilently()
            }});
        if (response.ok && response.body !== null) {
          setExternalDataRequest('MATCH');
          setConsumerExternalData(response.body);
        }
        else {
          setExternalDataRequest('FAILED');
        }
    })();
  };

  const getDemoData = () => {
    (async () => {
      var apiUri = 'consumer/demographicdata';
        
      const response = await api.get(apiUri, null, {
          headers: {
            Authorization:  'Bearer ' + await getAccessTokenSilently()
          }});
      if (response.ok) {
          setProfileDemoData(response.body);
      }
      else {
          setProfileDemoData([]);
      }
    })();
  };

  const onSubmit = async (event) => {
    event.preventDefault();
    const data = await api.post('consumer/demographic', {
        gender: consumerExternalData.gender,
        ethnicity: consumerExternalData.ethnicity,
        educationLevel: consumerExternalData.educationLevel,
        maritalStatus: consumerExternalData.maritalStatus,
        estimatedIncomeRange: consumerExternalData.estimatedIncomeRange,
        estimatedWealthRange: consumerExternalData.estimatedWealthRange,
        homeownerType: consumerExternalData.homeownerType,
        medHomeValue: consumerExternalData.medHomeValue,
        ageRange: consumerExternalData.ageRange,
        age: consumerExternalData.age,
        lengthOfResidency: consumerExternalData.lengthOfResidency,
        headOfHouseHold: consumerExternalData.headOfHouseHold,
        hasChildren: consumerExternalData.hasChildren,
        dob: consumerExternalData.dOB
      }, {
        headers: {
          Authorization:  'Bearer ' + await getAccessTokenSilently()
        }});
      if (!data.ok) {
      }
      else {
        await getDemoData();
      }
    };
  
  useEffect(() => {
    (async () => {
      getDemoData();
    })();
    }, []);

  return (
    <div>
      <h2>Demographic Data</h2>
      <div style={externalDataRequest === 'MATCH' ? {display: 'block', paddingTop: '30px'} : {display: 'none'}}>
              <h2>Tubular! Your data was found. Now you can decide to save it to your profile to increase you chances of being sold as a lead by clicking save below, or you can skip and we will discard this data. You can make this request again from your profile section later.</h2>
            </div>
            <div style={externalDataRequest === 'FAILED' ? {display: 'block', paddingTop: '30px'} : {display: 'none'}}>
              <h2>The good news is there are less people making money off of your data. The bad news, we were not able to find a match with your information to boost your profile. You can update your information and try again from your profile section later.</h2>
            </div>
            <div style={externalDataRequest === 'NOT RUN' ? {display: 'block', paddingTop: '30px'} : {display: 'none'}}>
              <button className='standard-btn' onClick={() => {getExternalData()}}>See Data</button>
            </div>
      <Form style={profileDemoData.length != 0 ? {display: 'block'} : {display: 'none'}}>
        {profileDemoData.map((demoData) => (
        <Form.Group className="mb-3 form-section" controlId="exampleForm.ControlInput1">
            <Form.Label className="form-section-label">{demoData.key}</Form.Label>
            <Form.Control type="text" value={demoData.value}/>
        </Form.Group>))}
        <Button variant="primary" type="submit" className='interested-modal-interested-btn'>
          <span className='feedback-modal-btn-icon'></span>
          <span>Save</span>
        </Button> 
      </Form>
      <Form onSubmit={onSubmit} style={profileDemoData.length == 0 ? {display: 'block'} : {display: 'none'}}>
        <Button variant="primary" className='profile-registration-btn' onClick={() => getExternalData()}>Get Data</Button>
        <Form.Group className="mb-3 form-section" controlId="exampleForm.ControlInput1">
              <Form.Label className="registration-form-section-label">Gender</Form.Label>
              <Form.Control className='registration-form-input' type="text" value={consumerExternalData.gender}/>
          </Form.Group>
          <Form.Group className="mb-3 form-section" controlId="exampleForm.ControlTextarea1">
              <Form.Label className="registration-form-section-label">Age</Form.Label>
              <Form.Control className='registration-form-input' type="text" value={consumerExternalData.age}/>
          </Form.Group>
          <Form.Group className="mb-3 form-section" controlId="exampleForm.ControlTextarea1">
              <Form.Label className="registration-form-section-label">Date of Birth</Form.Label>
              <Form.Control className='registration-form-input' type="text" value={consumerExternalData.dOB}/>
          </Form.Group>
          <Form.Group className="mb-3 form-section" controlId="exampleForm.ControlTextarea1">
              <Form.Label className="registration-form-section-label">Ethnicity</Form.Label>
              <Form.Control className='registration-form-input' type="text" value={consumerExternalData.ethnicity}/>
          </Form.Group>
          <Form.Group className="mb-3 form-section" controlId="exampleForm.ControlTextarea1">
              <Form.Label className="registration-form-section-label">Martial Status</Form.Label>
              <Form.Control className='registration-form-input' type="text" value={consumerExternalData.maritalStatus}/>
          </Form.Group>
          <Form.Group className="mb-3 form-section" controlId="exampleForm.ControlTextarea1">
              <Form.Label className="registration-form-section-label">Homeowner</Form.Label>
              <Form.Control className='registration-form-input' type="text" value={consumerExternalData.homeownerType}/>
          </Form.Group>
          <Form.Group className="mb-3 form-section" controlId="exampleForm.ControlTextarea1">
              <Form.Label className="registration-form-section-label">Length of Residency</Form.Label>
              <Form.Control className='registration-form-input' type="text" value={consumerExternalData.lengthOfResidency}/>
          </Form.Group>
          <Form.Group className="mb-3 form-section" controlId="exampleForm.ControlTextarea1">
              <Form.Label className="registration-form-section-label">Head of Household</Form.Label>
              <Form.Control className='registration-form-input' type="text" value={consumerExternalData.headOfHouseHold ? 'Yes' : 'No'}/>
          </Form.Group>
          <Form.Group className="mb-3 form-section" controlId="exampleForm.ControlTextarea1">
              <Form.Label className="registration-form-section-label">Has Children</Form.Label>
              <Form.Control className='registration-form-input' type="text" value={consumerExternalData.hasChildren ? 'Yes' : 'No'}/>
          </Form.Group>
          <Form.Group className="mb-3 form-section" controlId="exampleForm.ControlTextarea1">
              <Form.Label className="registration-form-section-label">Education Level</Form.Label>
              <Form.Control className='registration-form-input' type="text" value={consumerExternalData.educationLevel}/>
          </Form.Group>
          <Form.Group className="mb-3 form-section" controlId="exampleForm.ControlTextarea1">
              <Form.Label className="registration-form-section-label">Estimated Income</Form.Label>
              <Form.Control className='registration-form-input' type="text" value={consumerExternalData.estimatedIncomeRange}/>
          </Form.Group>
          <Form.Group className="mb-3 form-section" controlId="exampleForm.ControlTextarea1">
              <Form.Label className="registration-form-section-label">Estimated Net Worth</Form.Label>
              <Form.Control className='registration-form-input' type="text" value={consumerExternalData.estimatedWealthRange}/>
          </Form.Group>
          <Button variant="primary" type="submit" className='profile-registration-btn'>Save</Button>
        </Form>
    </div>
  );
}