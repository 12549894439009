import '../App.css';
import {faCommentDots, faChevronCircleLeft, faChevronCircleRight, faThumbsDown, faThumbsUp, faHeart } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useApi } from '../Contexts/ApiProvider';
import React, { useState, useRef} from 'react';
import {useCollapse} from 'react-collapsed';
import { useAuth0 } from "@auth0/auth0-react";
import Form from 'react-bootstrap/Form';
import Modal from 'react-modal';
import Button from 'react-bootstrap/Button';

function BrandReview({userBrand, brandIndex, onReviewSubmission, currentIndex, navToNextBrand, navToPrevBrand, hasNext, hasPrev}) {
    const doesNotLikeField = useRef(false);
    const doesLikeField = useRef(false);
    const doesSuperLikeField = useRef(false);
    const feedbackNotesField = useRef('');
    const api = useApi();
    const { user, getAccessTokenSilently } = useAuth0();
    const { getCollapseProps, getToggleProps } = useCollapse();
    const [feedbackModalIsOpen, setFeedbackModalIsOpen] = React.useState(false);
    let [feedbackNotes, setFeedbackNotes] = useState('');
    const customStylesFeedback = {
        content: {
          top: '20%',
          left: '35%',
          right: 'auto',
          bottom: 'auto',
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          width: '30%',
          textAlign: 'center',
          height: '65%'
        },
      };
  
    async function SaveFeedback(feedbackValue) {
        
        const data = await api.post('interaction/feedback/brand', {
            businessId: userBrand.id,
            email: user.email,
            doesNotLike: feedbackValue === 'NotPreferred' ? true : false,
            doesLike: feedbackValue === 'Preferred' ? true : false,
            doesSuperLike: feedbackValue === 'superLike' ? true : false,
            feedbackNotes: feedbackNotes
          }, {
            headers: {
              Authorization:  'Bearer ' + await getAccessTokenSilently()
            }});
          if (!data.ok) {
            
          }
          else {            
            onReviewSubmission(userBrand.id);
          }
        };

    function openFeedbackModal() {setFeedbackModalIsOpen(true);}
    function closeFeedbackModal() {setFeedbackModalIsOpen(false);}
    function afterOpenModal() {
        // references are now sync'd and can be accessed.
      }

    const onSubmit = async (event) => {
        event.preventDefault();
        const data = await api.post('interaction/feedback/brand', {
            businessId: userBrand.id,
            email: user.email,
            doesNotLike: doesNotLikeField.current.checked,
            doesLike: doesLikeField.current.checked,
            doesSuperLike: doesSuperLikeField.current.checked,
            feedbackNotes: feedbackNotesField.current.value
          }, {
            headers: {
              Authorization:  'Bearer ' + await getAccessTokenSilently()
            }});
          if (!data.ok) {
            
          }
          else {            
            onReviewSubmission(userBrand.id);
          }
        };

    return (
    <div>
        <div>
        <Modal
            isOpen={feedbackModalIsOpen}
            onAfterOpen={afterOpenModal}
            onRequestClose={closeFeedbackModal}
            style={customStylesFeedback}
            contentLabel="Example Modal"
            id='provide-feedback-modal'>
            <Form>
                <Form.Group className="mb-3 form-section" controlId="exampleForm.ControlInput1">
                  <Form.Label className="form-section-label"></Form.Label>
                  <textarea className='review-feedback-txt-area' placeholder='Feedback' value={feedbackNotes} onChange={(feedbackNotesValue) => {setFeedbackNotes(feedbackNotesValue.target.value)}}/>
                </Form.Group>
                <Button variant="primary" type="submit" className='send-feedback-modal-btn' onClick={() => {closeFeedbackModal()}}>
                    <span>Save Feedback</span>
                </Button>               
            </Form>
        </Modal>
    </div> 
    <div className="ad-main-container"  style={brandIndex === currentIndex ? {display: 'block'} : {display: 'none'}}>
        <div className='brand-feedback-super-like-btn-wrapper'>
            <button className='brand-feedback-super-like-btn'><FontAwesomeIcon icon={faHeart} onClick={() => { SaveFeedback('superLike')}}/></button>
            <div>Favorite</div>
        </div>
        <div className='brand-feedback-content-wrapper'>
            <div className='brand-feedback-content-logo-wrapper'>
                <img src={userBrand.businessImageUrl} className='brand-feedback-content-logo'/>
                <div className='brand-feedback-content-company-name'>{userBrand.name}</div>
            </div>            
        </div>
        <div className="ad-container-inner" id="carouselExampleIndicators">
            <div id="ad-carousel-main">
                <div className="ad-tile-overlay collapsible">
                    <div className="ad-tile-overlay-line header" {...getToggleProps()}></div>
                    <div {...getCollapseProps()}>
                        <div className="content">
                          <div className='overlay-expanded-text-business-name'>
                            <span>{userBrand.name}</span>
                          </div>
                          <div>
                              <span>{userBrand.baseCategory}</span>
                          </div>
                        </div>
                      </div> 
                    <div className="overlay-feedback-btn-row">
                        <button className='overlay-feedback-btn-arrow' style={hasPrev ? {} : {display: 'none'}} onClick={()=>{navToPrevBrand(currentIndex - 1)}}>
                            <FontAwesomeIcon icon={faChevronCircleLeft} className='overlay-feedback-btn-arrow-icon'/>
                        </button>
                        <button onClick={() => {SaveFeedback('NotPreferred')}} className="overlay-feedback-btn">
                            <span style={{ fontSize: '30px'}}><FontAwesomeIcon icon={faThumbsDown}/></span>
                            <span style={{ display: 'block' }}>Not Preferred</span>
                        </button>
                        <button onClick={openFeedbackModal} className="overlay-feedback-middle">
                            <span style={{ fontSize: '30px'}}><FontAwesomeIcon icon={faCommentDots}/></span>
                        </button>
                        <button onClick={() => {SaveFeedback('Preferred')}} className="overlay-feedback-btn-interested">
                            <span style={{ fontSize: '30px'}}><FontAwesomeIcon icon={faThumbsUp}/></span>
                            <span style={{ display: 'block' }}>Preferred</span>
                        </button>
                        <button className='overlay-feedback-btn-arrow' style={hasNext ? {left: '15px'} : {display: 'none'}} onClick={()=>{navToNextBrand(currentIndex + 1)}}>
                            <FontAwesomeIcon icon={faChevronCircleRight} className='overlay-feedback-btn-arrow-icon'/>
                        </button>
                    </div>            
                </div>
            </div>
        </div>
    </div>
    </div>
    );
  }

export default BrandReview;