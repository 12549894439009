import '../LandingPage.css';
import LandingNav from '../Components/LandingPage/LandingNav';
import LandingFooter from '../Components/LandingPage/LandingFooter';
import { faChevronCircleDown, faChevronCircleUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { useApi } from '../Contexts/ApiProvider';
import LoadingSpinner from '../Components/loadingSpinner';
import LandingLeaderSection from '../Components/LandingPage/LandingLeaderSection';
import AdSense from '../Components/AdSense';
import LeaaderboardTermsConditionsModal from '../Components/LandingPage/LeaderboardTermsConditionsModal';

function LandingLeaderboard() {
    let [leaderboards, setLeaderboards] = useState([]);
    const api = useApi();
    const [isLoading, setIsLoading] = useState(true);
    const [isBannerExpanded, setIsBannerExpanded] = useState(true);
  
    useEffect(() => {
      (async () => {
          var apiUri = 'interaction/leaderboard';
          
          const response = await api.get(apiUri, null, {});
          if (response.ok) {
              setLeaderboards(response.body);
              setIsLoading(false);
          }
          else {
            setIsLoading(false);
          }
      })();
      }, []);

    return(
        <div className='landing-page-wrapper'>
            <LandingNav />
            <div className='landing-page-leaderboard-banner-wrapper'>
                {/*<AdSense dataAdSlot='2013861535' />*/}
                <div className='landing-page-leaderboard-banner-content'>
                    <div className='landing-page-leaderboard-banner-top'>
                        <p className='leaderboard-banner-top-text'>Join now and earn</p>
                        <p className='leaderboard-banner-top-text-bold'>money while we grow together</p>
                    </div>
                    <div className='landing-page-leaderboard-banner-middle' style={isBannerExpanded ? {display:'block'} : {display:'none'}}>
                        <span>Parsal is currently in our Open Beta, as we grow we want to make sure to share in the opportunity we have to rebuild
                             the data marketplace together. For now, we will be paying out to users based on the below leaderboard
                               (you can find this in your profile page too!). Each month, the top 3 profiles in each category will earn a payout of
                                between $25 to $100. Click <LeaaderboardTermsConditionsModal/> for terms and conditions.</span>
                    </div>
                    <div className='landing-page-leaderboard-banner-bottom' style={isBannerExpanded ? {display:'block'} : {display:'none'}}>
                        <span>Let's get Parsal-ing!</span>
                    </div>
                    <div className='landing-page-leaderboard-banner-arrow-wrapper'>
                        <span style={isBannerExpanded ? {display: 'none'} : {display: 'block'}} onClick={() => (setIsBannerExpanded(true))}>
                            <FontAwesomeIcon icon={faChevronCircleDown}/>
                        </span>
                        <span style={isBannerExpanded ? {display: 'block'} : {display: 'none'}} onClick={() => (setIsBannerExpanded(false))}>
                            <FontAwesomeIcon icon={faChevronCircleUp}/>
                        </span>
                    </div>
                </div>
            </div>
            <div className='landing-page-leaderboard-ranking-wrapper'>
                <div className='landing-page-leaderboard-ranking-content'>
                    <div style={isLoading ? {display: 'block'} : {display: 'none'}}>
                        <LoadingSpinner/>
                    </div>
                    <div style={isLoading ? {display: 'none'} : {display: 'block'}}>
                        {leaderboards.map((leaderboard) => (
                            <LandingLeaderSection leaderboardSection={leaderboard} />
                        ))}
                    </div>
                </div>
            </div>
            <LandingFooter/>
        </div>
    )
}

export default LandingLeaderboard;