import React from "react";
import '../App.css';

export default function DefaultAdvertisementContent({adBusiness, reviewLink}) {
  return (
    <div style={{marginTop: '100px'}}>
        <div>
            {adBusiness != undefined ? adBusiness.name : <h2>Business</h2>}
        </div>
        <div style={{marginTop: '20px'}}>
            {adBusiness != undefined ? <img src={adBusiness.businessImageUrl} alt="business image"/> : <span></span>}
        </div>
        <div style={{marginTop: '20px', fontSize: '26px'}}>
            {reviewLink != undefined ? <span onClick={() => (window.open(reviewLink))} style={{color: 'black!important', textDecoration: 'underline'}}>Website</span> : <span></span>}
        </div>
    </div>
  );
}