import React, { useEffect, useState } from "react";
import { useApi } from '../Contexts/ApiProvider';
import '../App.css';
import LoadingSpinner from "./loadingSpinner";

export default function ProfileBanking({user, userLeaderboardName}) {
  let [leaderboards, setLeaderboards] = useState([]);
  const api = useApi();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    (async () => {
        var apiUri = 'interaction/leaderboard';
        
        const response = await api.get(apiUri, null, {});
        if (response.ok) {
            setLeaderboards(response.body);
            setIsLoading(false);
        }
        else {
          setIsLoading(false);
        }
    })();
    }, []);

  return (
    <div className='profile-content-main-wrapper'>
        <div>
            <div>
                <h1>Leaderboard</h1>
                <div style={isLoading ? {display: 'block'} : {display: 'none'}}>
                  <LoadingSpinner/>
                </div>
                <div style={isLoading ? {display: 'none'} : {display: 'block'}}>
                  <div style={leaderboards.length <= 0 ? {display: 'block'} : {display: 'none'}}>
                    <h2>Unable to load</h2>
                  </div>
                  {
                    leaderboards.map((leaderboard) => (
                      <div>
                        <h2>{leaderboard.category}</h2>
                        <table>
                          <tr>
                            <th>Pos.</th>
                            <th>Name</th>
                            <th>Count</th>
                          </tr>
                          {leaderboard.users.map((user) => (
                          <tr>
                            <td>{user.position}</td>
                            <td style={user.name === userLeaderboardName ? {color: '#ffbf46', fontWeight: 'bold'} : {}}>{user.name}</td>
                            <td>{user.count}</td>
                          </tr>
                        ))}
                        </table>
                      </div>
                    ))
                  }
                </div>
            </div>
        </div>
    </div>
  );
}