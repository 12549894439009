import '../LandingPage.css';
import LandingNav from '../Components/LandingPage/LandingNav';
import LandingFooter from '../Components/LandingPage/LandingFooter';
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { useApi } from '../Contexts/ApiProvider';
import AdMovie from '../AdMovie.jpg';
import { useNavigate } from 'react-router-dom';
import AdSense from '../Components/AdSense';

function LandingAboutTellMe() {
    const api = useApi();
    const [isLoading, setIsLoading] = useState(true);
    const navigate = useNavigate();

    return(
        <div className='landing-page-wrapper'>
            <LandingNav />
            <div style={{height: '50px'}}></div>
            <div className='landing-about-blog-content-wrapper'>
                {/*<AdSense dataAdSlot='2013861535' />*/}           
                <div className='landing-about-blog-articles-wrapper'>
                    <div className='landing-about-blog-article'>
                        <div className='landing-about-blog-back-btn' onClick={() => (navigate('/about'))}>
                            <FontAwesomeIcon icon={faChevronLeft}/><span className='landing-about-blog-back-text'>Go Back</span>
                        </div>
                        <div className='landing-about-blog-article-title'>
                            <p>Why Don't You Just Tell Me the Movie You Want to See?</p>
                            <p className='landing-about-blog-article-author'>Written by The Parsal Team</p>
                        </div>
                        <div className='landing-about-blog-article-image'>
                            <img src={AdMovie} alt='adMovie'/>
                        </div>
                        <div className='landing-about-blog-article-body'>
                            <p>A line made famous by a hilarious yet eclectic 90's sitcom neighbor. As the character asks phone callers to click the number for the movie they want to see, 
                                he awkwardly realizes he is not capable of interpreting the input, then asks "Why dont you just tell me the movie you want to see?". For years, advertisers and advertising platforms have attempted to interpret your web browsing behavor
                                in order to anticipate what products you might be interested in and when you might be interested in them. Brands spending big bucks to show you gym gear because your phone thought it heard you say "Exercise" when what you actually said
                                 was "Extra Fries".</p>
                            <div>
                                <p>Ever clicked on a link to a product you were vaugley interested in or curious about,
                                     just to be constantly spammed by that product's ads for the next month on every media platform? Advertisers and advertising platforms are constantly trying to anticipate consumer wants, to an annoying degree. 
                                     Yes, techincally I did click a link to the male enhancement pill website, but it was just a brief moment of self doubt and weakness, I am not actually going to buy them! Or better yet, someone just mentions a product while
                                      you are talking near your phone and all of the sudden all you see are ads for that product (How do they do that right?!).</p>

                                <p>"In the name of personalized experience!" is the zealous almost cultish decree of all major advertisement and data companies. Allow us to track your every move so that we can customize your advertisement content without any real context. Games, applications, internet providers, search engines, credit card companies, 
                                    and potentially worst of all cell phone providers. A couple of years ago, Verizon rolled out a new program that auto-opted in all of it's customers to a custom experience program. This program tracked certain activity in order to profile, package, and ultimately profit off of. In some cases customers reported 
                                    being opted into the custom experience plus which was even more invasive - like this person reported <a href='https://www.reddit.com/r/verizon/comments/r6i2c9/custom_experience_program/'>here</a>. These kinds of things are happening every where and most of the time without the consumers knowledge. Some of the time, 
                                    there is consumer conset that gets burried in some terms and conditions 15 paragraphs down - everyone reads those though right?
                                </p>
                                
                                <p>With advancements in artificial intellegence, big brands are investing millions and millions into technology that will interpret your every action in order to programatically project your next move. They want to train AI models to draw analytical conclusions about your 
                                    feelings towards certain products or brands - side note... am I the only one tired of seeing "AI" in everything? I am all for the advancement of technology to better our lives but come on. We all realize that AI is being slapped on 
                                    everything because it is a buzz word right? Okay back to my point - Wouldn't it be easier to simply ask consumers these questions? As impressive as the technology is, if the intent is to better my experience, isn't the best source of that feedback the consumer themselves?
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <LandingFooter/>
        </div>
    )
}

export default LandingAboutTellMe;