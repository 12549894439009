import React, { useState} from 'react';
import ProfileOverview from '../Components/ProfileOverview';
import ProfilePreferences from '../Components/ProfilePreferences';
import ProfileBadges from '../Components/ProfileBadges';
import ProfileLikes from '../Components/ProfileLikes';
import ProfileSettings from '../Components/ProfileSettings';
import LinkedAccounts from '../Components/LinkedAccounts';
import ActivelyShopping from '../Components/ActivelyShopping';
import ProfileActivity from './ProfileActivity';
import SocialShopping from './SocialShopping';
import '../App.css';

export default function ProfileSection(user) {
    let [isOverview, setIsOverview] = useState(true);
    let [isPreferences, setIsPreferences] = useState(false);
    let [isActivelyShopping, setIsActivelyShopping] = useState(false);
    let [isLikes, setIsLikes] = useState(false);
    let [isLinkedAccounts, setIsLinkedAccounts] = useState(false);
    let [isBadges, setIsBadges] = useState(false);
    let [isSettings, setIsSettings] = useState(false);
    let [isSocialShopping, setIsSocialShopping] = useState(false);

    function NavToSection(sectionName){
        switch(sectionName){
            case 'isOverview':
                setIsOverview(true);
                setIsPreferences(false);
                setIsActivelyShopping(false);
                setIsLikes(false);
                setIsLinkedAccounts(false);
                setIsBadges(false);
                setIsSettings(false);
                setIsSocialShopping(false);
            break;
            case 'isPreferences':
                setIsPreferences(true);
                setIsOverview(false);
                setIsActivelyShopping(false);
                setIsLikes(false);
                setIsLinkedAccounts(false);
                setIsBadges(false);
                setIsSettings(false);
                setIsSocialShopping(false);
            break;
            case 'isActivelyShopping':
                setIsActivelyShopping(true);
                setIsOverview(false);
                setIsPreferences(false);
                setIsLikes(false);
                setIsLinkedAccounts(false);
                setIsBadges(false);
                setIsSettings(false);
                setIsSocialShopping(false);
            break;
            case 'isLikes':
                setIsLikes(true);
                setIsOverview(false);
                setIsPreferences(false);
                setIsActivelyShopping(false);
                setIsLinkedAccounts(false);
                setIsBadges(false);
                setIsSettings(false);
                setIsSocialShopping(false);
            break;
            case 'isLinkedAccounts':
                setIsLinkedAccounts(true);
                setIsOverview(false);
                setIsPreferences(false);
                setIsActivelyShopping(false);
                setIsLikes(false);
                setIsBadges(false);
                setIsSettings(false);
                setIsSocialShopping(false);
            break;
            case 'isBadges':
                setIsBadges(true);
                setIsOverview(false);
                setIsPreferences(false);
                setIsActivelyShopping(false);
                setIsLikes(false);
                setIsLinkedAccounts(false);
                setIsSettings(false);
                setIsSocialShopping(false);
            break;
            case 'isSettings':
                setIsSettings(true);
                setIsOverview(false);
                setIsPreferences(false);
                setIsActivelyShopping(false);
                setIsLikes(false);
                setIsLinkedAccounts(false);
                setIsBadges(false);
                setIsSocialShopping(false);
            break;
            case 'isSocialShopping':
                setIsSettings(false);
                setIsOverview(false);
                setIsPreferences(false);
                setIsActivelyShopping(false);
                setIsLikes(false);
                setIsLinkedAccounts(false);
                setIsBadges(false);
                setIsSocialShopping(true);
            break;
            default:
                setIsOverview(true);
                setIsPreferences(false);
                setIsActivelyShopping(false);
                setIsLikes(false);
                setIsLinkedAccounts(false);
                setIsBadges(false);
                setIsSettings(false);
                setIsSocialShopping(false);
        }
    }

  return (
    <div className='profile-content-main-wrapper'>
        <div className='profile-content-main-nav-btn-area-wrapper'>
            <div className='profile-content-main-nav-btn-wrapper'>
                <button className={isOverview ? 'profile-content-main-nav-btn-active' : 'profile-content-main-nav-btn'} onClick={() => {NavToSection("isOverview")}}>Overview</button>
            </div>
            <div className='profile-content-main-nav-btn-wrapper'>
                <button className={isPreferences ? 'profile-content-main-nav-btn-active' : 'profile-content-main-nav-btn'} onClick={() => NavToSection("isPreferences")}>Preferences</button>
            </div>
            <div className='profile-content-main-nav-btn-wrapper'>
                <button className={isActivelyShopping ? 'profile-content-main-nav-btn-active' : 'profile-content-main-nav-btn'} onClick={() => NavToSection("isActivelyShopping")}>Activity</button>
            </div>
            <div className='profile-content-main-nav-btn-wrapper'>
                <button className={isLikes ? 'profile-content-main-nav-btn-active' : 'profile-content-main-nav-btn'} onClick={() => NavToSection("isLikes")}>Likes & Dislikes</button>
            </div>
            <div className='profile-content-main-nav-btn-wrapper'>
                <button className={isLinkedAccounts ? 'profile-content-main-nav-btn-active' : 'profile-content-main-nav-btn'} onClick={() => NavToSection("isLinkedAccounts")}>Data Sources</button>
            </div>
            <div className='profile-content-main-nav-btn-wrapper'>
                <button className={isBadges ? 'profile-content-main-nav-btn-active' : 'profile-content-main-nav-btn'} onClick={() => NavToSection("isBadges")}>Interests</button>
            </div>
            <div className='profile-content-main-nav-btn-wrapper'>
                <button className={isSettings ? 'profile-content-main-nav-btn-active' : 'profile-content-main-nav-btn'} onClick={() => NavToSection("isSettings")}>Demographics</button>
            </div>
            <div className='profile-content-main-nav-btn-wrapper'>
                <button className={isSocialShopping ? 'profile-content-main-nav-btn-active' : 'profile-content-main-nav-btn'} onClick={() => NavToSection("isSocialShopping")}>Social Shopping</button>
            </div>
        </div>
        <div className='profile-content-main-area-wrapper'>
            <div style={isOverview ? {display: 'block'} : {display: 'none'}}>
                <ProfileOverview/>
            </div>
            <div style={isPreferences ? {display: 'block'} : {display: 'none'}}>
                <ProfilePreferences/>
            </div>
            <div style={isActivelyShopping ? {display: 'block'} : {display: 'none'}}>
                <ProfileActivity/>
            </div>
            <div style={isLikes ? {display: 'block'} : {display: 'none'}}>
                <ProfileLikes user={user}/>
            </div>
            <div style={isLinkedAccounts ? {display: 'block'} : {display: 'none'}}>
                <LinkedAccounts user={user} />
            </div>
            <div style={isBadges ? {display: 'block'} : {display: 'none'}}>
                <ProfileBadges user={user}/>
            </div>
            <div style={isSettings ? {display: 'block'} : {display: 'none'}}>
                <ProfileSettings user={user}/>
            </div>
            <div style={isSocialShopping ? {display: 'block'} : {display: 'none'}}>
                <SocialShopping user={user}/>
            </div>
        </div>
    </div>
  );
}