import { React, useState} from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { faUsers } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ProfilePopup from "./ProfilePopup";
import { useNavigate } from 'react-router-dom';

const LogoutButton = () => {
  const [isProfileOpen, setIsProfileOpen] = useState(false);
  const navigate = useNavigate();

  return (
    <div>
      <button className='profile-button' onClick={()=>{navigate('/profile')}}>
        <FontAwesomeIcon icon={faUsers} />
      </button>
      <div style={!isProfileOpen ? {display: 'none'}:{}}>
        <ProfilePopup/>
      </div>
    </div>
    /*<button className='profile-button' onClick={() => logout({ logoutParams: { returnTo: window.location.origin + '/landing' } })}>
      <FontAwesomeIcon icon={faUsers} />
    </button>*/
  );
};

export default LogoutButton;