import '../App.css';
import NavBar from '../Components/NavBar';
import Banner from '../Components/Banner';
import PurchaseReview from '../Components/PurchaseReview';
import NoAdvertisements from '../Components/NoAdvertisements';
import { useApi } from '../Contexts/ApiProvider';
import { React, useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import LoadingSpinner from '../Components/loadingSpinner';
import { useSearchParams } from 'react-router-dom';

function Purchases() {
    const api = useApi();
    const { user, getAccessTokenSilently } = useAuth0();
    let [currentIndexVal, setCurrentIndexVal] = useState(0);
    let [userPurchases, setUserPurchases] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [searchParams] = useSearchParams();

    useEffect(() => {
        (async () => {
            setIsLoading(true);
            var apiUri = '';
            if(searchParams.get('businessID') != null){
                apiUri = 'advertisement/business/' + searchParams.get('businessID') + '/consumer';
            }
            else{
                apiUri = 'purchase?unreviewed=true';
            }
            const response = await api.get(apiUri, null, {
                headers: {
                  Authorization:  'Bearer ' + await getAccessTokenSilently()
                }});
            if (response.ok) {
                setUserPurchases(response.body);
                setIsLoading(false);
            }
            else {
                setIsLoading(false);
            }
        })();
        }, []);

    return(
        <div className='ads-page'>
            <header className="App-header">
                <NavBar currentPage={'ads'}/>
            </header>
            <Banner isHomePage={false}/>
            <div className='ad-page-sub-banner'>More ad categories... { userPurchases.length <= 0 || userPurchases == null ? '' : isLoading ? '' : ''}</div>
            <div className='ad-wrapper-container'>
                <div className='ad-wrapper'>
                    { userPurchases.length <= 0 || userPurchases == null ? isLoading ? <LoadingSpinner/> : <NoAdvertisements pageName='purchases'/> : isLoading ? <LoadingSpinner/> : 
                                    userPurchases.map((purchaseToReview, index) => (<PurchaseReview key={purchaseToReview.id} userPurchase={purchaseToReview} purchaseIndex={index} currentIndex={currentIndexVal} hasNext={purchaseToReview.length > currentIndexVal + 1 ? true : false} hasPrev={currentIndexVal == 0 ? false : true}
                                        onReviewSubmission={purchaseId => {
                                        setUserPurchases(userPurchases.filter(userPurchase => userPurchase.id !== purchaseId));
                                        if(currentIndexVal + 1 >= userPurchases.length) {setCurrentIndexVal(0)};
                                    }}
                                        navToNextBrand={navToBrand => {setCurrentIndexVal(navToBrand)}}
                                        navToPrevBrand={navToBrand => {setCurrentIndexVal(navToBrand)}}
                                    />))}
                </div>
            </div>
            <div className='ad-page-sub-banner-bottom'>More ads by... { userPurchases.length <= 0 || userPurchases == null ? '' : isLoading ? '' : userPurchases[0].name}</div>
        </div>
    )
}

export default Purchases;