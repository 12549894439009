import '../App.css';
import {Icon} from '../Components/NavIcon';
import { useApi } from '../Contexts/ApiProvider';
import { useFilePicker } from 'use-file-picker';
import Button from 'react-bootstrap/Button';
import { useNavigate } from 'react-router-dom';
import { React, useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import LoadingSpinner from '../Components/loadingSpinner';
import { faXmarkCircle, faInfoCircle} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Instagram1 from '../Instagram1.png';
import Twitter1 from '../Twitter1.png';
import Youtube1 from '../Youtube1.png';
import {ToastContainer, toast} from 'react-toastify';

function SocialConnections() {
    const navigate = useNavigate();
    const api = useApi();
    const [isLoading, setIsLoading] = useState(false);
    const { user, getAccessTokenSilently } = useAuth0();
    const [isFacebookGuideOpen, setIsFacebookGuideOpen] = useState(false);
    const [isAmazonGuideOpen, setIsAmazonGuideOpen] = useState(false);
    const [isGmailGuideOpen, setIsGmailGuideOpen] = useState(false);
    const [isInstagramGuideOpen, setIsInstagramGuideOpen] = useState(false);
    const [isYoutubeGuideOpen, setIsYoutubeGuideOpen] = useState(false);
    const [isTwitterGuideOpen, setIsTwitterGuideOpen] = useState(false);
    const [userLastDates, setUserLastDates] = useState({
        googleUploadDate: "",
        facebookUploadDate: "",
        amazonUploadDate: ""
    });

    const getUserId = () => {
        (async () => {
        const response = await api.get('consumer/email', null, {
            headers: {
              Authorization:  'Bearer ' + await getAccessTokenSilently()
            }});
            if (response.ok) {
            }
            else {
            }
        })();
    };

    const [openFileSelector] = useFilePicker({
        onFilesSelected: ({ filesContent }) => {
            setIsLoading(true);
            {filesContent.map(
                async(file, index) => {
                if(file.name.includes('interacted')) {
                    var historyObj = JSON.parse(file.content);
                    const data = await api.post('interaction/facebookads', {
                        history_v2: historyObj.history_v2
                      }, {
                        headers: {
                          Authorization:  'Bearer ' + await getAccessTokenSilently()
                        }});
                    if (!data.ok || data.body.responseMessage === 'Failed') {
                        setIsLoading(false);
                        toast.error('Unable to import Facebook details');
                    }
                    else {
                        setIsLoading(false);
                        toast('Facebook details imported (' + data.body.successfulUploadCount + ')');
                    }
                }
                else {
                    var requestVal = file.content.replaceAll("\n", ",");
                    requestVal = requestVal.replaceAll("\"", "");
                    const data = await api.post('interaction/amazonbusinesses' , {
                        businessName: requestVal
                      }, {
                        headers: {
                          Authorization:  'Bearer ' + await getAccessTokenSilently()
                        }});
                    if (!data.ok || data.body.responseMessage === 'Failed') {
                        setIsLoading(false);
                        toast('Unable to import Amazon details');
                    }
                    else {
                        setIsLoading(false);
                        toast('Amazon details imported (' + data.body.successfulUploadCount + ')');
                    }
                }
            })};
          }
      });

      {/*useEffect(() => {
          (async () => {
              await getUserId();
              const response = await api.get('interaction/lastupload/consumer/' + user.email, null, {
                headers: {
                  Authorization:  'Bearer ' + await getAccessTokenSilently()
                }});
              if (response.ok) {
                setUserLastDates(response.body);
              }
              else {
                setUserLastDates(null);
              }
          })();
          }, []);*/}

    const getFacebook = async () => {
        await openFileSelector();        
    }

    const navToPage = () => {
        navigate('/getstarted');
    }

    const getGmail = async () => {
        (async () => {
            setIsLoading(true);
            const response = await api.get('Interaction/Gmailads' + '?emailLimit=15', null, {
                headers: {
                  Authorization:  'Bearer ' + await getAccessTokenSilently()
                }});
                if (response.ok && response.body.responseMessage !== 'Failed') {
                    setIsLoading(false);
                    toast('Gmail details imported (' + response.body.successfulUploadCount + ')');
                }
                else {
                    setIsLoading(false);
                    toast.error('Unable to import Gmail details');
                }
            })();
    };

    return(
        <div className='social-page'>
            <div className='social-spinner-container' style={isLoading ? {display: 'block'} : {display: 'none'}}>
                <LoadingSpinner/>
            </div>
            <div style={isLoading ? {display: 'none'} : {display: 'block'}}>
                <div className='facebook-guide-wrapper' style={!isFacebookGuideOpen ? {display: 'none'}:{}}>
                    <div className='facebook-guide-inner'>
{/*                        <Carousel>
                            <div>
                                <img src={facebookImg1} alt='facebook1' className='facebook-guide-img'/>
                            </div>
                            <div>
                                <img src={facebookImg2} alt='facebook2' className='facebook-guide-img'/>
                            </div>
                            <div>
                                <img src={facebookImg3} alt='facebook3' className='facebook-guide-img'/>
                            </div>
                            <div>
                                <img src={facebookImg4} alt='facebook4' className='facebook-guide-img'/>
                            </div>
                            <div>
                                <img src={facebookImg5} alt='facebook5' className='facebook-guide-img'/>
                            </div>
                            <div>
                                <img src={facebookImg6} alt='facebook6' className='facebook-guide-img'/>
                            </div>
                        </Carousel> */}
                        <div className='close-facebook-instructions' onClick={()=>{setIsFacebookGuideOpen(!isFacebookGuideOpen)}} style={{display: 'none'}}><FontAwesomeIcon icon={faXmarkCircle}/></div>
                        {/*<img src={facebookImg7} alt='facebook7' className='facebook-guide-img'/>*/}
                        <div className='social-guid-inner-wrapper'>
                            <h3>Download advertisers targeting you on Facebook</h3>
                            <ul>                                
                                <li>Get a kickstart to your profile building by downloading advertisers targeting you on Facebook, just follow these <a href='https://parsalgeneralstorage.blob.core.windows.net/generalresources/Parsal_Facebook_Advertisor_Import_Steps.pdf' target='_blank'>steps</a>!</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className='facebook-guide-wrapper' style={!isAmazonGuideOpen ? {display: 'none'}:{}}>
                    <div className='facebook-guide-inner'>
                        <div className='close-facebook-instructions' onClick={()=>{setIsAmazonGuideOpen(!isAmazonGuideOpen)}} style={{display: 'none'}}><FontAwesomeIcon icon={faXmarkCircle}/></div>
                        <div className='social-guid-inner-wrapper'>
                            <h3>Download businesses targeting you on Amazon</h3>
                            <ul>
                                <li>Discover businesses targeting you based on shopping behavior on Amazon, and let them know what you think! Just follow these <a href='https://parsalgeneralstorage.blob.core.windows.net/generalresources/Parsal_Amazon_Advertisor_Import_Steps.pdf' target='_blank'>steps</a>!</li>
                            </ul>
                        </div>                     
                        {/*<Carousel>
                            <div>
                                <ul>
                                    <li>Go to the data request page on Amazon: <a href='https://www.amazon.com/hz/privacy-central/data-requests/preview.html' target='_blank'>Amazon data request link</a></li>
                                    <li>Select <i>"Advertisements"</i> from the dropdown</li>
                                    <li>Click the <i>"Submit Request"</i> button</li>
                                    <li>A message indicating that a confirmation email has been sent will be displayed</li>
                                    <li>Once the email is received, click the <i>"Confirm Data Request"</i> button</li>
                                    <li>A confirmation message will be displayed indicating the request is being processed</li>
                                </ul>
                            </div>
                            <div>
                                <img src={Amazon1} alt='Amazon1' className='facebook-guide-img'/>
                            </div>
                            <div>
                                <img src={Amazon3} alt='Amazon3' className='facebook-guide-img'/>
                            </div>
                            <div>
                                <img src={Amazon4} alt='Amazon4' className='facebook-guide-img'/>
                            </div>
                            <div>
                                <img src={Amazon4} alt='Amazon4' className='facebook-guide-img'/>
                            </div>
                    </Carousel>*/}
                    </div>
                </div>
                <div className='facebook-guide-wrapper' style={!isInstagramGuideOpen ? {display: 'none'}:{}}>
                    <div className='facebook-guide-inner'>
                        <div className='close-facebook-instructions' onClick={()=>{setIsInstagramGuideOpen(!isInstagramGuideOpen)}}><FontAwesomeIcon icon={faXmarkCircle}/></div>
                        <img src={Instagram1} alt='Instagram1' className='facebook-guide-img'/>
                    </div>
                </div>
                <div className='facebook-guide-wrapper' style={!isYoutubeGuideOpen ? {display: 'none'}:{}}>
                    <div className='facebook-guide-inner'>
                        <div className='close-facebook-instructions' onClick={()=>{setIsYoutubeGuideOpen(!isYoutubeGuideOpen)}}><FontAwesomeIcon icon={faXmarkCircle}/></div>
                        <img src={Youtube1} alt='Youtube1' className='facebook-guide-img'/>
                    </div>
                </div>
                <div className='facebook-guide-wrapper' style={!isTwitterGuideOpen ? {display: 'none'}:{}}>
                    <div className='facebook-guide-inner'>
                        <div className='close-facebook-instructions' onClick={()=>{setIsTwitterGuideOpen(!isTwitterGuideOpen)}}><FontAwesomeIcon icon={faXmarkCircle}/></div>
                        <img src={Twitter1} alt='Twitter1' className='facebook-guide-img'/>
                    </div>
                </div>
                <div className='social-page-top'>
                    <div className='social-page-top-icon-container'>
                        <Icon iconName = "icon-social"/>
                    </div>
                    <div className='social-page-top-text'>
                        <div className='inline-element spaced-text'>Load </div><div className='inline-element text-strike'> Social Media</div>
                        <div className='gold replacement-text'>Advertisers</div>
                    </div>
                </div>
                <div className='social-page-content'>
                    <div className='social-page-description-container'>
                        <h2>In order to jump start your Parsal profile, we have provided instructions on how to import your ad interaction data from popular platforms. This can be done now or in the profile section later.</h2>
                        <h3>Click here for instructions on how to import historical ad interaction data from Facebook: <a href='https://parsalgeneralstorage.blob.core.windows.net/generalresources/Parsal_Facebook_Advertisor_Import_Steps.pdf' target='_blank'><span style={{color:'#ffbf46'}}>Facebook Instructions</span></a></h3>
                        <h3>Click here for instructions on how to import brands targeting you on Amazon: <a href='https://parsalgeneralstorage.blob.core.windows.net/generalresources/Parsal_Amazon_Advertisor_Import_Steps.pdf' target='_blank'><span style={{color:'#ffbf46'}}>Amazon Instructions</span></a></h3>
                        <h4>Your top 10 Gmail advertisement emails from your "Promotions" inbox will be loaded automatically. </h4>
                        <h4 style={{display: 'none'}}>You will also be able to manually upload advertisements and brands as seen here: <a href='https://parsalgeneralstorage.blob.core.windows.net/generalresources/ManualUploadDemo.mp4' target='_blank'><span style={{color:'#ffbf46'}}>Manual Upload</span></a></h4>
                    </div>
                    <div className='social-page-social-container'>
                        <div className='social-guide-info' onClick={()=>{setIsFacebookGuideOpen(!isFacebookGuideOpen)}} style={{display: 'none'}}><FontAwesomeIcon icon={faInfoCircle}/></div>
                        <div className='social-page-social-container-inner social-selection facebook-social' onClick={() => getFacebook()}>
                            <Icon iconName = "facebook" />
                        </div>
                        <div>
                            <p>Import Facebook History</p>
                        </div>
                    </div>
                    <div className='social-page-social-container'>
                        <div className='social-guide-info' onClick={()=>{setIsAmazonGuideOpen(!isAmazonGuideOpen)}} style={{display: 'none'}}><FontAwesomeIcon icon={faInfoCircle}/></div>
                        <div className='social-page-social-container-inner social-selection facebook-social' onClick={() => getFacebook()}>
                            <Icon iconName = "amazon" />
                        </div>
                        <div>
                            <p>Import Amazon Businesses</p>
                        </div>
                    </div>
                    <div className='social-page-social-container' style={{display: 'none'}}>
                        <div className='social-guide-info' onClick={()=>{setIsGmailGuideOpen(!isGmailGuideOpen)}}><FontAwesomeIcon icon={faInfoCircle}/></div>
                        <div className='social-selection google-social' onClick={getGmail}>
                            <Icon iconName = "google" />
                        </div>
                        <div>
                            <p>Gmail Promotions</p>
                        </div>
                    </div>
                    <br/>
                    <div className='social-page-social-container' style={{display: 'none'}}>
                        <div className='social-guide-info' onClick={()=>{setIsInstagramGuideOpen(!isInstagramGuideOpen)}}><FontAwesomeIcon icon={faInfoCircle}/></div>
                        <div className='social-selection google-social' onClick={getGmail}>
                            <Icon iconName = "instagram" />
                        </div>
                        <div>
                            <p>Upload Instagram</p>
                        </div>
                    </div>
                    <div className='social-page-social-container' style={{display: 'none'}}>
                        <div className='social-guide-info' onClick={()=>{setIsYoutubeGuideOpen(!isYoutubeGuideOpen)}}><FontAwesomeIcon icon={faInfoCircle}/></div>
                        <div className='social-selection google-social' onClick={getGmail}>
                            <Icon iconName = "youtube" />
                        </div>
                        <div>
                            <p>Upload Youtube</p>
                        </div>
                    </div>
                    <div className='social-page-social-container' style={{display: 'none'}}>
                        <div className='social-guide-info' onClick={()=>{setIsTwitterGuideOpen(!isTwitterGuideOpen)}}><FontAwesomeIcon icon={faInfoCircle}/></div>
                        <div className='social-selection google-social' onClick={getGmail}>
                            <Icon iconName = "twitter" />
                        </div>
                        <div>
                            <p>Upload Twitter</p>
                        </div>
                    </div>
                    <div style={{paddingTop: '35px'}}>                        
                        <Button variant="primary" type="button" className='social-continue-btn' onClick={navToPage}>Continue</Button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SocialConnections;