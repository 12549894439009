import React from "react";
import '../../LandingPage.css';
import { useAuth0 } from "@auth0/auth0-react";
import ParsalIconLg from '../../ParsalIcon64.png';
import { faUserCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Sidebar from '../../Components/Sidebar';
import { useNavigate, useLocation } from 'react-router-dom';

export default function LandingNav() {
    const { loginWithRedirect } = useAuth0();
    const navigate = useNavigate();
    const location = useLocation();

        const handleLogin = async () => {
            await loginWithRedirect({
            appState: {
                access_type: 'offline', 
                connection_scope: 'https://www.googleapis.com/auth/gmail.readonly', 
                approval_prompt: 'force',
                returnTo: "/home",
                useRefreshTokens: true
            },
        });

    }
  return (
    <div className='landing-page-header-wrapper'>
        <div className='landing-page-header-logo-wrapper'>
            <div className='landing-page-header-logo-img-wrapper'>
                <img className='landing-page-header-logo' src={ParsalIconLg} alt='parsalIcon'></img>
            </div>
            <div className='landing-page-header-app-name'>parsal</div>
        </div>
        <div className='landing-page-header-nav-links'>
            <div className={location.pathname === '/' ? 'landing-page-header-nav-link-active' : 'landing-page-header-nav-link'} onClick={() => {navigate('/')}}>
                <span>Home</span>
            </div>
            <div className={location.pathname === '/leaderboard' ? 'landing-page-header-nav-link-active' : 'landing-page-header-nav-link'} onClick={() => {navigate('/leaderboard')}}>
                <span>Leaderboard</span>
            </div>
            <div className={location.pathname === '/about' ? 'landing-page-header-nav-link-active' : 'landing-page-header-nav-link'} onClick={() => {navigate('/about')}}>
                <span>About</span>
            </div>
            <div className={location.pathname === '/howitworks' ? 'landing-page-header-nav-link-active' : 'landing-page-header-nav-link'} onClick={() => {navigate('/howitworks')}}>
                <span>How It Works</span>
            </div>
            <div className='landing-page-header-navigation'>
                <div className='landing-page-header-navigation-profile'>
                    <span className='landing-page-header-navigation-profile-login' onClick={handleLogin}>
                        <FontAwesomeIcon icon={faUserCircle}/>
                    </span>
                </div>
            </div>
            <div className='landing-page-header-hamburger-menu'>
                <Sidebar pageWrapId={'page-wrap'} outerContainerId={'landing-page-header-nav-links'} />
            </div>
        </div>
    </div>
  );
}