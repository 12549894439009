import React, { useEffect, useState } from "react";
import { useApi } from '../Contexts/ApiProvider';
import '../App.css';
import { useAuth0 } from "@auth0/auth0-react";
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import {toast} from 'react-toastify';

export default function ProfileOverview() {
    let [userProfile, setUserProfile] = useState({});
    const { user, getAccessTokenSilently } = useAuth0();
    const api = useApi();
    let [profileFirstName, setProfileFirstName] = useState('');
    let [profileLastName, setProfileLastName] = useState('');
    let [profileEmail, setProfileEmail] = useState('');
    let [profilePhone, setProfilePhone] = useState('');
    let [profileAddressLine1, setProfileAddressLine1] = useState('');
    let [profileAddressLine2, setProfileAddressLine2] = useState('');
    let [profileGender, setProfileGender] = useState('');
    let [profileBirthday, setProfileBirthday] = useState('');
    let [profileState, setProfileState] = useState('');
    let [profileCity, setProfileCity] = useState('');
    let [profileZip, setProfileZip] = useState('');
    let [profileBio, setProfileBio] = useState('');
    let [profileInterests, setProfileInterests] = useState('');
    const stateOptions = [
        'Alabama', 'Alaska', 'Arizona', 'Arkansas', 'California', 'Colorado', 'Connecticut', 'Delaware', 'Florida', 'Georgia', 'Hawaii', 'Idaho', 'Illinois', 'Indiana',
        'Iowa', 'Kansas', 'Kentucky', 'Louisiana', 'Maine', 'Maryland', 'Massachusetts', 'Michigan', 'Minnesota', 'Mississippi', 'Missouri', 'Montana', 'Nebraska',
        'Nevada', 'New Hampshire', 'New Jersey', 'New Mexico', 'New York', 'North Carolina', 'North Dakota', 'Ohio', 'Oklahoma', 'Oregon', 'Pennsylvania', 'Rhode Island',
        'South Carolina', 'South Dakota', 'Tennessee', 'Texas', 'Utah', 'Vermont', 'Virginia', 'Washington', 'West Virginia', 'Wisconsin', 'Wyoming'
      ];

    const onUpdateRequest = async (event) => {
        event.preventDefault();
        const data = await api.post('consumer/update', {
            email: profileEmail,
            firstName: profileFirstName,
            lastName: profileLastName,
            phone: profilePhone,
            gender: profileGender,
            birthday: profileBirthday,
            state: profileState,
            city: profileCity,
            zip : profileZip,
            bio: profileBio,
            interests: profileInterests,
            addressLine1: profileAddressLine1,
            addressLine2: profileAddressLine2
          }, {
            headers: {
              Authorization:  'Bearer ' + await getAccessTokenSilently()
            }});
          if (!data.ok) {
            toast.error('Unable to update profile');
          }
          else {
            toast('Profile successfully updated');
          }
        };

        const handleSelectChange = (e) => {
            setProfileState(e.target.value);
          };
    
    useEffect(() => {
        (async () => {
            var apiUri = 'consumer';
            
            const response = await api.get(apiUri, null, {
                headers: {
                  Authorization:  'Bearer ' + await getAccessTokenSilently()
                }});
            if (response.ok) {
                setUserProfile(response.body);
                setProfileFirstName(response.body.firstName);
                setProfileLastName(response.body.lastName);
                setProfileEmail(response.body.email);
                setProfilePhone(response.body.phone);
                setProfileAddressLine1(response.body.addressLine1);
                setProfileAddressLine2(response.body.addressLine2);
                setProfileGender(response.body.gender);
                setProfileBirthday(response.body.birthday);
                setProfileCity(response.body.city);
                setProfileState(response.body.state);
                setProfileZip(response.body.zip);
                setProfileBio(response.body.bio);
                setProfileInterests(response.body.interests);
            }
            else {
                setUserProfile(null);
            }
        })();
        }, []);

  return (
    <div>
        <Form onSubmit={onUpdateRequest}>
            <Form.Group className="mb-3 form-section" controlId="exampleForm.ControlInput1">
                <Form.Label className="form-section-label">Name</Form.Label>
                <Form.Control type="text" value={profileFirstName + ' ' + profileLastName} disabled/>
            </Form.Group>
            <Form.Group className="mb-3 form-section" controlId="exampleForm.ControlTextarea1">
                <Form.Label className="form-section-label">Email</Form.Label>
                <Form.Control type="email" value={profileEmail} disabled/>
            </Form.Group>
            <Form.Group className="mb-3 form-section" controlId="exampleForm.ControlTextarea1">
                <Form.Label className="form-section-label">Phone</Form.Label>
                <Form.Control type="phone" value={profilePhone} onChange={(selectedValue) => {setProfilePhone(selectedValue.target.value)}}/>
            </Form.Group>
            <Form.Group className="mb-3 form-section" controlId="exampleForm.ControlTextarea1">
                <Form.Label className="form-section-label">Address Line 1</Form.Label>
                <Form.Control type="text" value={profileAddressLine1} onChange={(selectedValue) => {setProfileAddressLine1(selectedValue.target.value)}}/>
            </Form.Group>
            <Form.Group className="mb-3 form-section" controlId="exampleForm.ControlTextarea1">
                <Form.Label className="form-section-label">Address Line 2</Form.Label>
                <Form.Control type="text" value={profileAddressLine2} onChange={(selectedValue) => {setProfileAddressLine2(selectedValue.target.value)}}/>
            </Form.Group>
            <Form.Group className="mb-3 form-section" controlId="exampleForm.ControlTextarea1">
                <Form.Label className="form-section-label">City</Form.Label>
                <Form.Control type="text" value={profileCity} onChange={(selectedValue) => {setProfileCity(selectedValue.target.value)}}/>
            </Form.Group>
            <Form.Group className="mb-3 form-section">
                <Form.Label className="form-section-label">State</Form.Label>
                <Form.Select value={profileState} onChange={handleSelectChange}>
                <option>{profileState}</option>
                    {stateOptions.map((state) => (
                    <option value={state}>
                        {state}
                    </option>
                    ))}
                </Form.Select>
            </Form.Group>
            <Form.Group className="mb-3 form-section" controlId="exampleForm.ControlTextarea1">
                <Form.Label className="form-section-label">Zip</Form.Label>
                <Form.Control type="text" value={profileZip} onChange={(selectedValue) => {setProfileZip(selectedValue.target.value)}}/>
            </Form.Group>
            <Button variant="primary" type="submit" className='interested-modal-interested-btn'>
                <span className='feedback-modal-btn-icon'></span>
                <span>Save</span>
            </Button>   
        </Form>
    </div>
  );
}