import '../LandingPage.css';
import LandingNav from '../Components/LandingPage/LandingNav';
import LandingFooter from '../Components/LandingPage/LandingFooter';
import { faChevronCircleDown, faChevronCircleUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { useApi } from '../Contexts/ApiProvider';
import vertImg from '../landingAboutVert.png';
import horImg from '../landingAboutHor.png';
import adCollage from '../AdCollage.png';
import OriginalAd from '../OriginalAd.png';
import AdMovie from '../AdMovie.jpg';
import BehaviorEco from '../BehaviorEco.png';
import BehaviorEco1 from '../BehaviorEco1.png';
import BehaviorEco2 from '../BehaviorEco2.png';
import SpotAi from '../SpotAI.png';
import { useNavigate } from 'react-router-dom';
import AdSense from '../Components/AdSense';

function LandingAbout() {
    const api = useApi();
    const [isLoading, setIsLoading] = useState(true);
    let [isArticleOneShow, setIsArticleOneShow] = useState(false);
    let [isArticleTwoShow, setIsArticleTwoShow] = useState(false);
    let [isArticleThreeShow, setIsArticleThreeShow] = useState(false);
    let [isArticleFourShow, setIsArticleFourShow] = useState(false);
    let [isArticleFiveShow, setIsArticleFiveShow] = useState(false);
    const navigate = useNavigate();

    return(
        <div className='landing-page-wrapper'>
            <LandingNav />
            <div className='landing-about-content-wrapper'>
                <div className='landing-about-content-image-wrapper-mobile'>
                    <img src={horImg} alt='ad-img' className='landing-about-img'/>
                </div>
                <div className='landing-about-content-text-wrapper'>
                    <div className='landing-about-content-text'>
                        <p className='landing-about-content-text-header'>It's your data,</p>
                        <p className='landing-about-content-text-header-bold'>reach out and grab it.</p>
                        <p>Parsal was founded by a small team specialized in technology, marketing, and data. After decades of experience consulting and building lead generation and nurturing campaigns, working with data vendors, ad networks, and other billion-dollar companies built on the backs of their users' personal information - we felt there was a need for change.</p>
                        <p>We have a vision to empower the people and businesses together to create a better data marketplace.</p>
                        <p>Let's get Parsal-ing.</p>
                    </div>
                </div>
                <div className='landing-about-content-image-wrapper'>
                    <img src={vertImg} alt='ad-img' className='landing-about-img'/>
                </div>
            </div>
            <div className='landing-about-blog-content-wrapper'>
                {/*<AdSense dataAdSlot='2013861535' />*/}
                <div className='landing-about-blog-content-title'>
                    <span>The Parsal Parcel</span>
                </div>
                <div className='landing-about-blog-articles-wrapper'>
                    <div className='landing-about-blog-article'>
                        <div className='landing-about-blog-article-title'>
                            <p>A Data Deal with the Devil</p>
                            <p className='landing-about-blog-article-author'>Written by The Parsal Team</p>
                        </div>
                        <div className='landing-about-blog-article-image'>
                            <img src={adCollage} alt='adCollage'/>
                        </div>
                        <div className='landing-about-blog-article-body'>
                            <p>Your data is sold every day for thousands of dollars, if not more. Its a fact that our 
                                use of online brands like Facebook, Instagram, Twitter, Google, and more make up a 
                                large portion of our everyday life, and that those services are funded by your data 
                                getting sold, sold, and sold again. How did this happen?</p>

                            <div style={isArticleOneShow ? {display: 'none'} : {display: 'block'}} onClick={() => (navigate('/blog/datadeal'))}>
                                <FontAwesomeIcon icon={faChevronCircleDown}/> <span>Read more...</span>
                            </div>    
                            <div style={isArticleOneShow ? {display: 'block'} : {display: 'none'}}>
                                <p>When the internet was first brought to the mainstream, it changed everything. 
                                    Location and distance were no longer the barriers they once were, you could 
                                    communicate, shop, and visit the Space Jam website (<a href='https://www.spacejam.com/' target='_blank'>still online by the way</a>).</p>

                                <p>In 1994, as the internet began to take hold and grow in popularity, a small off shoot 
                                    company of Wired magazine called Hotwired, created the first “banner ad”. </p>

                                <img src={OriginalAd} alt='OriginalBannerAd'/>

                                <p>Hotwired needed to find a way to generate revenue to fund its free online content, 
                                    in place of its traditional cousin's magazine subscription feeds. So, they created a 
                                    brilliant and world changing idea. They would create small sections of their site that 
                                    they could sell to advertisers, like how magazine and newspapers would sell ad 
                                    space. </p>

                                <p>AT&T paid $30k to for the right to display the banner ad above on the Hotwire site 
                                    for three months. The ad generated massive response, the first of its kind, including
                                    a clickthrough rate of nearly half of the traffic to the site – 44%. For comparison, 
                                    ad’s today can range from .01% to .1% on averaged depending on the content.</p>
                                
                                <p>Hotwired had struck a gold mine, but like a Tolkienesque curse, soon we would dig 
                                    too deep. In 1995, inspired by the momentum that banner ad's were gaining, an ad 
                                    agency called WebConnect began offering tracking for their clients to better 
                                    understand what websites their customer's were visiting, connect user data and 
                                    behavior to targeted advertising online for the first time. The Balrog was awakened.</p>

                                <p>In 2000, a little up and coming search engine company launched what would 
                                    become their most successful financial product, Goodle Adwords. Today, Google's 
                                    total yearly revenue is in excess of $300 billion (with a B) : more than 60% of that 
                                    revenue is generated through ad sales.
                                    </p>
                                
                                <p>With the advent of social media in the 2000s, starting with myspace in 2003, the 
                                    internet transformed from message boards, shopping, and hobby sites to a massive 
                                    social communications hub : enhancing and expanding on all that came before it.</p>

                                <p>The best thing about the amazing new world of social media? It was all free.
                                    However, being free to use came at a different price and these two related streams 
                                    of innovation, online advertising using personal data and the glut of personal data 
                                    provided by social media melded together into a giant eye of fire and flame... 
                                    perhaps these Lord of the Rings references have gone too far.
                                    </p>
                                
                                <p>Today, advertising and lead generation built on the back of your personal online 
                                    data is projected to reach nearly $750 billion dollars in market size by 2030, with 
                                    companies like Google, Facebook, Amazon and many others continuing to grow by 
                                    taking your personal data and offering it to companies to target with advertising 
                                    and marketing. </p>

                                <p>Many of those who use the internet the most, those born in the 1990s and onward, 
                                    have never known it any other way than as a value exchange of personal data for 
                                    services : when they are aware at all.</p>
                                
                                <p>There are companies, like Parsal, working to change this data marketplace. Its your 
                                    data, why should big companies be the only ones getting to leverage it to earn 
                                    revenue, why not you? </p>

                                <p>A big hurdles in pushing back against the current online advertising standards are 
                                    that:
                                    <p style={{paddingLeft: '4em'}}>1: They work. Even if everyone hates it, targeted online ads work and drive 
                                    revenue for the companies buying them.</p>
                                    <p style={{paddingLeft: '4em'}}>2: The big players have cornered the market. The big social media and ad 
                                    publishing giants provide the biggest bang for advertisers buck with the least 
                                    amount of work.</p>
                                    <p style={{paddingLeft: '4em'}}>3: One user doesnt have the leverage to argue for their ownership of the data.</p>
                                </p>

                                <p>So, to build something better it has to work BETTER, create a NEW market, give 
                                    USERS the power to negotiate value in return for their personal data. </p>

                                <p>Join Parsal today to help create this new marketplace, together we can take back 
                                    the internet.</p>
                                
                                <div style={isArticleOneShow ? {display: 'block'} : {display: 'none'}} onClick={() => (setIsArticleOneShow(false))}>
                                    <FontAwesomeIcon icon={faChevronCircleUp}/> <span>Read less...</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
                <div className='landing-about-blog-articles-wrapper'>
                    <div className='landing-about-blog-article'>
                        <div className='landing-about-blog-article-title'>
                            <p>Why Don't You Just Tell Me the Movie You Want to See?</p>
                            <p className='landing-about-blog-article-author'>Written by The Parsal Team</p>
                        </div>
                        <div className='landing-about-blog-article-image'>
                            <img src={AdMovie} alt='adMovie'/>
                        </div>
                        <div className='landing-about-blog-article-body'>
                            <p>A line made famous by a hilarious yet eclectic 90's sitcom neighbor. As the character asks phone callers to click the number for the movie they want to see, 
                                he awkwardly realizes he is not capable of interpreting the input, then asks "Why dont you just tell me the movie you want to see?". For years, advertisers and advertising platforms have attempted to interpret your web browsing behavor
                                in order to anticipate what products you might be interested in and when you might be interested in them. Brands spending big bucks to show you gym gear because your phone thought it heard you say "Exercise" when what you actually said
                                 was "Extra Fries".</p>

                            <div style={isArticleTwoShow ? {display: 'none'} : {display: 'block'}} onClick={() => (navigate('/blog/tellme'))}>
                                <FontAwesomeIcon icon={faChevronCircleDown}/> <span>Read more...</span>
                            </div>    
                            <div style={isArticleTwoShow ? {display: 'block'} : {display: 'none'}}>
                                <p>Ever clicked on a link to a product you were vaugley interested in or curious about,
                                     just to be constantly spammed by that product's ads for the next month on every media platform? Advertisers and advertising platforms are constantly trying to anticipate consumer wants, to an annoying degree. 
                                     Yes, techincally I did click a link to the male enhancement pill website, but it was just a brief moment of self doubt and weakness, I am not actually going to buy them! Or better yet, someone just mentions a product while
                                      you are talking near your phone and all of the sudden all you see are ads for that product (How do they do that right?!).</p>

                                <p>"In the name of personalized experience!" is the zealous almost cultish decree of all major advertisement and data companies. Allow us to track your every move so that we can customize your advertisement content without any real context. Games, applications, internet providers, search engines, credit card companies, 
                                    and potentially worst of all cell phone providers. A couple of years ago, Verizon rolled out a new program that auto-opted in all of it's customers to a custom experience program. This program tracked certain activity in order to profile, package, and ultimately profit off of. In some cases customers reported 
                                    being opted into the custom experience plus which was even more invasive - like this person reported <a href='https://www.reddit.com/r/verizon/comments/r6i2c9/custom_experience_program/'>here</a>. These kinds of things are happening every where and most of the time without the consumers knowledge. Some of the time, 
                                    there is consumer conset that gets burried in some terms and conditions 15 paragraphs down - everyone reads those though right?
                                </p>
                                
                                <p>With advancements in artificial intellegence, big brands are investing millions and millions into technology that will interpret your every action in order to programatically project your next move. They want to train AI models to draw analytical conclusions about your 
                                    feelings towards certain products or brands - side note... am I the only one tired of seeing "AI" in everything? I am all for the advancement of technology to better our lives but come on. We all realize that AI is being slapped on 
                                    everything because it is a buzz word right? Okay back to my point - Wouldn't it be easier to simply ask consumers these questions? As impressive as the technology is, if the intent is to better my experience, isn't the best source of that feedback the consumer themselves?
                                </p>
                                <div style={isArticleTwoShow ? {display: 'block'} : {display: 'none'}} onClick={() => (setIsArticleTwoShow(false))}>
                                    <FontAwesomeIcon icon={faChevronCircleUp}/> <span>Read less...</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='landing-about-blog-articles-wrapper'>
                    <div className='landing-about-blog-article'>
                        <div className='landing-about-blog-article-title'>
                            <p>Behavioral Economics, The Secret Language of Business</p>
                            <p className='landing-about-blog-article-author'>Written by The Parsal Team</p>
                        </div>
                        <div className='landing-about-blog-article-image'>
                            <img src={BehaviorEco} alt='Behavor Economics'/>
                        </div>
                        <div className='landing-about-blog-article-body'>
                            <p>Have you ever felt like your being followed online? That someone is always looking 
                                over your shoulder and nudging your elbow at just the right time to get you to click 
                                on that ad, add that hat to your amazon shopping list, subscribe to that email for 
                                that sweet, sweet 10% discount?</p>

                            <div style={isArticleThreeShow ? {display: 'none'} : {display: 'block'}} onClick={() => (navigate('/blog/behavior'))}>
                                <FontAwesomeIcon icon={faChevronCircleDown}/> <span>Read more...</span>
                            </div>    
                            <div style={isArticleThreeShow ? {display: 'block'} : {display: 'none'}}>
                                <p>The truth is, that is happening, and not just online.</p>
                                <p>All day, every day – businesses are using expert targeted tactics that are shown to 
                                    effectively nudge shoppers in the direction they want. From hiding the milk at the 
                                    back of the grocery store so you must walk down every aisle and see all the stuff 
                                    you don’t need, but WANT to buy to buy one get one free offers that drive you to 
                                    buy two copies of Twilight, when you know well that you already have four copies at 
                                    home.</p>
                                <p>Here, we’ve gathered experiences of the course of a decade plus in the advertising 
                                    game to share some of the tactics these companies deploy. Watch for these tactics, 
                                    stay informed, and hold your personal data close when businesses ask you to 
                                    exchange it for momentary return:</p>
                                <div className='landing-about-blog-article-image'>
                                    <img src={BehaviorEco1} alt='Behavor Economics'/>
                                </div>
                                <p><b>The “Foot in the Door” Subscription:</b>It seems inconspicuous at first. A pop up 
                                    interrupting your shopping for socks on coolsocksforcoolpeople.com offering 25% off
                                    your next sock order! All you need to do is provide an email address or mobile 
                                    phone number. Easy. However, hidden in that offer is what is called an “opt in”. 
                                    Some businesses will make the Opt In active – this means you have to check a box 
                                    or agree to receive future marketing from the business. But many will use what’s 
                                    called “passive opt in”. This means that whether you realize it or not, you are 
                                    agreeing to marketing just by signing up for the discount. “Not I” you’re saying in 
                                    your head now, “I will be one of the smart ones and quickly unsubscribe as soon as I
                                    get that delicious discount code”. The thing is, you won’t. According to email 
                                    marketing platform Mailchimp, only about 0.25% of people unsubscribe from email 
                                    lists they belong to. So, once you sign up, the business has you trapped receiving 
                                    their emails day in and day out. “But that doesn’t matter!” you’re surely exclaiming,
                                    again, in your head “I will just ignore them like I do all those emails from my mom.” 
                                    Well, bad news...</p>
                                
                                <div className='landing-about-blog-article-image'>
                                    <img src={BehaviorEco2} alt='Behavor Economics'/>
                                </div>
                                <p><b>A/B Testing & Predictive Analytics:</b>There is a famous anecdote that business 
                                    folks love to share. A story about the magical predictive power of behavioral data, 
                                    the buzzword version of “tracking what users are clicking on your website” and 
                                    dynamic email marketing. The tale unfolds that an angry husband and father called 
                                    the headquarters of retailer Target demanding that they stop emailing him to 
                                    advertise diapers and formula. No one in his household was a baby, nor was 
                                    expecting a baby. The target headquarters apologized and said that they would 
                                    unsubscribe him, only for the man to call back shortly after to apologize. His 
                                    teenage daughter, it turns out, had indeed been shopping for pregnancy tests and 
                                    hadn’t yet told her dad – and the magical marketing math had predicted her future 
                                    needs for baby supplies, then let her know that when the time came, mama Target 
                                    was there. While this story may sound a bit silly, in practice, it’s actually quite 
                                    common. Every action you take on a company’s website, social pages, or even in 
                                    store can be captured, analyzed, and predicted. Once you open a pathway for 
                                    messaging, email, text, mobile app, carrier pigeon, whatever – businesses can use 
                                    all this behavior to create a series of tests, incremental offers, interesting articles, 
                                    and more to slowly shift your behavior toward what they want from you.</p>
                                <p>To be continued...</p>
                                <div style={isArticleThreeShow ? {display: 'block'} : {display: 'none'}} onClick={() => (setIsArticleThreeShow(false))}>
                                    <FontAwesomeIcon icon={faChevronCircleUp}/> <span>Read less...</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='landing-about-blog-articles-wrapper'>
                    <div className='landing-about-blog-article'>
                        <div className='landing-about-blog-article-title'>
                            <p>Behavioral Economics, The Secret Language of Business Continued</p>
                            <p className='landing-about-blog-article-author'>Written by The Parsal Team</p>
                        </div>
                        <div className='landing-about-blog-article-image'>
                            <img src={BehaviorEco} alt='Behavor Economics'/>
                        </div>
                        <div className='landing-about-blog-article-body'>
                            <p>In part one of this article, we covered some tactics that businesses use to leverage the data
                                 they capture or purchase about you to create effective, sometime insidious, behavioral loops
                                  that draw you into their web of discounts, offers, and more. While generally, these methods
                                   of marketing aren’t in and of themselves harmful, we believe that through the level of personal
                                    data that they have access too, whether you as a consumer like it or not, they have become
                                     much more of an infringement on our lives. At Parsal, we believe that the best way to create
                                      good data for marketing is to provide it, uniquely, directly, and proactively – with transparency
                                       from both sides. So, we share these examples to better arm you with knowledge, and as G.I.
                                        Joe says – knowing is half the battle.  If you see these methods in use, tread carefully:</p>

                            <div style={isArticleFourShow ? {display: 'none'} : {display: 'block'}} onClick={() => (navigate('/blog/behaviorContinued'))}>
                                <FontAwesomeIcon icon={faChevronCircleDown}/> <span>Read more...</span>
                            </div>    
                            <div style={isArticleFourShow ? {display: 'block'} : {display: 'none'}}>
                                <div className='landing-about-blog-article-image'>
                                    <img src={BehaviorEco1} alt='Behavor Economics'/>
                                </div>
                                <p>The “Better Mouse Trap”: The most effective marketing funnels use what are called
                                     triggers to watch for your activity, then they spring the trap. Usually what this
                                      looks like is, you visit a site and put a few things into the cart, then come to
                                       your senses and realize that even the most dedicated athlete doesn’t need three
                                        Bowflexes, let alone you who saw them while on the couch watching Netflix at 2am.
                                         You’ll soon receive an email or a text message from the helpful human-like brand
                                          reminding you that “Hey, you left something in the cart! To help you make your
                                           decision, we’ve even discounted it for you!”. Even more, many businesses will
                                            stack these trigger systems to create an entire staircase of interconnected
                                             actions, acting almost as an artificial intelligence itself. Often, these messages
                                              will be well crafted and personalized to you, and will only ask for a small,
                                               almost inconsequential next step – like signing up for a newsletter, or agreeing
                                                to reminders for sales, but then it will be followed by another small prompt,
                                                 then another. There is a common old-school adage in sales that if you can get
                                                  someone to say yes several times in a row, the momentum will make the sale
                                                   itself much easier, and these systems follow that same mentality.</p>
                                <div className='landing-about-blog-article-image'>
                                    <img src={BehaviorEco1} alt='Behavor Economics'/>
                                </div>
                                <p>The Data Cabal: Did you know that the United States Postal Service keeps a database of everyone in the county’s
                                     address and keeps a running stream of updates whenever anyone moves, and that they make it fully available
                                      to businesses to purchase, append, and target advertising using that data? Well, they do. And they aren’t
                                       alone. Many of the services we rely on every day to live and go about our personal lives are just as reliant
                                        on your data to provide those services, and generate revenue, as the big social media brands. Credit companies,
                                         online recipe mavens, real estate shopping networks, and many many more provide a free-to-use service by 
                                         extracting their payment in other ways.</p>
                                <p>To be continued…</p>
                                <div style={isArticleFourShow ? {display: 'block'} : {display: 'none'}} onClick={() => (setIsArticleFourShow(false))}>
                                    <FontAwesomeIcon icon={faChevronCircleUp}/> <span>Read less...</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
                <div className='landing-about-blog-articles-wrapper'>
                    <div className='landing-about-blog-article'>
                        <div className='landing-about-blog-article-title'>
                            <p>Spotting AI Fakes: A Beginner's Guide</p>
                            <p className='landing-about-blog-article-author'>Generated by the Parsal Team</p>
                        </div>
                        <div className='landing-about-blog-article-image'>
                            <img src={SpotAi} alt='Spot AI'/>
                        </div>
                        <div className='landing-about-blog-article-body'>
                            <p>Hey there, savvy internet surfer! Ever stumbled upon content that left you scratching your head, wondering if it was crafted by a creative
                                 genius or a mischievous AI? Fear not, because in this guide, we're diving into the delightful world of AI-generated content detection,
                                  armed with wit, wisdom, and maybe a sprinkle of existential dread. So, buckle up and let's embark on this journey through the digital
                                   jungle together.</p>

                            <div style={isArticleFiveShow ? {display: 'none'} : {display: 'block'}} onClick={() => (navigate('/blog/spotai'))}>
                                <FontAwesomeIcon icon={faChevronCircleDown}/> <span>Read more...</span>
                            </div>    
                            <div style={isArticleFiveShow ? {display: 'block'} : {display: 'none'}}>
                                <p>⦁	The Language of Bots and Banter: Picture this: you're scrolling through a blog post when suddenly, you encounter
                                     a sentence that reads like it was written by a hyperactive squirrel on caffeine. Yup, that's our cue to suspect AI
                                      involvement! Keep an eye out for bizarre sentence structures, repetitive phrases, or word choices that seem straight
                                       out of a sci-fi novel. After all, even AI has its quirks, and boy, are they entertaining!</p>
                                <p>⦁	Logic vs. AI: The Battle Royale: Ah, logic – the age-old nemesis of AI-generated content. Prepare yourself for a showdown
                                     of epic proportions as we pit human reasoning against AI randomness. From stories that take more twists and turns than a
                                      rollercoaster to conclusions that make less sense than a pineapple on pizza, you'll soon be able to spot AI shenanigans
                                       from a mile away. Game on, bots!</p>
                                <p>⦁	Metadata Madness: CSI Internet Edition: Now, here's where things get a tad Sherlockian. Dive deep into the murky waters
                                     of metadata and uncover clues that even the most cunning AI can't hide. Timestamps that don't add up? Author attributions
                                      as mysterious as a disappearing act? Sounds like a job for the digital detectives in all of us! Get ready to channel your
                                       inner CSI investigator and crack the case wide open.</p>
                                <p>⦁	AI Detection Tools: The Unsung Heroes: Meet the unsung heroes of the digital realm – AI detection tools! Armed with algorithms
                                     sharper than a freshly sharpened pencil, these tools are here to save the day. With their help, you can sift through mountains of
                                      content with the precision of a surgeon and the swagger of a superhero. Cue the dramatic music, because it's time to uncover some AI fakes!</p>
                                <p>⦁	Reverse Image Searches: Where Art Meets AI: Ever stumbled upon an image that seemed too good to be true? Cue the reverse image search! From
                                     unmasking image manipulation to tracking down the original source faster than you can say "Abracadabra," this nifty tool is your ticket to
                                      unraveling visual mysteries. Say cheese, AI – we're onto you!</p>
                                <p>⦁	Sources and Citations: Truth or Dare? Last but not least, let's play a game of truth or dare with our sources and citations. From fact-checking
                                     like a pro to sniffing out fake references faster than a bloodhound, we're leaving no stone unturned in our quest for authenticity. So, buckle up,
                                      because things are about to get real... or should I say, "reel"?</p>
                                <p>In conclusion, spotting AI-generated content is as much about wit and humor as it is about sleuthing skills. With a dash of sarcasm, a sprinkle of
                                     skepticism, and a whole lot of laughter, you'll be navigating the digital landscape like a seasoned pro in no time. So, go forth, my friend, armed
                                      with knowledge, curiosity, and maybe a few jokes – because in the world of AI detection, laughter truly is the best medicine.</p>
                                <p>Did you spot it? Did you notice a sudden increase in the format and structure of the writing? Because this entire article was written almost entirely
                                     by the generative AI tool ChatGPT. The influx of generative AI is both wonderous and potentially terrifying. After all, how can businesses know that
                                      the attractive, good-looking customer they’ve been messaging is interested in their products? What if they are just being AI catfished? </p>
                                <p>AI in all its exciting forms can be a powerful tool for businesses and consumers alike, when used well. However, at the end of the day, we are all looking
                                     for actual connections. After all, AI isn’t quite human-like enough to buy 24 cases of Flamin Hot Doritos, and until it needs to do that, businesses will
                                      need to make a truly human connection.</p>
                                <div style={isArticleFiveShow ? {display: 'block'} : {display: 'none'}} onClick={() => (setIsArticleFiveShow(false))}>
                                    <FontAwesomeIcon icon={faChevronCircleUp}/> <span>Read less...</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <LandingFooter/>
        </div>
    )
}

export default LandingAbout;