import { useNavigate } from 'react-router-dom';
import { React, useEffect, useState } from "react";
import { Carousel } from "react-responsive-carousel";
import Button from 'react-bootstrap/Button';
import "react-responsive-carousel/lib/styles/carousel.min.css";

const HomeContentCarousel = () => {
    const navigate = useNavigate();
    const navToAdsPage = () => {
        navigate('/ads');
    }

    const navToBusinessPage = () => {
        navigate('/businesses');
    }

    const navToProfilePage = () => {
        navigate('/profile');
    }
    return (
    <Carousel 
        autoPlay={true}
        infiniteLoop={true}
        interval={10000}>
      <div>
            <div className='home-content-title'>
                Join the Data Gig Economy...
            </div>
            <div className='home-content-body'>
                your <b>Parsal</b> profile allows you to get paid for your data - review ads targeting you to create a valuable profile businesses will pay for!
            </div>
            <div className='home-content-bottom'>
                <Button variant="primary" type="button" className='social-continue-btn' onClick={() => {navToAdsPage()}}>View Advertisements</Button>
            </div>
      </div>
      <div>
      <div className='home-content-title'>
                Parsal Alpha Program...
            </div>
            <div className='home-content-body'>
                thank you for  your interest in <b>Parsal</b>, complete your profile to secure your alpha account. Click <a href='https://parsalgeneralstorage.blob.core.windows.net/generalresources/ManualUploadDemo.mp4' target='_blank' style={{color:'white'}}>here</a> for demo video.
            </div>
            <div className='home-content-bottom'>
                <Button variant="primary" type="button" className='social-continue-btn' onClick={() => {navToProfilePage()}}>Update Profile</Button>
            </div>
      </div>
      <div>
      <div className='home-content-title'>
                We want your feedback...
            </div>
            <div className='home-content-body'>
                please let us know how we can improve <b>Parsal</b> going forward, we want to hear from you!
            </div>
            <div className='home-content-bottom'>
                <Button variant="primary" type="button" className='social-continue-btn' onClick={() => {window.open('https://forms.gle/Hhp5TVFVZKdYGHzo9', '_blank')}}><b>Parsal</b> Feedback</Button>
            </div>
      </div>
    </Carousel>
    )
  }


  export default HomeContentCarousel;