import '../App.css';
import NavBar from '../Components/NavBar';
import Banner from '../Components/Banner';
import { useApi } from '../Contexts/ApiProvider';
import React, { useEffect, useState } from "react";
import { faCommentSlash, faCommentDots, faCommentDollar, faPencil, faClose, faSave, faThumbsUp, faThumbsDown } from "@fortawesome/free-solid-svg-icons";
import { useAuth0 } from "@auth0/auth0-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Modal from 'react-modal';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import LoadingSpinner from '../Components/loadingSpinner';
import moment from 'moment';
import { useSearchParams } from 'react-router-dom';

function PurchaseFeedbackList() {
    const api = useApi();
    const { user, getAccessTokenSilently } = useAuth0();
    let [userFeedback, setUserFeedback] = useState([]);
    const [unfilteredList, setUnfilteredList] = useState([]);
    const [formErrors, setFormErrors] = useState({});
    const [searchParams] = useSearchParams();
    let [filteredBrand, setFilteredBrand] = useState('');
    let [isFilteredBrand, setIsFilteredBrand] = useState(false);
    let [feedbackObj, setFeedbackObj] = useState({
        feedbackId: 0,
        hasAlreadyPurchased: false,
        isInterested: false,
        isActivelyShopping: false,
        isBuyingWithinThirtyDays: false,
        isNotRightTime: false,
        isNotRightTarget: false,
        IsRightFit: false,
        IsTooExpensive: false,
        IsWithinPriceRange: false,
        createDate: ''
    });
    let [isLoading, setIsLoading] = useState(false);
    let [isLoadingMore, setIsLoadingMore] = useState(false);
    let [pageSize, setPageSize] = useState(10);
    let [pageNumber, setPageNumber] = useState(1);
    let [currentFilter, setCurrentFilter] = useState('all');

    const customStylesInterested = {
        content: {
          top: '56%',
          left: '52%',
          right: 'auto',
          bottom: 'auto',
          transform: 'translate(-50%, -50%)',
          background: '#ffffff',
          width: '630px',
          textAlign: 'left',
          height: '650px'
        },
      };
      const [editFeedbackModalIsOpen, setEditFeedbackModalIsOpen] = React.useState(false);

      const openEditFeedbackModal = async (id) => {
        var activeFeedback = userFeedback.find((feedback) => feedback.feedbackId === id);
        setFeedbackObj({
            feedbackId: activeFeedback.feedbackId,
            isInterested: activeFeedback.feedbackDetails.isInterested,
            hasAlreadyPurchased: activeFeedback.feedbackDetails.hasAlreadyPurchased,
            isActivelyShopping: activeFeedback.feedbackDetails.isActivelyShopping,
            isBuyingWithinThirtyDays: activeFeedback.feedbackDetails.isBuyingWithinThirtyDays,
            isNotRightTarget: activeFeedback.feedbackDetails.isNotRightTarget,
            isNotRightTime: activeFeedback.feedbackDetails.isNotRightTarget,
            isRightFit: activeFeedback.feedbackDetails.isRightFit,
            isTooExpensive: activeFeedback.feedbackDetails.isTooExpensive,
            isWithinPriceRange: activeFeedback.feedbackDetails.isWithinPriceRange,
            createDate: activeFeedback.createdDate
        });
        setEditFeedbackModalIsOpen(true);
    }

    function removeBrandFilter(){
        window.location = window.location.href.split("?")[0];
    }

      function afterOpenModal() {
        // references are now sync'd and can be accessed.
      }
    
      function closeEditFeedbackModal() {setEditFeedbackModalIsOpen(false);}

    function getDate(dateVal)
    {
      var formattedDate = moment(dateVal).format('LL');
      return formattedDate;
    }

      const onSubmit = async (event) => {
        event.preventDefault();
        const data = await api.post('interaction/Feedback/' + feedbackObj.feedbackId, {
            hasAlreadyPurchased: feedbackObj.hasAlreadyPurchased,
            isInterested: feedbackObj.isInterested,
            isActivelyShopping: feedbackObj.isActivelyShopping,
            isWithinPriceRange: feedbackObj.isWithinPriceRange,
            isRightFit: feedbackObj.isRightFit,
            isBuyingWithinThirtyDays: feedbackObj.isBuyingWithinThirtyDays,
            isNotRightTarget: feedbackObj.isNotRightTarget,
            isNotRightTime: feedbackObj.isNotRightTime,
            isTooExpensive: feedbackObj.isTooExpensive
          }, {
            headers: {
              Authorization:  'Bearer ' + await getAccessTokenSilently()
            }});
          if (!data.ok) {
            setFormErrors(data.body.errors.json);
          }
          else {
            setFormErrors({});
            let activeFeedback = userFeedback.find((feedback) => feedback.feedbackId === data.body.feedbackId);
            const updatedFeedbackList = [...userFeedback];
            updatedFeedbackList[activeFeedback.index].isInterested = data.body.isInterested;
            updatedFeedbackList[activeFeedback.index].hasAlreadyPurchased = data.body.hasAlreadyPurchased;
            updatedFeedbackList[activeFeedback.index].sActivelyShopping = data.body.isActivelyShopping;
            updatedFeedbackList[activeFeedback.index].isTooExpensive = data.body.isTooExpensive;
            updatedFeedbackList[activeFeedback.index].isNotRightTarget = data.body.isNotRightTarget;
            updatedFeedbackList[activeFeedback.index].isNotRightTime = data.body.isNotRightTime
            updatedFeedbackList[activeFeedback.index].isWithinPriceRange = data.body.isWithinPriceRange;
            updatedFeedbackList[activeFeedback.index].isRightFit = data.body.isRightFit;
            updatedFeedbackList[activeFeedback.index].isBuyingWithinThirtyDays = data.body.isBuyingWithinThirtyDays;
            setUserFeedback(updatedFeedbackList);
            setEditFeedbackModalIsOpen(false);
          }
        };

    useEffect(() => {
        (async () => {
            setIsLoading(true);
            let getFeedbackRoute = 'interaction/feedback/consumer?feedbackType=purchases&pageSize=25&pageNumber=' + pageNumber;
            if(searchParams.get('businessID') != null){
                setIsFilteredBrand(true);
                getFeedbackRoute = getFeedbackRoute + '&businessId=' + searchParams.get('businessID');
            }
            const response = await api.get(getFeedbackRoute, null, {
                headers: {
                  Authorization:  'Bearer ' + await getAccessTokenSilently()
                }});
            if (response.ok && response.body !== null && response.body.length !== 0) {
                setUserFeedback(response.body);
                setUnfilteredList(response.body);
                setFilteredBrand(response.body[0].businessName);
                setIsLoading(false);
            }
            else {
                setUserFeedback(null);
                setIsLoading(false);
            }
        })();
        }, []);

    
        const loadResponses = () => {
            (async () => {
                setIsLoadingMore(true);
                var nextPageNum = pageNumber + 1;
                setPageNumber(nextPageNum);
                let getFeedbackRoute = 'interaction/feedback/consumer?feedbackType=purchases&pageSize=25&pageNumber=' + nextPageNum;
                if(currentFilter !== 'all')
                {
                    getFeedbackRoute += '&response=' + currentFilter;
                }
                if(searchParams.get('businessID') != null){
                    setIsFilteredBrand(true);
                    getFeedbackRoute = getFeedbackRoute + '&businessId=' + searchParams.get('businessID');
                }
                const response = await api.get(getFeedbackRoute, null, {
                    headers: {
                      Authorization:  'Bearer ' + await getAccessTokenSilently()
                    }});
                if (response.ok && response.body !== null && response.body.length !== 0) {
                    var concatFeedback = userFeedback.concat(response.body);
                    setUserFeedback(concatFeedback);
                    /*if(currentFilter !== 'all'){
                        if(currentFilter === 'isInterested'){
                            setUserFeedback(userFeedback.filter(i => i.response === 'Interested'));
                        }
                        else if (currentFilter === 'isNotInterested'){
                            setUserFeedback(userFeedback.filter(i => i.response === 'Not Interested'));
                        }
                        else if (currentFilter === 'isBuyingWithinThirtyDays'){
                            setUserFeedback(userFeedback.filter(i => i.response === 'Saved'));
                        }
                    }*/
                    setIsLoadingMore(false);
                }
                else {
                    setIsLoadingMore(false);
                }
            })();
        };

        const applyFilter = (responseFilter) => {
            (async () => {
                setIsLoading(true);
                let getFeedbackRoute = 'interaction/feedback/consumer?feedbackType=purchases&pageSize=25&pageNumber=1&response=' + responseFilter;
                if(searchParams.get('businessID') != null){
                    setIsFilteredBrand(true);
                    getFeedbackRoute = getFeedbackRoute + '&businessId=' + searchParams.get('businessID');
                }
                const response = await api.get(getFeedbackRoute, null, {
                    headers: {
                    Authorization:  'Bearer ' + await getAccessTokenSilently()
                    }});
                if (response.ok && response.body !== null && response.body.length !== 0) {
                    setUserFeedback(response.body);
                    setUnfilteredList(response.body);
                    setFilteredBrand(response.body[0].businessName);
                    setIsLoading(false);
                }
                else {
                    setUserFeedback(null);
                    setIsLoading(false);
                }
            })();
            }

    return(
        <div className='feedbacks-page'>
            <div>
                <Modal
                    isOpen={editFeedbackModalIsOpen}
                    onAfterOpen={afterOpenModal}
                    onRequestClose={closeEditFeedbackModal}
                    style={customStylesInterested}
                    contentLabel="Example Modal"
                    id='is-interested-modal'>
                    <div className='edit-feedback-modal-banner'><span>Response last saved  <i>{feedbackObj.createDate}</i></span><div className='edit-feedback-modal-banner-close'><FontAwesomeIcon icon={faClose}/></div></div>
                    <Form onSubmit={onSubmit}>
                        <Form.Group className="mb-3 form-section" controlId="exampleForm.ControlInput1">
                            <Form.Label className="form-section-label">Is Interested</Form.Label>
                            <Form.Control type="checkbox" defaultChecked={feedbackObj.isInterested} onChange={e => feedbackObj.isInterested = !feedbackObj.isInterested}/>
                        </Form.Group>
                        <Form.Group className="mb-3 form-section" controlId="exampleForm.ControlInput1">
                            <Form.Label className="form-section-label">Is Actively Shopping</Form.Label>
                            <Form.Control type="checkbox" defaultChecked={feedbackObj.isActivelyShopping} onChange={e => feedbackObj.isActivelyShopping = !feedbackObj.isActivelyShopping}/>
                        </Form.Group>
                        <Form.Group className="mb-3 form-section" controlId="exampleForm.ControlInput1">
                            <Form.Label className="form-section-label">Has Already Purchased</Form.Label>
                            <Form.Control type="checkbox" defaultChecked={feedbackObj.hasAlreadyPurchased} onChange={e => feedbackObj.hasAlreadyPurchased = !feedbackObj.hasAlreadyPurchased}/>
                        </Form.Group>
                        <Form.Group className="mb-3 form-section" controlId="exampleForm.ControlInput1">
                            <Form.Label className="form-section-label">Is Shopping Within 30 Days</Form.Label>
                            <Form.Control type="checkbox" defaultChecked={feedbackObj.isBuyingWithinThirtyDays} onChange={e => feedbackObj.isBuyingWithinThirtyDays = !feedbackObj.isBuyingWithinThirtyDays}/>
                        </Form.Group>
                        <Form.Group className="mb-3 form-section" controlId="exampleForm.ControlInput1">
                            <Form.Label className="form-section-label">Is Not Right Target</Form.Label>
                            <Form.Control type="checkbox" defaultChecked={feedbackObj.isNotRightTarget} onChange={e => feedbackObj.isNotRightTarget = !feedbackObj.isNotRightTarget}/>
                        </Form.Group>
                        <Form.Group className="mb-3 form-section" controlId="exampleForm.ControlInput1">
                            <Form.Label className="form-section-label">Is Not Right Time</Form.Label>
                            <Form.Control type="checkbox" defaultChecked={feedbackObj.isNotRightTime} onChange={e => feedbackObj.isNotRightTime = !feedbackObj.isNotRightTime}/>
                        </Form.Group>
                        <Form.Group className="mb-3 form-section" controlId="exampleForm.ControlInput1">
                            <Form.Label className="form-section-label">Is Right Fit</Form.Label>
                            <Form.Control type="checkbox" defaultChecked={feedbackObj.isRightFit} onChange={e => feedbackObj.isRightFit = !feedbackObj.isRightFit}/>
                        </Form.Group>
                        <Form.Group className="mb-3 form-section" controlId="exampleForm.ControlInput1">
                            <Form.Label className="form-section-label">Is Too Expensive</Form.Label>
                            <Form.Control type="checkbox" defaultChecked={feedbackObj.IsTooExpensive} onChange={e => feedbackObj.IsTooExpensive = !feedbackObj.IsTooExpensive}/>
                        </Form.Group>
                        <Form.Group className="mb-3 form-section" controlId="exampleForm.ControlInput1">
                            <Form.Label className="form-section-label">Is Within Price Range</Form.Label>
                            <Form.Control type="checkbox" defaultChecked={feedbackObj.isWithinPriceRange} onChange={e => feedbackObj.isWithinPriceRange = !feedbackObj.isWithinPriceRange}/>
                        </Form.Group>
                        <Button variant="primary" type="submit" className='edit-feedback-modal-btn'>
                            <span className='feedback-modal-btn-icon'><FontAwesomeIcon icon={faSave}/></span>
                            <span>Save</span>
                        </Button>                     
                    </Form>
                </Modal>
              </div>
            <div className='feedback-review-table-header'>
                <span>Latest Responses</span>
                {isFilteredBrand ? <div className='feedback-review-brand-filter-label' onClick={() => {removeBrandFilter()}}><span className='feedback-review-brand-filter-label-name'><i>{filteredBrand}</i></span></div> : <span></span>}       
                <div className='feedback-filter-container'>                    
                    <select onChange={(selectedVal)=> {
                            userFeedback = [...unfilteredList];
                            if(selectedVal.target.value === 'Positive'){
                                setCurrentFilter('Positive');
                                applyFilter('Positive');
                            }
                            else if (selectedVal.target.value === 'Not Positive'){
                                setCurrentFilter('Not Positive');
                                applyFilter('Not Positive');
                            }
                            else if (selectedVal.target.value === "all"){
                                setCurrentFilter('all');
                                applyFilter('all');
                            }
                        }}>
                        <option value='all'>all</option>
                        <option value='Positive'>positive</option>
                        <option value='Not Positive'>negative</option>
                    </select>
                </div>
            </div>
            <div className='feedback-review-table-contaier'>
                <table className='feedback-review-table'>
                    <thead>
                    </thead>
                    <tbody>
                            { isLoading ? <LoadingSpinner/> : userFeedback === null ? <div>No Responses</div> :  userFeedback.map((feedback, index) =>                             
                            <tr className={feedback.response === 'Negative' ? 'feedback-table-row-not-interested' : ''} key={index}>
                                {feedback.response === 'Negative' ? <td className='first-cell-not-interested'></td> : <td className='first-cell-interested'></td>}
                                <td>{feedback.response === 'Negative' ? <span><FontAwesomeIcon icon={faThumbsDown}/> {feedback.response}</span> : <span><FontAwesomeIcon icon={faThumbsUp}/> {feedback.response}</span>}<br></br><span style={{fontSize: '14px'}}>{getDate(feedback.createdDate)}</span></td>
                                <td>{feedback.feedbackType}<br></br>{feedback.hasFeedbackGiven ? <span style={{fontSize: '12px', color: '#8ACB88'}}><FontAwesomeIcon icon={faCommentDots}/> Feedback Given</span> : <span style={{fontSize: '12px'}}><FontAwesomeIcon icon={faCommentDots}/> No Feedback Given</span>}</td>
                                <td><img className='ad-feedback-business-img' src={feedback.businessIconUrl}></img></td>
                                <td style={{width: '50px', padding: '10px'}}><a href={feedback.link} target='_blank'>{feedback.businessName}</a><br></br><span className='ad-feedback-business-category'>{feedback.businessCategoryName}</span></td>
                                <td style={{display: 'none'}}><button className='feedback-edit-btn' onClick={()=>{openEditFeedbackModal(feedback.feedbackId)}}><span style={{fontSize: '20px'}}><FontAwesomeIcon icon={faPencil}/></span><br></br>Edit<br></br>Response</button></td>
                            </tr>
                            )}
                    </tbody>
                </table>
                <div className='feedback-load-more-wrapper'>
                    <div style={isLoadingMore ? {display: 'none'} : {display: 'block'}} onClick={() => (loadResponses())}>Load More...</div>
                    <div style={isLoadingMore ? {display: 'block'} : {display: 'none'}}><LoadingSpinner/></div>
                </div>
            </div>
        </div>
    )
}

export default PurchaseFeedbackList;