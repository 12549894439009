import '../App.css';
import {Icon} from '../Components/NavIcon';
import { useApi } from '../Contexts/ApiProvider';
import Button from 'react-bootstrap/Button';
import { useNavigate } from 'react-router-dom';
import { React, useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import LoadingSpinner from '../Components/loadingSpinner';
import {ToastContainer, toast} from 'react-toastify';
import ToggleSwitch from "../Components/ToggleSwitch";
import copy from "copy-to-clipboard";
import { faUserCircle, faCopy, faEllipsisVertical, faArrowUpRightFromSquare, faArrowUpFromBracket, faLink, faListOl } from "@fortawesome/free-solid-svg-icons";
import { faAndroid, faApple, faYoutube } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ListGroup from 'react-bootstrap/ListGroup';
import { useAddToHomescreenPrompt } from "../Components/AddtoHomeScreenPrompt";
import ParsalIconLg from '../ParsalIcon64.png';

function GetStarted() {
    const navigate = useNavigate();
    const api = useApi();
    const [isLoading, setIsLoading] = useState(false);
    const { user, getAccessTokenSilently } = useAuth0();
    const [prompt, promptToInstall] = useAddToHomescreenPrompt();
    let [isAutoUnsubscribe, setIsAutoUnsubscribe] = useState(true);
    let [isAutoActivelyShopping, setIsAutoActivelyShopping] = useState(true);
    let [isAutoEmailLoad, setIsAutoEmailLoad] = useState(true);
    let [profileRefLink, setProfileRefLink] = useState({
        referralLink: ''
    });
    let [profileLeaderboard, setProfileLeaderboard] = useState({
        leaderboardName: ''
    });

    const savePreferences = async () => {
        const data = await api.post('consumer/preferences', {
            AutoUnsubscribeEmail: isAutoUnsubscribe,
            AutoActivelyShopping: isAutoActivelyShopping,
            AutoLoadEmails: isAutoEmailLoad
          }, {
            headers: {
              Authorization:  'Bearer ' + await getAccessTokenSilently()
            }});
          if (!data.ok) {
          }
          else {
          }
        };

    const copyToClipboard = () => {
        let isCopy = copy(profileRefLink.referralLink);
        if (isCopy) {
          toast.success("Copied to Clipboard");
        }
      };

      useEffect(() => {
          (async () => {
            setIsLoading(true);
            var apiUri = 'consumer/referralLink';
            const response = await api.get(apiUri, null, {
                headers: {
                  Authorization:  'Bearer ' + await getAccessTokenSilently()
                }});
            if (response.ok) {
                setProfileRefLink(response.body);
            }
            else {
                setProfileRefLink('Coming Soon');
            }

            var lbApiUrl = 'consumer/leaderboard';
            const lbResponse = await api.get(lbApiUrl, null, {
                headers: {
                  Authorization:  'Bearer ' + await getAccessTokenSilently()
                }});
            if (lbResponse.ok) {
                setProfileLeaderboard(lbResponse.body);
            }
            else {
                setProfileLeaderboard('Generating...');
            }

            setIsLoading(false);
          })();
          }, []);
        
    const navToPage = () => {
        savePreferences();
        navigate('/home');
    }

    return(
        <div className='get-started-page'>
            <div className='social-spinner-container' style={isLoading ? {display: 'block'} : {display: 'none'}}>
                <LoadingSpinner/>
            </div>
            <div className='get-started-banner' style={isLoading ? {display: 'none'} : {display: 'block'}}>
                <div className='get-started-logo-wrapper'>
                    <div className='get-started-logo-img-wrapper'>
                        <img className='get-started-header-logo' src={ParsalIconLg} alt='parsalIcon'></img>
                    </div>
                    <div className='get-started-header-app-name'>parsal</div>
                </div>
            </div>
            <div style={isLoading ? {display: 'none'} : {display: 'block'}}>
                <div className='social-page-content' style={{padding: '20px'}}>
                    <div className='get-started-title-wrapper'>
                        <h1 style={{marginBottom: '0'}}>Get Started</h1>
                        <h3 style={{marginTop: '0'}}>It's dangerous to go alone, take this!</h3>
                    </div>
                    <div>
                        <div className='get-started-install-app-tile-first'>
                            <div className='get-started-install-brand-icon-wrapper'>
                                <FontAwesomeIcon style={{verticalAlign: 'middle', paddingTop: '10px'}} icon={faApple}/>
                            </div>
                            <h2>How to install on iPhone</h2>
                            <ol className='get-started-install-instructions-list'>
                                <li>Click the browser options:</li>
                                <li>Select "Add to Home Screen"</li>
                                <li>Click "Done"</li>
                            </ol>
                        </div>
                        <div className='get-started-install-app-tile'>
                            <div className='get-started-install-brand-icon-wrapper'>
                                <FontAwesomeIcon style={{verticalAlign: 'middle', paddingTop: '10px'}} icon={faAndroid}/>
                            </div>
                            <h2>How to install on Android</h2>
                            <ol className='get-started-install-instructions-list'>
                                <li>Click the browser options:</li>
                                <li>Select the option "Install app"</li>
                                <li>Click "Install"</li>
                            </ol>
                        </div>                      
                    </div>
                    <div className='get-started-preferences-wrapper'>
                        <div style={{padding: '20px'}}>
                            <h1 style={{marginBottom: '0'}}>Set Your Preferences</h1>
                            <h3 style={{marginTop: '0'}}>Update your account settings to make managing your ads easier</h3>
                        </div>
                        <div className='get-started-preferences-toggle-wrapper'>
                            <div className='get-started-preferences-section-wrapper'>
                                <div className="get-started-preference-item-container">
                                    <div>
                                        <div className='get-started-toggle-wrapper'><ToggleSwitch label=" " toggleSelection={toggle => {setIsAutoUnsubscribe(toggle);}} preferenceStatus={isAutoUnsubscribe}/></div>
                                        <div className='get-started-toggle-description'>Auto Unsubscribe from Emails: </div>
                                    </div>
                                </div>
                                <div>If you indicate you are Actively Shopping for a particular product or category, this will create an Actively Shopping entry for you</div>
                            </div>
                            <div className='get-started-preferences-section-wrapper'>
                                <div className="get-started-preference-item-container">
                                    <div>
                                        <div className='get-started-toggle-wrapper'><ToggleSwitch label="  " toggleSelection={toggle => {setIsAutoActivelyShopping(toggle);}} preferenceStatus={isAutoActivelyShopping}/></div>
                                        <div className='get-started-toggle-description'>Auto Actively Shopping: </div>
                                    </div>
                                </div>
                                <div>Each time you hit the home page, your top 10 promotion emails will be imported into your account for you to respond to</div>
                            </div>
                            <div className='get-started-preferences-section-wrapper'>
                                <div className="get-started-preference-item-container">
                                    <div>
                                        <div className='get-started-toggle-wrapper'><ToggleSwitch label="              " toggleSelection={toggle => {setIsAutoEmailLoad(toggle);}} preferenceStatus={isAutoEmailLoad}/></div>
                                        <div className='get-started-toggle-description'>Load New Promo Emails on Login: </div>
                                    </div>
                                </div>
                                <div>Each time you hit the home page, your top 10 promotion emails will be imported into your account for you to respond to</div>
                            </div>
                        </div>
                    </div>
                    <div className='get-started-more-wrapper'>
                        <div style={{padding: '20px'}}>
                            <h1 style={{marginBottom: '0'}}>Learn More & Get Parsal-ing</h1>
                            <h3 style={{marginTop: '0'}}>Ready to rock? Here are a few more tidbits to get you rolling</h3>
                        </div>
                        <div className='get-started-more-section-wrapper'>
                            <div className='get-started-install-brand-icon-wrapper'>
                                <FontAwesomeIcon style={{verticalAlign: 'middle', paddingTop: '10px'}} icon={faLink}/>
                            </div>
                            <div className='get-started-more-tile-header'>Invite Link</div>
                            <div>Invite your friends by sending them your unique invitation link:</div>
                            <div className='get-started-learn-more-highlight'>
                                <div>{profileRefLink.referralLink}</div>
                                <button className='copy-link-btn' onClick={() => {copyToClipboard()}}><FontAwesomeIcon icon={faCopy}/></button>
                            </div>
                            <div>If they register from your link, you earn together.</div>
                        </div>
                        <div className='get-started-more-section-wrapper'>
                            <div className='get-started-install-brand-icon-wrapper'>
                                <FontAwesomeIcon style={{verticalAlign: 'middle', paddingTop: '10px'}} icon={faListOl}/>
                            </div>
                            <div className='get-started-more-tile-header'>Leaderboard Name</div>
                            <div>Track your activity against other Parsal users on our leaderboard by looking for your generated leaderboard name:</div>
                            <div className='get-started-learn-more-highlight'>
                                <div style={{color: '#ffbf46'}}>{profileLeaderboard.leaderboardName}</div>
                            </div>
                            <div>The leaders from each category will earn bonus money each month.</div>
                        </div>
                        <div className='get-started-more-section-wrapper-last'>
                            <div className='get-started-install-brand-icon-wrapper'>
                                <FontAwesomeIcon style={{verticalAlign: 'middle', paddingTop: '10px'}} icon={faYoutube}/>
                            </div>
                            <div className='get-started-more-tile-header'>How It Works</div>
                            <div>Need more to feel comfy with the Parsal experience? Check out our "How To" Videos.</div>
                            <div className='get-started-learn-more-highlight'>
                                <div onClick={() => {navigate('/howitworks')}}>https://parsal.app/howitworks</div>
                            </div>
                            <div>They'll clear up most questions.</div>
                        </div>
                    </div>
                    <div style={{paddingTop: '35px'}}>
                        <Button variant="primary" type="button" className='social-continue-btn' onClick={navToPage}>Continue</Button>
                    </div>                    
                </div>
            </div>
        </div>
    )
}

export default GetStarted;